<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section16" class="CreateSigningProcess sectionContainer ">
      <h1 class="section-title">Create a signing process</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <p><b>Scope:</b><i> basic</i></p>
      <hr class="line-break">
      <p>Creating a signing process is a two-part operation: first,
        <v-btn text class="api-negative-link" to="/docs/api/upload-a-document">upload the documents</v-btn>
        to sign, second, create the process which signs them.</p>
      <p>To create a signing process send a request which lists the identifiers of the uploaded
        documents as well as the rest of the information about the signing process:</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/signatures HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "create",
     "data_type": "signature_flow",
     "trans_map": {
         "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span
      v-if="!isV4">{{ versionDifferenceAuthKey }}</span>": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {
         "title": "My first",
         "locale": "en",
         "signature_invitation": {
             "subject": "Signature invitation",
             "message": "Your signature is requested."
         },
         "documents": [
             "d807b62c-08bb-11e7-a142-080027334cc4"
         ],
         "signers": [<span v-if="isV4">
             {
                 "type": "me"
             },</span>
             {<span v-if="isV4">
                 "type": "others",</span>
                 "name": "SignHero Tester",
                 "email": "signhero.tester@signhero.io"
             }
         ]
     }
 }
</code></pre>
        </div>
      </v-card>
      <p v-if="isV4">To create an advanced signing process send a request which lists the identifiers of the uploaded
        documents as well as the rest of the information about the signing process:</p>
      <v-card v-if="isV4">
        <div class="card-body mb-4">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/signatures HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "create",
     "data_type": "signature_flow",
     "trans_map": {
         "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span
      v-if="!isV4">{{ versionDifferenceAuthKey }}</span>": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {
         "title": "My first",
         "locale": "en",
         "signature_invitation": {
             "subject": "Signature invitation",
             "message": "Your signature is requested."
         },
         "documents": [
             "d807b62c-08bb-11e7-a142-080027334cc4"
         ],
         "signers": [
             {
                 "type": "me",
                 "allowed_signature_types": ["fi-ftn-advanced-signature"]
             },
             {
                 "type": "others",
                 "name": "SignHero Tester",
                 "email": "signhero.tester@signhero.io"
                 "allowed_signature_types": ["fi-ftn-advanced-signature"]
             }
         ],
         "signature_level" : "advanced"
     }
 }
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the request map:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>title</td>
            <td>A title for the signing process. This value is optional.</td>
          </tr>
          <tr>
            <td>locale</td>
            <td>Specifies the language used in the signing pages and emails. Supported
              locales are <b>en</b>, <b>fi</b>, <b>sv</b>, <b>pl</b>, <b>de</b>, <b>nl</b>,  <b>fr</b>, <b>es</b> and <b>th</b>. Defaults to <b>en</b>.
            </td>
          </tr>
          <tr>
            <td>signature_invitation</td>
            <td>An object presenting the signature invitation.</td>
          </tr>
          <tr>
            <td>signature_invitation:subject</td>
            <td>A custom subject for the email sent to the signers. This value is
              optional.
            </td>
          </tr>
          <tr>
            <td>signature_invitation:message</td>
            <td>A custom message in the email sent to the signers. This value is optional.</td>
          </tr>
          <tr>
            <td>documents</td>
            <td>An array containing the identifiers of the documents sent for signature. The maximum number of documents
              is 20.
            </td>
          </tr>
          <tr>
            <td>signers</td>
            <td>An array of the signers.</td>
          </tr>
          <tr v-if="isV4">
            <td>signer:type</td>
            <td>Type of the signer: <b>me</b> for account owner or <b>others</b> for an invited signer.</td>
          </tr>
          <tr>
            <td>signer:name</td>
            <td>The name of the signer.<span v-if="isV4"> This value is not allowed for a <b>me</b> signer.</span>
            </td>
          </tr>
          <tr>
            <td>signer:email</td>
            <td>The email of the signer. <span v-if="isV4">This value is not allowed for a <b>me</b> signer.</span></td>
          </tr>
          <tr>
            <td>signer:locale</td>
            <td>Specifies the language used in the signing pages and emails. Supported locales are en, fi, sv, pl, de, nl, fr and es. Defaults
              to the locale of the signing process.<span v-if="isV4"> This value is not allowed for a <b>me</b> signer.</span>
            </td>
          </tr>
          <tr v-if="isV4">
            <td>signer:allowed_signature_types</td>
            <td>Specifies the signature types allowed for signing for this signer. Supported signature types must be enabled in the organisation.
              An advanced signature type is only allowed with advanced signature level.
            </td>
          </tr>
          <tr v-if="isV4">
            <td>signature_level</td>
            <td>Specifies the signature level for the signing process. Supported levels are "advanced" and "basic". Defaults to "basic".</td>
          </tr>
          <tr v-if="isV4">
            <td>hidden_email</td>
            <td>A boolean value to specify whether the email addresses will be hidden in the signed documents.
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="mt-5">The successful response includes the identifier<span v-if="!isV4">s</span> of the process
        <span v-if="!isV4"> and signers</span>:</p>
      <v-card>
        <div class="card-body">
<pre><code>{
    "action_str": "created",
    "data_type": "signature_flow",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span><span v-if="isV4">{{ versionDifference200 }}</span>,
            "code_str": "ok",
            "user_msg": "signature_flow created",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {<span v-if="!isV4">
        "auth_key": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    </span>},
    "response_map": {
        "process_id": "d7e93169-08bb-11e7-a142-080027334cc4"<span v-if="!isV4">,
        "signers": [
            "2d871e79-0b23-11e7-93dc-42067774ef10"
        ]</span>
    }
}
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "CreateASigningProcess",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
                versionDifferenceAuthKey : 'auth_key',
                versionDifferenceAccessToken : 'access_token',
            }
        }
    }
</script>

<style scoped>

</style>
