<template>
<!--  <v-card :light="user.disabled" :dark="!user.disabled" class="old-user-card pa-0" :class="{ 'inactive-user-card' : isInactive && !user.disabled }">
    <v-row no-gutters align="center">
      <v-col class="sh-user-card-icon">
        <v-row align="center" justify="center" class="fill-height">
          <v-icon size="64" :color="user.disabled ? 'black': 'white' " class="pa-4">mdi-account-circle</v-icon>
        </v-row>
      </v-col>
      <v-col>
        <v-card flat light tile class="sh-orgnization-user-card ma-auto pa-4"
          :class="{ 'disabled-user-card' : user.disabled }"
        >
          <v-menu v-if="isAdmin" class="float-right">
            <template v-slot:activator="{ on, attrs }">
              <v-icon style="position:absolute;top:10px;right:10px;" v-bind="attrs"
                      v-on="on" v-if="user.email != userEmail ">mdi-dots-horizontal</v-icon>
            </template>
            <v-list class="user-card-operation-list" min-width="200px">
              <v-list-item v-if="isInactive && !user.disabled" @click="sendReminder()">
                {{ $t( "signedin.SendReminder" )}}
              </v-list-item>
              <v-list-item v-if="!user.disabled" @click="deleteUser()" :disabled="disableAdminToggle">
                {{ $t( "signedin.DeleteUser" ) }}
              </v-list-item>
              <v-list-item v-if="user.disabled" @click="restoreUser()" :disabled="disableAdminToggle">
                {{ $t( "signedin.RestoreAccount" ) }}
              </v-list-item>
            </v-list>
          </v-menu>
          <v-container fill-height class="text-center">
            <v-row>
              <v-col cols="12" class="user-info text-left">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="user-name"
                         v-bind="attrs"
                         v-on="on">{{ user.name }}</div>
                  </template>
                  {{ user.name }}
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="user-email"
                         v-bind="attrs"
                         v-on="on">{{ user.email }}</div>
                  </template>
                  {{ user.email }}
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-12 py-0">
                <v-checkbox v-if="!user.disabled" v-model="user.isAdmin" :disabled="disableAdminToggle" :label="$t( 'signedin.isAdmin' )" @change="setAdmin()"/>
              </v-col>
              <v-col v-if="stripePaymentOn" class="col-12 py-0">
                <v-checkbox v-if="!user.disabled" v-model="user.isSubscriptionAdmin" :disabled="disableSubscriptionAdminToggle" :label="$t( 'signedin.isSubscriptionAdmin' )" @change="setSubscriptionAdmin()"/>
              </v-col>
            </v-row>
            <v-row v-if="user.disabled && !isInactive && stripePaymentOn">
              <p class="pa-3 text-left font-italic deleted-users-subscription">
                {{ $t( "signedin.SubInactive1" ) }} {{ $t( "signedin.SubInactive2" ) }}
              </p>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-card>-->
  <v-expansion-panel class="user-accounts-panel">
    <v-expansion-panel-header>
      <div class="user-panel-header">
        <v-icon v-if="isOwnAccount" class="ml-0 mr-2 mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2" small color="secondary">mdi-account-circle</v-icon>
        <v-icon v-if="user.isAdmin && !user.disabled && isActive && !isOwnAccount" class="ml-0 mr-2 mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2" small color="secondary">mdi-account-cog</v-icon>
        <v-icon v-if="user.isSubscriptionAdmin && !user.disabled && !user.isAdmin && isActive && !isOwnAccount" class="ml-0 mr-2 mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2" small
                color="secondary">
          mdi-credit-card-outline
        </v-icon>
        <v-icon v-if="!user.isAdmin && !user.disabled && isActive && !user.isSubscriptionAdmin && !isOwnAccount" class="ml-0 mr-2 mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2" small
                color="secondary">
          mdi-account
        </v-icon>
        <v-icon v-if="isInactive && !user.disabled" class="ml-0 mr-2 mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2" small color="grey darken-1">mdi-email-outline</v-icon>
        <v-icon v-if="user.disabled" class="ml-0 mr-2 mx-sm-2 mx-md-2 mx-lg-2 mx-xl-2" small color="red">mdi-account-off</v-icon>
        <span>{{ user.name }}&nbsp;</span>
        <span class="hidden-xs-only">|&nbsp;{{ user.email }}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="accounts-panel-content text-left">
      <v-card flat>
        <v-responsive>
          <v-row>
            <v-col class="pb-3 user-name-col" cols="12" sm="12" md="6" lg="6">
              <div>
                <span><b>{{ $t( "signedin.UserName" ) }}</b></span>
              </div>
              <div>
                <span>{{ user.name }}</span>
              </div>
            </v-col>
            <v-col class="user-email-row" cols="12" sm="12" md="6" lg="6">
              <div>
                <span><b>{{ $t( "signedin.Email" ) }}</b></span>
              </div>
              <div>
                <span>{{ user.email }}</span>
              </div>
            </v-col>
          </v-row>
          <div class="pt-3"><b>{{ $t( "signedin.AccountStatus" ) }}</b></div>
          <div>
            <p v-if="isOwnAccount" class="mb-3">{{ $t( "signedin.OwnAccount" ) }}</p>
            <p v-if="!user.disabled && isActive && !isOwnAccount" class="mb-3">{{ $t( "signedin.StatusActive" ) }}</p>
            <p v-if="isInactive && !user.disabled" class="mb-3">
              {{ $t( "signedin.StatusInactive" ) }}
              <span class="send-reminder-link" v-if="isAdmin">
                  | <v-icon class="mx-1">mdi-send-outline</v-icon>
                  <a v-if="isInactive && !user.disabled" @click="sendReminder()">{{ $t( "signedin.SendReminder" ) }}</a>
              </span>
            </p>
          </div>
          <p v-if="user.disabled">{{ $t( "signedin.StatusDeleted" ) }}</p>
          <span v-if="!user.disabled"><b>{{ $t( "signedin.AccountRights" ) }}</b></span>
          <v-row>
            <v-col class="admin-checkbox" cols="12" sm="12" md="6" lg="6">
              <v-checkbox v-if="!user.disabled" :ripple="false" v-model="user.isAdmin" :disabled="disableAdminToggle"
                          :label="$t( 'signedin.isAdmin' )" @change="setAdmin()" class="mt-0"/>
            </v-col>
            <v-col class="subscription-checkbox" :ripple="false" v-if="stripePaymentOn" cols="12" sm="12" md="6" lg="6">
              <v-checkbox v-if="!user.disabled" v-model="user.isSubscriptionAdmin"
                          :disabled="disableSubscriptionAdminToggle" :label="$t( 'signedin.isSubscriptionAdmin' )"
                          @change="setSubscriptionAdmin()" class="mt-0"/>
            </v-col>
          </v-row>
        </v-responsive>
        <v-divider v-if="isAdmin"></v-divider>
        <v-card-actions class="operation-panel" v-if="isAdmin && user.email != userEmail">
          <v-row no-gutters>
            <v-col class="text-left operation-panel-buttons">
              <v-btn class="secondary-btn" v-if="!user.disabled" @click="deleteUser()" :disabled="disableAdminToggle">
                {{ $t( "signedin.DeleteUser" ) }}
              </v-btn>
              <v-btn class="alternative-primary-btn" v-if="user.disabled" @click="restoreUser()"
                     :disabled="disableAdminToggle || disableResellerToggle">
                {{ $t( "signedin.RestoreAccount" ) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
    import util from "../../../util/util";
    export default {
        props : {
            user : Object,
            isAdmin : Boolean,
            disableResellerToggle : Boolean,
            isSubscriptionAdmin : Boolean
        },
        data()
        {
            return {
                disableAdminToggle : !this.isAdmin || $cc_user_info.email === this.user.email,
                isOwnAccount : $cc_user_info.email === this.user.email,
                isInactive : !this.user.active,
                isActive : this.user.active,
                userEmail : "",
                subscription : $cc_user_info.subscription,
                stripePaymentOn : $cc_user_info.stripePaymentOn
            }
        },
        computed : {
            disableSubscriptionAdminToggle()
            {
                return !this.isAdmin || this.user.isAdmin || $cc_user_info.email === this.user.email;
            }
        },
        methods :
        {
            /**
             * Update a user's admin status.
             */
            setAdmin()
            {
                if ( this.user.isAdmin && !this.user.isSubscriptionAdmin )
                {
                    this.user.isSubscriptionAdmin = true;
                }
                this.$store.shioCoreClient.updateUserAdmin( util.getOrganizationId(), this.user.email, this.user.isAdmin ).then( r =>
                {
                    this.$inform( this.$t( "signedin.AdminStatusChanged" ) );
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Update a user's subscription admin status.
             */
            setSubscriptionAdmin()
            {
                this.$store.shioCoreClient.updateUserSubscriptionAdmin( util.getOrganizationId(), this.user.email, this.user.isSubscriptionAdmin ).then( r =>
                {
                    this.$inform( this.$t( "signedin.AdminStatusChanged" ) );
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Send account activation reminder.
             */
            sendReminder()
            {
                this.$store.shioCoreClient.createActivationReminder( util.getOrganizationId(), this.user.email ).then( r =>
                {
                    this.$inform( this.$t( "signedin.EmailReminderSuccess" ) );
                } ).catch( e =>
                {
                    if( e.code_key === 409 )
                    {
                        this.$warn( this.$t( "signedin.UserAlreadyActive" ) );
                    }
                    else
                    {
                        this.$error( this.$t( "signedin.Error!Unexpected" ) )
                    }
                } );
            },
            /**
             * Delete a user.
             */
            deleteUser()
            {
                this.$store.shioCoreClient.updateUserDisabled( util.getOrganizationId(), this.user.email, true ).then( r =>
                {
                    this.$inform( this.$t( "signedin.UserDeleted" ) );
                    this.$emit( 'delete-user', this.user );
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Restore a user.
             */
            restoreUser()
            {
                this.$store.shioCoreClient.updateUserDisabled( util.getOrganizationId(), this.user.email, false ).then( r =>
                {
                    this.$inform( this.$t( "signedin.UserRestored" ) );
                    this.$emit( 'restore-user', this.user );
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            }
        },
        mounted()
        {
            this.userEmail = $cc_user_info.email;
        },
    }
</script>

<style lang="sass">
  @import "../../../styles/style"

  .inactive-user-card
    background-color: $dark-grey !important

  .disabled-user-card
    background-color: $light-grey !important

  .user-name
    font-size: 20px
    font-weight: bold

  .user-email
    font-size: 13px

  .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate)
    i.v-icon.notranslate.mdi.mdi-checkbox-marked.theme--light
      color: $vivid-pink !important
      opacity: 0.5

  .user-card-operation-list .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover
    color: $vivid-pink !important

  .sh-orgnization-user-card .v-input.v-input--selection-controls.v-input--checkbox
    margin-top: 0 !important

  @media (max-width: 599px)
    .user-card-operation-list .v-list-item
      font-size: 14px
</style>

<style lang="sass" scoped>
  .accounts-panel-content .v-responsive
    padding: 16px 55px 16px 55px

  @media (max-width: 599px)
    .accounts-panel-content .v-responsive
      padding: 16px 35px 16px 35px

  @media (max-width: 959px)
    .user-name-col
      padding-bottom: 0 !important
</style>
