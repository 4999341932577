export default {
    "pdfsign": {
        "Error!Unexpected": "Une erreur inattendue s'est produite. Veuillez réessayer ultérieurement.",
        "SelectSigner": "Sélectionner le signataire",
        "SignatureTimestampFormat": "Signé le jj.mm.aaaa",
        "DeclinationPlaceHolder": "Raisons du refus (facultatif)",
        "SignerGreetings": "Bonjour ",
        "WhoSigns": "Qui signe :",
        "Sign": "Signer",
        "WhatSigned": "Qu'est-ce qui est signé : <span class='sh-nowOrNeverText'>(cliquez sur le document pour le vérifier – c'est le moment ou jamais !)</span>",
        "SignConfirmationMsg": "J'ai vérifié le(s) document(s) et suis légalement autorisé à signer.",
        "UsagePolicyInfoMsg": "En utilisant SignHero, vous acceptez nos <a href='terms-of-use.html' target='_blank' style='color: #ff507a;'>Conditions d'utilisation</a> et notre <a href='privacy-policy.html' target='_blank' style='color: #ff507a;'>Politique de confidentialité</a>.",
        "Decline": "Refuser",
        "Cancel": "Annuler",
        "ThankUsingService": "Merci quand même !",
        "FindMoreInformation": "Choisissez la facilité sur <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "Le processus de signature a été annulé",
        "CompleteProcessInfo": "Vous ne pouvez pas signer le(s) document(s) car cette demande de signature a été clôturée. Vous avez peut-être déjà signé ces documents ou le processus a été annulé par l'expéditeur.",
        "DeclineToSignTitle": "Refuser de signer",
        "SignedAndSent": "Le ou les documents ont été signés avec succès.",
        "SorryUnexpectedError": "Désolé, une erreur s'est produite",
        "SessionNeedRefreshError": "Votre session doit être actualisée. Veuillez réessayer.",
        "ReasonExpiredSession": "Le lien sur lequel vous avez cliqué est peut-être rompu ou l'invitation a été supprimée.",
        "InvalidLoginUrl": "Veuillez vérifier que l'URL saisie correspond au lien figurant dans votre e-mail et réessayez.",
        "You": "vous"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "locale!th" : "ภาษาไทย",
        "Applications": "Applications",
        "SearchUsers": "Rechercher des utilisateurs",
        "UserDeleted" : "L'utilisateur a été désactivé et sera définitivement supprimé au bout de 30 jours",
        "UserRestored" : "L'utilisateur a été restauré",
        "UserAlreadyActive" : "L'utilisateur est déjà actif.",
        "OrganizationHasBeenDeleted": "Un administrateur vient de supprimer votre organisation.\nNous sommes désolés de votre départ et espérons vous revoir bientôt.",
        "SendReminder": "Envoyer un rappel d'activation",
        "EmailReminderSuccess": "Rappel par e-mail envoyé.",
        "AdminStatusChanged": "Utilisateur mis à jour.",
        "DateTimeFormat": "dd.MM.yyyy, HH:mm:ss",
        "SessionType!password": "Mot de passe",
        "SessionType!auth_code": "Application",
        "Start": "Démarrer",
        "TheService": "Administration",
        "Save": "Enregistrer",
        "MyOrganization": "Mon organisation",
        "OrganizationName": "Nom de l'organisation",
        "ManageAccounts": "Gérer les comptes",
        "MyIntegrations": "Intégrations",
        "Statistics" : "Statistiques",
        "isAdmin": "Admin",
        "DeleteUser": "Supprimer le compte utilisateur",
        "RestoreAccount": "Restaurer le compte utilisateur",
        "Error!Unexpected": "Une erreur inattendue s'est produite. Veuillez réessayer ultérieurement.",
        "Error!InvitedUserExists": "L'utilisateur a déjà rejoint cette organisation ou appartient à une autre organisation. Dans ce dernier cas, veuillez contacter ${email}",
        "InviteUser": "Inviter",
        "UserInvited" : "Utilisateur invité",
        "Create": "Créer",
        "ConfirmDeleteEverything": "Voulez-vous vraiment supprimer ce compte d'organisation (${groupTitle}) ?\n",
        "ConfirmCancelOngoingProcesses" : "Votre organisation a ${processNum} processus de signature en cours. Ils doivent également être supprimés pour pouvoir procéder à la suppression du compte de l'organisation.\n "
                                          + "\nVoulez-vous vraiment annuler tous les processus en cours et supprimer ce compte d'organisation (${groupTitle})?\n"
                                          + "\nPour continuer, veuillez saisir le code : ${rndCode}",
        "IncorrectConfirmationCode" : "Code de confirmation incorrect.",
        "DownloadOrganizationDataMessage": "Téléchargez les données utilisateur et les données des processus de signature sous la forme d'un fichier JSON.",
        "DownloadOrganizationDataLink": "Télécharger les données de l'organisation",
        "Subscription": "Abonnement",
        "InviteUserHeader": "Inviter un nouvel utilisateur dans votre organisation",
        "AccountStatus" : "Statut du compte :",
        "OwnAccount" : "Votre propre compte",
        "StatusActive" : "Actif, l'utilisateur a accepté l'invitation.",
        "StatusInactive" : "Inactif, l'utilisateur n'a pas encore accepté l'invitation.",
        "StatusDeleted" : "Marqué pour suppression. L'abonnement est inactif, il ne sera pas renouvelé pour cet utilisateur.",
        "AccountRights" : "Droits :",
        "DownloadOrganizationData": "Télécharger JSON",
        "DeleteOrganizationMessage": "Supprimer cette organisation, votre compte ainsi que tous les autres comptes administrateur et utilisateur",
        "DeleteEverything": "Tout supprimer",
        "OrganizationHasActiveProcesses" : "Impossible de supprimer l'organisation car elle a des processus de signature ou des demandes de fusion actifs.",
        "MergeOrganizationMessage": "L'organisation peut demander à fusionner avec une autre organisation.",
        "RequestMergeDetails": "L'administrateur de l'organisation peut demander à fusionner l'organisation avec une autre organisation. La demande sera valide pendant 24 heures.\n\n"
            + "Une fois la demande acceptée, les processus de signature, y compris les documents et les utilisateurs, seront migrés vers la nouvelle organisation et cette organisation disparaîtra. "
            + "Les utilisateurs de cette organisation n'auront plus de droits administrateur après la fusion. Les droits devront être ajoutés par l'administrateur de la nouvelle organisation. Si un abonnement était en cours, "
            + "la période d'abonnement restante sera annulée et les utilisateurs fusionnés seront comptabilisés dans l'abonnement de la nouvelle organisation.",
        "MergeOrganizationDetails": "L'organisation ${groupName} a demandé à fusionner le compte de l'organisation "
            + "avec le compte de votre organisation. La demande est valide pendant 24 heures.\n\n"
            + "Une fois la demande acceptée, les processus de signature, y compris les documents et les utilisateurs, seront migrés vers votre organisation "
            + "et l'organisation d'origine disparaîtra. Les utilisateurs de l'organisation d'origine n'auront plus de droits administrateur après la fusion. "
            + "Les droits devront être ajoutés par l'administrateur de votre organisation. Si un abonnement était en cours, "
            + "les utilisateurs migrés seront comptabilisés dans l'abonnement de votre organisation.",
        "RequestMerge": "Demander la fusion",
        "ConfirmMergeOrganisation": "Voulez-vous vraiment fusionner ce compte \nd'organisation ? La séparation des comptes ne sera plus possible.",
        "EnterAdminEmail": "Saisissez l'adresse e-mail d'un administrateur l'autre organisation :",
        "EnterPassword": "Pour continuer, veuillez saisir votre mot de passe :",
        "InvalidPassword" : "Votre mot de passe ne correspondait pas. Veuillez réessayer.",
        "MergeRequestSent": "Demande de fusion d'organisation envoyée",
        "MergeRequestCanceled": "Demande de fusion d'organisation annulée",
        "MergeRequestRejected": "Demande de fusion d'organisation rejetée",
        "ReceivedMergeRequests": "Demandes de fusion reçues",
        "SentMergeRequest": "Demande de fusion envoyée",
        "Recipient": "Destinataire",
        "Reject": "Rejeter",
        "Merge": "Fusionner",
        "OrganisationMerged": "La fusion a commencé.",
        "UserNotDifferentOrganizationAdmin": "L'utilisateur n'est pas un administrateur d'une autre organisation",
        "UserNotFound": "Désolé, utilisateur introuvable !",
        "OrganizationHasActiveMergeRequest": "L'organisation a déjà une demande de fusion active",
        "InvalidMergeRequest": "La demande de fusion est introuvable ou a expiré",
        "Complete": "Archiver",
        "Locales": "Langue",
        "Sessions": "Sessions",
        "LogOutAllDevicesMessage": "Si vous le souhaitez, vous pouvez vous déconnecter de toutes vos sessions sur tous les appareils.",
        "NoApplicationsMessage": "Vous n'avez pas encore été autorisé à accéder à une application.",
        "Domain" : "Domaine",
        "AccessGiven" : "Accès accordé le",
        "LastAccess" : "Dernier accès",
        "RemoveAccess": "Supprimer l'accès",
        "Ongoing": "En cours",
        "MyAccount": "Compte",
        "UserName": "Nom",
        "Email": "E-mail",
        "Logout": "Se déconnecter",
        "SignOut": "Se déconnecter de toutes les sessions",
        "Confirm": "Confirmer",
        "Cancel": "Annuler",
        "Reset": "Réinitialiser",
        "Profile": "Mon profil",
        "Organization": "Organisation",
        "Device": "Appareil",
        "Update" : "Enregistrer",
        "Delete" : "Supprimer",
        "AcceptApiTerms" : "En utilisant l'API SignHero, vous acceptez nos <a href='/terms/api' target='_blank'>Conditions générales de l'API</a>.",
        "CannotDeleteActiveFlows" : "Votre organisation a des processus de signature en cours. Vous devez d'abord les annuler pour pouvoir procéder à la suppression du compte de l'organisation.",
        "MySubscription" : "Mon abonnement",
        "FreeTrialLeft" : "Temps d'essai gratuit restant : ",
        "Days" : "jours.",
        "SubscriptionPeriod" : "Période d'abonnement en cours :",
        "PleaseActivateSubscription" : "Veuillez activer votre abonnement.",
        "Tab1" : "Informations relatives au paiement",
        "Tab2" : "Historique de facturation",
        "Tab3" : "À propos",
        "NoPaymentNotification" : "Remarque : Vous n'avez actuellement pas de mode de paiement valide.",
        "AddCreditCard" : "Ajouter une carte de crédit pour la facturation mensuelle de SignHero",
        "CreditCardEmail" : "E-mail",
        "BillingDate" : "Date de facturation",
        "Amount" : "Montant",
        "UsersAmount" : "Utilisateurs",
        "Duration" : "Durée",
        "ReferNumber" : "Numéro de référence",
        "Invoice" : "Facture",
        "Action" : "Action",
        "LoadMore" : "Charger plus",
        "SalesChannel" : "Canal de vente",
        "ResellerName" : "Revendeur: ",
        "ResellerContact" : "Contact: ",
        "ResellerMaxUsers" : "Utilisateurs max.: ",
        "ResellerCurrentInvitedUsers" : "Utilisateurs invités actuels: ",
        "MaxResellerUsersWarning" : "Votre nombre d’utilisateurs a atteint la limite maximale (${resellerMaxUsers}) fixée pour les revendeurs.",
        "StartOfSubscription" : "Date de début de l'abonnement :",
        "EstimatedBilling" : "Prochaine facture le ${nextBillingDate} pour un montant de ${nextBillingValue} €",
        "SubInactive1" : "L'abonnement n'est pas actif",
        "SubInactive2" : ", il ne sera pas renouvelé pour cet utilisateur supprimé",
        "isSubscriptionAdmin" : "Administrateur de l'abonnement",
        "CardInfo" : "Informations de carte",
        "NameOnCard" : "Nom figurant sur la carte",
        "SelectCountry" : "Pays ou région",
        "SelectLocale" : "Sélectionner une langue",
        "BusinessAccount" : "Compte professionnel",
        "OnlyBusinessAccountsSupported" : "Seuls les comptes professionnels sont pris en charge pour le pays sélectionné.",
        "TaxIdExplanation" : "Veuillez sélectionner votre région d'imposition et saisir votre identifiant fiscal pour la TVA.",
        "SelectIdType" : "Sélectionner le type d'identifiant fiscal",
        "TaxId" : "Identifiant fiscal",
        "AddCardTooltip1" : "Veuillez d'abord ajouter des informations de facturation valides.",
        "AddCardTooltip2" : "Veuillez ajouter des informations de carte de crédit valides. Tous les champs sont obligatoires.",
        "AddCardTooltip3" : "Les informations de carte de crédit sont valides.",
        "BillingInformation" : "Informations de facturation",
        "Edit" : "Modifier",
        "EditBillingInformation" : "Modifiez les informations de facturation.",
        "AddBillingTooltip" : "Veuillez renseigner tous les champs obligatoires et vérifier que l'identifiant fiscal correspond au pays.",
        "Street" : "Adresse",
        "Street2" : "Adresse 2",
        "City" : "Ville",
        "State" : "Pays",
        "Zip" : "Code postal",
        "SaveBillingInfo" : "Enregistrer les informations de facturation",
        "SaveCard" : "Enregistrer les informations de carte",
        "Expires" : "Valide jusqu'au",
        "DeleteCardOnly" : "Supprimer la carte de crédit",
        "DeletePaymentInfo" : "Supprimez les informations de facturation et de carte de crédit.",
        "RemoveWholePaymentMethod" : "Supprimer les infos de paiement",
        "PayOnline" : "Confirmer le paiement",
        "PaymentStatusPaid" : "Payé",
        "PaymentStatusPending" : "En attente",
        "PaymentInfo" : "À propos",
        "PaymentExplanation1" : "SignHero coûte 5 € par mois et par utilisateur actif (prix hors TVA/taxe sur les ventes). La TVA/taxe sur les ventes alors en vigueur sera ajoutée au prix. Les utilisateurs inactifs ne sont pas pris en compte dans la facturation. Si vous ajoutez ou supprimez des utilisateurs pendant un cycle de facturation, nous calculerons automatiquement le montant minoré ou majoré de la prochaine facture en fonction du temps du cycle de facturation qui restait lorsque la modification a eu lieu. Lorsque vous vous abonnez, vous avez droit à une période d'essai gratuite de 14 jours à l'issue de laquelle vous devez enregistrer une carte de crédit pour le règlement des paiements mensuels si vous souhaitez continuer à utiliser le service. Votre carte de crédit sera automatiquement débitée tous les mois en fonction du nombre d'utilisateurs enregistrés dans le service. Vous recevrez un reçu de votre paiement mensuel à l'adresse e-mail que vous avez indiquée lorsque vous avez enregistré votre carte de crédit.",
        "PaymentExplanation2" : "Vous devez saisir le numéro de TVA de votre organisation car SignHero collecte la TVA auprès de ses clients conformément à la législation fiscale finlandaise et européenne. Pour les entreprises basées dans l'Union européenne, un numéro de TVA intracommunautaire valide doit être fourni. Si votre pays ne figure pas dans la sélection de pays ou de régions, vous pouvez nous contacter pour connaître les autres modes de paiement. Si vous n'avez pas trouvé votre pays et si vous avez décidé de ne pas utiliser le service pour cette raison, merci de nous en informer. Il est possible que nous l'ajoutions ultérieurement, auquel cas nous vous en informerons !",
        "PaymentExplanation3" : "Si vous ne pouvez pas ajouter de carte de crédit pour la facturation automatique, veuillez contacter SignHero à l'adresse <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "PaymentExplanation4" : "Vous avez un problème avec votre facture ? Vous avez des questions concernant le processus de facturation ? Pour toute question, n'hésitez pas à nous contacter à l'adresse <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "CountersPending" : "Les statistiques sont en cours de calcul. Veuillez réessayer dans quelques minutes.",
        "WholeOrganisation" : "Toute l'organisation",
        "OrganisationHas" : "Votre organisation a actuellement",
        "ActiveUsers" : "utilisateurs actifs et",
        "InactiveUsers" : "utilisateurs inactifs.",
        "ChartDescriptionOrg" : "Le graphique ci-dessous montre les processus de signature et les documents créés de toute l'organisation.",
        "ChartDescriptionUser" : "Le graphique ci-dessous montre les processus de signature créés des utilisateurs sélectionnés.",
        "SelectUsers" : "Sélectionner les utilisateurs",
        "From" : "Du",
        "To" : "Au",
        "AmountOfDocuments" : "Dans la période sélectionnée, le nombre de nouveaux documents est",
        "AmountOfSignatureProcesses" : "et le nombre de nouveaux processus de signature est",
        "Processes" : "Processus",
        "Documents" : "Documents",
        "Users" : "Utilisateurs",
        "UserActivity" : "Activité de l'utilisateur",
        "Signatures": "Signatures",
        "User" : "Utilisateur",
        "Inactive" : "Inactif",
        "SignatureProcesses" : "Processus de signature dans la période",
        "AllSignatureProcesses" : "Tous les processus de signature",
        "FreeTrialAlert1" : "Votre organisation a",
        "FreeTrialAlert2" : "jours d'essai gratuit restants !",
        "ContactAdmin" : "Aucun abonnement actif, veuillez contacter votre administrateur !",
        "PaymentOverdueAlert" : "Paiement en retard, veuillez ajouter une carte valide",
        "PaymentOverdueAlertIgnore" : "Si vous avez déjà ajouté une carte valide, veuillez ignorer ce message",
        "PleaseAddValidCPayment" : "Aucun abonnement actif, veuillez ajouter un mode de paiement valide !",
        "PleaseConfirmPayment" : "Authentification de la carte requise. Veuillez vérifier la section \"Historique de facturation\" !",
        "PleaseConfirmPaymentMainPage" : "Aucun mode de paiement valide ou authentification de la carte requise. Veuillez vérifier la section \"Mon abonnement\" !",
        "ConfirmDeletePaymentMethod": "Voulez-vous vraiment supprimer les informations de facturation et de carte de crédit ?",
        "ConsentDialogTitle": "S'abonner à SignHero avec paiements récurrents ?",
        "ConsentText1": "SignHero est un service de signature électronique simple et rapide. Envoyez et signez des PDF, suivez les processus, envoyez des rappels aux signataires, accédez aux pistes d'audit, travaillez en équipe et archivez les documents signés.",
        "ConsentText2": "SignHero coûte 5 € par mois et par utilisateur actif + la TVA. Les utilisateurs inactifs ne sont pas pris en compte dans la facturation. Si vous ajoutez ou supprimez des utilisateurs pendant un cycle de facturation, nous calculerons automatiquement le montant minoré ou majoré de la prochaine facture en fonction du temps du cycle de facturation qui restait lorsque la modification a eu lieu. Lorsque vous vous abonnez, vous avez droit à une période d'essai gratuite de 14 jours à l'issue de laquelle vous devez enregistrer une carte de crédit pour le règlement des paiements mensuels si vous souhaitez continuer à utiliser le service. Votre carte de crédit sera automatiquement débitée tous les mois à la même date en fonction du nombre d'utilisateurs enregistrés dans le service.  Une fois votre carte de crédit enregistrée, votre abonnement prendra effet après un paiement réussi et restera valide tant que les paiements auront lieu. Vous recevrez les e-mails suivants concernant votre abonnement à SignHero :",
        "ConsentText3": "Vous devez saisir le numéro de TVA de votre organisation car SignHero collecte la TVA auprès de ses clients conformément à la législation fiscale finlandaise et européenne. Pour les entreprises basées dans l'Union européenne, un numéro de TVA intracommunautaire valide doit être fourni. Si votre pays ne figure pas dans la sélection de pays ou de régions, vous pouvez nous contacter à l'adresse hello@signhero.io pour connaître les autres modes de paiement. Merci de nous indiquer dans votre message si vous n'avez pas trouvé votre pays et si vous avez décidé de ne pas utiliser le service pour cette raison. Il est possible que nous l'ajoutions ultérieurement, auquel cas nous vous en informerons !",
        "ConsentText4": "Pour annuler l'abonnement, vous pouvez supprimer vos informations de carte de crédit et l'abonnement en cours sera annulé à la fin de la période d'abonnement. Vous pouvez également le faire en appuyant sur le bouton “Annuler l'abonnement”.",
        "ConsentEmailBullet1": "un rappel avant la fin d'une période d'essai",
        "ConsentEmailBullet2": "un reçu de votre paiement mensuel à l'adresse e-mail que vous avez indiquée",
        "ConsentEmailBullet3": "une notification par e-mail 7 jours avant chaque transaction",
        "ConsentCheckBoxLabel1": "J'ai lu les informations ci-dessus",
        "ConsentCheckBoxLabel2": "J'accepte de m'abonner au service SignHero avec paiements récurrents",
        "Agree": "Accepter",
        "CurrentPlan" : "Abonnement en cours",
        "SignHeroSubscription" : "Abonnement à SignHero",
        "CancelPlan" : "Annuler l'abonnement",
        "CreatePlan" : "Créer un abonnement",
        "PriceInfo" : "5 € par mois et par utilisateur actif",
        "TaxTooltip" : "Notez que le prix n'inclut pas la TVA/taxe sur les ventes.",
        "PleaseAddValidCard" : "Veuillez ajouter un mode de paiement valide",
        "CancelPlanConfirm" : "Voulez-vous vraiment annuler l'abonnement en cours ?\nVous perdrez le temps d'essai gratuit restant ou le temps restant de l'abonnement que vous avez payé. Vous ne recevrez aucun dédommagement pour le temps restant.",
        "CreatePlanConfirm" : "Voulez-vous vraiment créer un nouvel abonnement ?",
        "numbersDidNotMatch" : "Les codes ne correspondent pas. Veuillez réessayer.",
        "toProceedEnterCode" : "Pour continuer, saisir le code : ",
        "subStatus!trialing" : "Fin de la période d'essai ",
        "subStatus!active" : "Actif",
        "subStatus!canceled" : "Annulé",
        "subStatus!incomplete" : "Non terminé",
        "subStatus!incomplete_expired" : "Arrivé à expiration",
        "subStatus!past_due" : "À payer",
        "ConfirmCreateSubNow" : "Vous pouvez créer un nouvel abonnement maintenant ou plus tard, à partir de la page Mon abonnement.",
        "CreateSubscriptionNow" : "Créer maintenant",
        "CreateSubscriptionLater" : "Plus tard",
        "Country!Australia" : "Australie",
        "Country!Austria" : "Autriche",
        "Country!Belgium" : "Belgique",
        "Country!Brazil" : "Brésil",
        "Country!Bulgaria" : "Bulgarie",
        "Country!Canada" : "Canada",
        "Country!Chile" : "Chili",
        "Country!Croatia" : "Croatie",
        "Country!Cyprus" : "Chypre",
        "Country!CzechRepublic" : "République tchèque",
        "Country!Denmark" : "Danemark",
        "Country!Estonia" : "Estonie",
        "Country!Finland" : "Finlande",
        "Country!France" : "France",
        "Country!Germany" : "Allemagne",
        "Country!Greece" : "Grèce",
        "Country!HongKong" : "Hong Kong",
        "Country!Hungary" : "Hongrie",
        "Country!India" : "Inde",
        "Country!Indonesia" : "Indonésie",
        "Country!Ireland" : "Irlande",
        "Country!Israel" : "Israël",
        "Country!Italy" : "Italie",
        "Country!Japan" : "Japon",
        "Country!Korea" : "Corée",
        "Country!Latvia" : "Lettonie",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Lituanie",
        "Country!Luxembourg" : "Luxembourg",
        "Country!Malaysia" : "Malaisie",
        "Country!Malta" : "Malte",
        "Country!Mexico" : "Mexique",
        "Country!Netherlands" : "Pays-Bas",
        "Country!NewZealand" : "Nouvelle-Zélande",
        "Country!Norway" : "Norvège",
        "Country!Poland" : "Pologne",
        "Country!Portugal" : "Portugal",
        "Country!Romania" : "Roumanie",
        "Country!RussianFederation" : "Fédération de Russie",
        "Country!SaudiArabia" : "Arabie saoudite",
        "Country!Singapore" : "Singapour",
        "Country!Slovakia" : "Slovaquie",
        "Country!Slovenia" : "Slovénie",
        "Country!SouthAfrica" : "Afrique du Sud",
        "Country!Spain" : "Espagne",
        "Country!Sweden" : "Suède",
        "Country!Switzerland" : "Suisse",
        "Country!Taiwan" : "Taïwan",
        "Country!Thailand" : "Thaïlande",
        "Country!UnitedArabEmirates" : "Émirats arabes unis",
        "Country!UnitedKingdom" : "Royaume-Uni",
        "Country!UnitedStates" : "États-Unis",
        "TaxType!au_abn" : "Numéro d'entreprise australien (AU ABN)",
        "TaxType!au_arn" : "Numéro de référence de l'administration fiscale australienne (ATO)",
        "TaxType!eu_vat" : "Numéro de TVA européen",
        "TaxType!br_cnpj" : "Numéro CNPJ brésilien",
        "TaxType!br_cpf" : "Numéro CPF brésilien",
        "TaxType!ca_bn" : "Numéro d'entreprise canadien (BN)",
        "TaxType!ca_gst_hst" : "Numéro GST/HST canadien",
        "TaxType!ca_pst_bc" : "Numéro PST canadien (Colombie-Britannique)",
        "TaxType!ca_pst_mb" : "Numéro PST canadien (Manitoba)",
        "TaxType!ca_pst_sk" : "Numéro PST canadien (Saskatchewan)",
        "TaxType!ca_qst" : "Numéro QST canadien (Québec)",
        "TaxType!cl_tin" : "TIN chilien",
        "TaxType!hk_br" : "Numéro BR de Hong Kong",
        "TaxType!in_gst" : "Numéro GST indien",
        "TaxType!id_npwp" : "Numéro NPWP indonésien",
        "TaxType!il_vat" : "Numéro de TVA israélien",
        "TaxType!jp_cn" : "Numéro d'entreprise japonais",
        "TaxType!jp_rn" : "Numéro d'immatriculation des entreprises étrangères enregistrées au Japon",
        "TaxType!kr_brn" : "Numéro BRN coréen",
        "TaxType!li_uid" : "Numéro UID du Liechtenstein",
        "TaxType!my_frp" : "Numéro FRP malaisien",
        "TaxType!my_itn" : "Numéro ITN malaisien",
        "TaxType!my_sst" : "Numéro SST malaisien",
        "TaxType!mx_rfc" : "Numéro RFC mexicain",
        "TaxType!nz_gst" : "Numéro GST de Nouvelle-Zélande",
        "TaxType!no_vat" : "Numéro de TVA norvégien",
        "TaxType!ru_inn" : "Numéro INN russe",
        "TaxType!ru_kpp" : "Numéro KPP russe",
        "TaxType!sa_vat" : "Numéro de TVA d'Arabie saoudite",
        "TaxType!sg_gst" : "Numéro GST de Singapour",
        "TaxType!sg_uen" : "Numéro UEN de Singapour",
        "TaxType!za_vat" : "Numéro de TVA d'Afrique du Sud",
        "TaxType!es_cif" : "Numéro CIF espagnol",
        "TaxType!ch_vat" : "Numéro de TVA suisse",
        "TaxType!tw_vat" : "Numéro de TVA de Taïwan",
        "TaxType!th_vat" : "Numéro de TVA de Thaïlande",
        "TaxType!ae_trn" : "Numéro TRN des Émirats arabes unis",
        "TaxType!gb_vat" : "Numéro de TVA du Royaume-Uni",
        "TaxType!us_ein" : "Numéro EIN des États-Unis"
    },
    "signflow": {
        "SendReminder": "Envoyer un rappel d'activation",
        "SessionType!password": "Mot de passe",
        "SessionType!auth_code": "Application",
        "Start": "Démarrer",
        "Error!Unexpected": "Une erreur inattendue s'est produite. Veuillez réessayer ultérieurement.",
        "ButtonBack": "Précédent",
        "ButtonGoBack": "Retourner",
        "ButtonNext": "Suivant",
        "Create": "Créer",
        "Complete": "Archiver",
        "SearchSignFlows": "Rechercher par signataire, titre ou document",
        "AllFlows": "Tous",
        "PendingMeFlows": "En attente de ma signature",
        "PendingOthersFlows": "En attente de la signature d'autres personnes",
        "CanceledFlows": "Annulé",
        "CompletedFlows": "Terminé",
        "ShowGroupProcesses": "Tous les processus de l'organisation",
        "CancellationReasons": "Raison : ${cancellationReasons}",
        "SignedDocuments": "Document(s) :",
        "CreatedByTitle": "Créé par :",
        "CanceledByTitle": "Annulé par :",
        "PendingSignaturesTitle": "En attente :",
        "PendingMeAlert": "<span class='elem-pink'>Vous</span> n'avez pas signé.",
        "PendingOthersAlert": "En attente de la signature de <span class='elem-pink'>${count}</span> personne(s)",
        "SignerStatusTitle": "État :",
        "ReminderHistory": "Historique des rappels",
        "SignerStatus1": "En attente, invitation ouverte pour la première fois le ",
        "SignerStatus2": "En attente, invitation jamais ouverte.",
        "SignerStatus3": "Signé le ",
        "SignerStatus4": "En attente au niveau du créateur.",
        "SendReminders": "Rappeler par e-mail",
        "NoProcessesFound": "Aucun processus correspondant à vos filtres",
        "LastEmailReminder": "Dernier rappel : <b>${timestamp}</b>",
        "NoRemindersYet": "Aucun rappel envoyé",
        "EmailReminderSuccess": "Rappels par e-mail envoyés.",
        "ReminderSentBy": "envoyé par",
        "CancelProcessDialogTitle": "Annuler le processus",
        "CancellationReasonsPlaceHolder": "Raisons de l'annulation",
        "CancelProcess": "Annuler le processus",
        "DeleteProcess": "Supprimer le processus",
        "RestartProcess": "Recommencer",
        "ConfirmDeleteProcess": "Voulez-vous vraiment supprimer le processus ?",
        "ResendDownloadInvitation": "Renvoyer le lien de téléchargement",
        "DownloadInvitationEmailSuccess": "E-mail envoyé.",
        "DownloadInvitationEmailFail": "Échec de l'envoi",
        "Me": "Moi",
        "SignFlowDocumentsHeading": "Qu'est-ce qui est signé ?",
        "FlowSettings": "Options supplémentaires",
        "FlowTitlePlaceholder": "Nommer le dossier pour faciliter l'archivage",
        "Signers": "Signataires *",
        "AddSigner": "Ajouter d'autres signataires",
        "AddSignerGroup": "Ajouter des signataires à partir d'un fichier",
        "AddressBookChooseFile": "Sélectionner le fichier",
        "AddressBookInfoHeader": "Comment ajouter des signataires à partir d'un fichier",
        "AddressBookInfo": "Il doit s'agir d'un fichier .txt/.csv comportant une ligne pour chaque signataire et une virgule entre les champs.",
        "AddressBookInfoExampleHeader": "Par exemple, un fichier nommé BoardMembers.txt et ayant le contenu suivant :",
        "AddressBookInfoExampleContent": "James Smith, james.smith@example.com<br>Mary Jones, mary.jones@example.com<br>John Williams, john.williams@example.com<br>Linda Taylor, linda.taylor@example.com",
        "NotAnAddressBook": "Le fichier ne contenait pas les noms et les adresses des destinataires.",
        "CheckFileEncoding": "Le fichier contient des caractères non pris en charge. Veuillez vous assurer que l'encodage du fichier est UTF-8.",
        "SignersName": "Prénom et nom",
        "SignersEmail": "E-mail",
        "EmailSubject": "Titre de l'e-mail :",
        "EmailMessage": "Message :",
        "EditInvitation": "Modifier l'invitation de signature",
        "ChooseInvitation": "Sélectionner le modèle d'invitation",
        "UseDragAndDropSignature": "Définir les emplacements des signatures",
        "NoPrintedEmail": "Masquer les adresses e-mail",
        "StartNow": "Signer maintenant",
        "StartSigningNow": "Vous pouvez signer maintenant ou plus tard à partir de l'onglet En cours.",
        "RequestEmailSendSigners": "Un e-mail de demande de signature est envoyé aux signataires. ",
        "InformProgressNotifications": "Vous serez averti de l'avancement par e-mail.",
        "Later": "Plus tard",
        "Ok" : "OK",
        "DefaultEmailSubject": "${$cc_user_info.name} vous invite à signer",
        "DefaultEmailMessage": "${$cc_user_info.name} de ${$cc_user_info.organization.display_name} (${$cc_user_info.email}) vous a invité à signer électroniquement à l'aide de SignHero.",
        "Signer": "Signataire",
        "Created": "Créé",
        "Canceled": "Annulé",
        "Sign": "Signer",
        "Send": "Envoyer",
        "GoToSign": "Aller signer",
        "Download": "Télécharger",
        "SignedBy": "Signé par :",
        "WhoIsSigning": "Qui signe ?",
        "OnlyMe": "Seulement moi",
        "OnlyOthers": "Seulement les autres",
        "MeAndOthers": "Moi et les autres",
        "Email": "E-mail",
        "Add": "Ajouter",
        "numbersDidNotMatch": "Les codes ne correspondent pas. Veuillez réessayer.",
        "toProceedEnterCode": "Pour continuer, saisir le code : ",
        "Confirm": "Confirmer",
        "Continue": "Continuer",
        "Cancel": "Annuler",
        "Reset": "Réinitialiser",
        "selectFromDisk": "Local",
        "UploadFile": "Charger",
        "fileTooLarge": "La taille maximale de fichier est ${maxSize}.",
        "allFilesTooLarge": "La taille maximale totale pour tous les fichiers est ${maxSize}. Vous essayez actuellement de charger ${uploadedSize}.",
        "fileNumberTooLarge": "Chargez un maximum de ${maxFileNumber} fichiers.",
        "uploadError": "Désolé, il y a un problème avec un fichier. Le format est peut-être incorrect ou le fichier est déjà signé.",
        "select": "Sélectionner",
        "OngoingProcess" : "Processus en cours",
        "GoogleDrive": "Google Drive",
        "DownloadFailed": "Échec du téléchargement de fichier",
        "Error!FilesOutdated" : "Erreur d'expiration de session. Veuillez charger les fichiers et compléter à nouveau les données si nécessaire",
        "Error!DocumentError" : "Désolé, la session a expiré ou il y a un problème avec un fichier. Le format est peut-être incorrect. Veuillez charger les fichiers et compléter à nouveau les données si nécessaire.",
        "OnlyPdf" : "Les types de fichiers acceptés sont les fichiers PDF",
        "OnlyCancelledProcessAllowed": "Vous pouvez uniquement redémarrer un processus annulé.",
        "CreateNewTags" : "Créer une nouvelle étiquette",
        "CreateNewTagsHint" : "Appuyez sur Entrée pour ajouter une nouvelle étiquette : 20 caractères maximum",
        "ManageTags" : "Gérer les étiquettes",
        "Tags" : "Étiquettes",
        "FilterByTag" : "Filtrer par étiquette",
        "ErrorTagExists" : "L'étiquette existe déjà !   ",
        "MyOrganization" : "Mon organisation",
        "OtherOrganizations" : "Autres organisations",
        "OwnedBy" : "Détenu par :",
        "SavedTemplates" : "Sélectionner le modèle d'invitation",
        "CreateTemplateTitle" : "Enregistrer le modèle d'invitation",
        "TemplateCreated" : "Modèle d'invitation enregistré.",
        "DeleteTemplateTitle" : "Supprimer le modèle d'invitation partagé",
        "DeleteTemplateConfirm" : "Voulez-vous vraiment supprimer le modèle d'invitation ? Cela affecte toute l'organisation.",
        "Update" : "Mettre à jour",
        "Edit" : "Modifier",
        "Save": "Enregistrer",
        "ExplainTemplate1" : "L'organisation n'a pas de modèles d'invitation enregistrés. Les modèles d'invitation peuvent être enregistrés après les avoir modifiés.",
        "ExplainTemplate2" : "Vous pouvez enregistrer, mais vous n'êtes pas obligé de le faire ! ",
        "ExplainTemplate3" : "L'invitation à la signature actuelle peut être utilisée sans enregistrer.",
        "ExplainTemplate4" : "Vous pouvez enregistrer des modèles d'invitation fréquemment utilisés. Les modèles créés sont visibles pour toute l'organisation et peuvent être modifiés ou supprimés par n'importe qui dans l'organisation.\nL'invitation à la signature actuelle peut être utilisée sans enregistrer.",
        "UpdateTemplate" : "Le modèle sera visible, modifiable et supprimable pour tout le monde dans l'organisation.\n\nNom du modèle : ",
        "DeleteTemplate" : "Modèle supprimé.",
        "TemplateNoname" : "noname",
        "Error!TemplateNameExist": "Le modèle existe déjà, doit-il être remplacé ?",
        "Error!TemplateNameEmpty": "Le nom du modèle est manquant !",
        "ProcessesTabName1" : "Mes processus",
        "ProcessesTabName2" : "Mes invitations de signature",
        "ShowOnly12MonthInfo" : "Affichage des processus des 12 derniers mois",
        "MaxSigners" : "Le nombre maximum de signataires pour un processus est de ",
        "MaxSigners1" : "Veuillez supprimer ",
        "MaxSigners2" : " signataires.",
        "MaxSigners3" : "Plus de signataires ne peuvent pas être ajoutés."
    },
    "message": {
        "ReportProblem" : "Signaler un problème",
        "IForgotMyPassword" : "Réinitialiser le mot de passe",
        "NavigationMenuButton": "Menu",
        "NavigationAccountButton": "Compte",
        "NavigationContactButton": "Contact",
        "NavItemIntroduction": "Introduction",
        "NavItemChooseEasy": "Choisissez la facilité",
        "NavItemAccount": "Compte",
        "NavItemAPIsIntegrations": "API & Intégrations",
        "NavItemDoubtsWorries": "Doutes et inquiétudes",
        "NavItemThisIsUs": "Voici qui nous sommes",
        "NavItemContactUs": "Nous contacter",
        "MainSlogan": "SignHero - Il n'y a rien de plus simple !",
        "SubSloganPart1": "C'est une révolte contre la complexité, les processus sophistiqués",
        "SubSloganPart2": "et les anciennes méthodes de travail.",
        "GoToRegister": "Commencer l'essai gratuit",
        "TitleLazy": "Choisissez la facilité",
        "TextLazyLine1": "Les numéros d'identification bancaires, les modèles et les gribouillages ne sont d'aucun intérêt :",
        "TextLazyLine2": "ils représentent des processus complexes qui génèrent des pertes de temps et d'argent.",
        "TextLazyLine3": "Les fans de SignHero veulent simplement se concentrer sur l'essentiel. C'est donc ce que nous leur fournissons : l'essentiel.",
        "MetricsTitle": "Chiffres hebdomadaires moyens :",
        "Metrics1" : "+ de 6000 personnes utilisant SignHero",
        "Metrics2" : "+ de 2000 contrats signés",
        "Metrics3" : "+ de 100 nouveaux comptes d'équipes",
        "TitleAccount": "Compte",
        "TextAccount": "Le compte SignHero inclut tout ce dont vous avez vraiment besoin pour signer électroniquement :",
        "Feature1": "Envoi et signature de documents PDF",
        "Feature2": "Suivi des processus",
        "Feature3": "Envoi de rappels aux signataires",
        "Feature4": "Accès aux pistes d'audit",
        "Feature5": "Travail en équipe",
        "Feature6": "Archivage des documents",
        "UnlimitedSigners": "Nombre de signataires et d'invitations illimité.",
        "EasyRegister": "Inscrivez-vous en indiquant votre nom, votre adresse e-mail et votre organisation.",
        "Register": "Créer votre compte",
        "TitlePricing": "Tarification",
        "MonthlyPrice": "5 € / utilisateur / mois",
        "MonthlyBill": "(Facturation mensuelle. Le prix n'inclut pas la TVA/taxe sur les ventes. La TVA/taxe sur les ventes alors en vigueur sera ajoutée au prix.)",
        "Benefits1": "Nombre de signatures électroniques illimité",
        "Benefits2": "Archivage pour vos documents",
        "Benefits3": "Compte d'équipe pour utilisateurs multiples",
        "Benefits4": "Les signataires n'ont pas besoin de compte",
        "ButtonFreeTrial": "Commencer un essai de 14 jours",
        "CreditCardInfo": "Aucune carte de crédit requise",
        "TitleAPI": "API & Intégrations",
        "IntegrateSignHero": "Intégrez notre bouton de signature là où vous en avez besoin.",
        "ToIntroBTN": "Introduction",
        "ToDocsBTN": "Documentation",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "QuoteOlli": "\"Le fait d'avoir intégré la génération et la signature électroniques de contrats dans un environnement WordPress permet à nos clients de gagner des dizaines d'heures.\"",
        "AuthorOlli": "Olli Nyqvist",
        "CompanyOlli": "PDG, WebAula",
        "TitleDoubts": "Doutes et inquiétudes",
        "DoubtsLegal": "La signature électronique est-elle seulement légale ?",
        "TitleShortAnswer": "Réponse courte :",
        "AnswerLegalParagraph1": "Bien sûr. Cela fait déjà dix ans que cette méthode de signature existe.",
        "AnswerLegalParagraph2": "Le fait de signer un document à l'aide d'un stylo n'est pas une méthode de signature particulièrement sûre. La loi est de notre côté : vérifiez les lois",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "et",
        "Acts": ".",
        "TitleLongAnswer": "Réponse longue :",
        "LegalLongAnswerParagraph1": "Vous savez, le fait de signer un document à l'aide d'un stylo n'est pas une manière très sûre de conclure un accord.\nLes signatures manuscrites peuvent être imitées, contestées et même perdues. En fait, si vous utilisez toujours\ncette méthode de signature, vous n'êtes sûr de rien du tout.",
        "LegalLongAnswerParagraph2": "Par ailleurs, l'Union européenne dispose d'une loi sur la liberté contractuelle selon laquelle vous pouvez choisir la façon dont\nvos contrats sont confirmés. Vérifiez que votre entreprise n'est pas soumise à des exigences spécifiques (\nloi sur le blanchiment d'argent, par exemple) et adoptez la signature électronique.",
        "LegalLongAnswerParagraph3": "L'authentification équivaut à une assurance. Par exemple, avez-vous montré votre identifiant lorsque vous avez signé votre\ncontrat de travail actuel ? Certains contrats présentent peu de risques et ne nécessitent donc qu'une assurance légère ; dans\nce cas, l'authentification par e-mail est suffisante. ",
        "LegalLongAnswerParagraph4": "SignHero est actuellement utilisé pour les documents suivants : Contrats commerciaux / Contrats de travail / Accords de confidentialité / Procès-verbaux de réunions\n/ Contrats de location",
        "LegalLongAnswerParagraph5": "Les signatures SignHero conformes aux normes respectent les lois UETA et ESIGN et\n"
                                     + "le règlement eIDAS.",
        "DoubtsPrice": "Comment cela fonctionne ? Seulement 5 € ?",
        "PriceShortAnswerParagraph1": "Il vous suffit de quelques clics pour faire signer vos documents et les archiver. Les invitations de signature sont envoyées par e-mail et il n'est pas nécessaire d'avoir un compte pour signer. Le prix est de 5 € par utilisateur et par mois. Et il sera facturé automatiquement sur une base mensuelle une fois que vous aurez saisi vos informations de carte de crédit. Dans la mesure où vous pouvez annuler votre abonnement à tout moment, vous ne payez que pour un mois à la fois.",
        "PriceLongAnswerParagraph1": "<span class='doubts-break1'>SignHero vous permet de signer des documents PDF à l'aide d'un ordinateur ou d'un appareil mobile et grâce à notre API publique, il peut être intégré à n'importe quel système CRM, d'archivage numérique ou de gestion de documents. Vous pouvez même signer directement dans votre compte Gmail via le plug-in SignHero sans sortir de votre boîte de réception.</span>",
        "PriceLongAnswerParagraph2": "Vous pouvez créer un compte sans obligation d'achat et bénéficier d'une période d'essai gratuite de 14 jours. Aucune information de carte de crédit n'est nécessaire lors de l'inscription. La période d'essai gratuite vous permet d'accéder à toutes les fonctionnalités de sorte que vous pouvez vraiment tester le service. Et ne vous inquiétez pas, vous ne recevrez jamais aucun spam ni aucune newsletter de notre part.",
        "PriceLongAnswerParagraph3": "Le prix est de 5 € par utilisateur et par mois. En tant qu'utilisateur payant, vous pouvez envoyer des demandes de signature, surveiller les événements de signature, stocker vos documents dans l'archive SignHero et travailler en équipe avec un compte d'organisation. Vous pouvez ajouter un nombre pratiquement illimité de membres d'équipe à votre compte et chaque nouvel utilisateur ajouté augmente votre abonnement mensuel de 5 €.",
        "PriceReasons1": "À un certain moment, SignHero doit en effet pouvoir voler de ses propres ailes sans être éternellement soutenu par une société mère aimante (Avaintec).",
        "PriceReasons2": "Si nous ne gagnons pas de l'argent pour payer nos factures, nous ne pourrons pas nous développer et améliorer l'expérience offerte à nos utilisateurs paresseux.",
        "PriceReasons3": "Pas une semaine ne passe sans qu'une personne ne nous demande comment nous exploitons ses données et l'arnaquons avec un service gratuit brillamment déguisé. Et bien, cela est fini. Nous sommes absolument certains qu'après avoir payé 5 € par mois, vous nous ferez davantage confiance. Qui ne ferait pas confiance à un capitaliste ?",
        "DoubtsSaveTime": "Que faire de tout le temps que je gagne ?",
        "SaveTimeAnswer": "Prenez un café, allez à la pêche, non, n'allez pas à la pêche en fait, travaillez un peu, appelez votre mère, faites votre déclaration\nd'impôt, consignez vos heures, recyclez, sauvez le monde, jouez avec votre chien, jouez avec le chien de\nvotre pote, apprenez à jouer d'un instrument, écrivez un poème, faites un gâteau, mangez un gâteau, allez chez le coiffeur, faites\ndes roulades dans la pelouse, parcourez le monde, lisez un livre, façonnez votre destin, inventez une\nreligion, démolissez un mythe, travaillez pour votre compte, allez à une soirée de lancement, levez des fonds, faites une sieste,\nsoyez paresseux, et recommencez.",
        "TitleThisIsUs": "Voici qui nous sommes",
        "LinkedIn": "LinkedIn SignHero",
        "Youtube": "YouTube SignHero",
        "History": "Historique",
        "CompanyName": "Avaintec",
        "CompanyAddress": "Kansakoulukuja 3, 00100 Helsinki",
        "CompanyWeb": "www.avaintec.com",
        "TitleMoreQuestion": "D'autres questions ?",
        "SignHeroEmail": "hello@signhero.io",
        "ApiDocsLink": "Documentation relative à l'API",
        "TermOfUseLink": "Conditions d'utilisation",
        "PrivacyPolicyLink": "Politique de confidentialité",
        "CopyrightYear": "© " + new Date().getFullYear() + " ",
        "CompanyOyName": "Avaintec Oy",
        "RegulationNotice": "Aux États-Unis et dans l'Union européenne, les signatures électroniques ont le même statut juridique que les signatures manuscrites grâce à la",
        "ESignAct": "loi ESIGN",
        "eIDASRegulations": "aux réglementations eIDAS",
        "Docs": "Documentation",
        "SignIn": "Se connecter",
        "TermsOfUse": "Conditions d'utilisation",
        "ThisLocale": "FR",
        "WelcomeToSignHero": "Bienvenue dans SignHero. Veuillez saisir votre adresse e-mail et cliquer sur \"Continuer.\". Vous recevrez un e-mail qui vous permettra de définir votre mot de passe la première fois puis de vous connecter.",
        "ConfirmAccountInfo": "Veuillez vérifier votre e-mail et suivre les instructions qui y figurent afin de définir votre mot de passe et de vous connecter.",
        "MustBeEmail": "Saisissez une adresse e-mail.",
        "MustBeValidFullName": "Saisissez un prénom et un nom valides.",
        "MustBeValidSignatureInvitation": "Les liens hypertexte ne sont pas autorisés.",
        "MustBeUniqueEmail": "Cette adresse e-mail a déjà été ajoutée.",
        "EnterValidCharacters": "Veuillez saisir uniquement des caractères pris en charge.",
        "UserSignedUp": "Bienvenue dans SignHero ! Nous vous avons envoyé un e-mail contenant des instructions sur la façon de procéder pour activer votre compte. Si vous avez déjà un compte, vous pouvez réinitialiser votre mot de passe à partir de l'écran \"Se connecter\".",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "SessionType!password": "Mot de passe",
        "SessionType!auth_code": "Application",
        "YouHaveBeenSignedOut": "Vous avez été déconnecté.",
        "Error!UserExists": "Un utilisateur ayant l'adresse e-mail ou le nom d'utilisateur que vous avez fourni(e) existe déjà. Si vous avez oublié votre mot de passe, cliquez sur \"Se connecter\" puis modifiez-le à l'aide du lien \"Réinitialiser le mot de passe\".",
        "Save": "Enregistrer",
        "Error!Unexpected": "Une erreur inattendue s'est produite. Veuillez réessayer ultérieurement.",
        "Create": "Créer",
        "Complete": "Archiver",
        "Sign": "Signer",
        "Welcome": "Bienvenue",
        "CompleteProcessInfo": "Vous ne pouvez pas signer le(s) document(s) car cette demande de signature a été clôturée. Vous avez peut-être déjà signé ces documents ou le processus a été annulé par l'expéditeur.",
        "SignInHere": "Se connecter",
        "ReasonTitle": "RAISONS PROBABLES",
        "Reason1": "Vous avez déjà signé ce document",
        "Reason2": "L'un des signataires a refusé de signer",
        "Reason3": "L'invitation a été annulée par l'expéditeur",
        "Reason4": "L'invitation a expiré au bout d'un an",
        "Reason5": "L'adresse que vous avez saisie contenait des erreurs",
        "Reason6": "Vous avez reçu un rappel et l'ancien lien a expiré automatiquement",
        "SeeYouSoon" : "À bientôt",
        "HowToOpenExpiredSession" : "Pour rouvrir la session, veuillez cliquer sur le lien figurant dans votre e-mail.",
        "ClearCookieWarning": "Si vous utilisez un appareil public ou partagé, n'oubliez pas d'effacer vos cookies, votre historique et votre cache.",
        "JustASec": "Juste une seconde",
        "SorryUnexpectedError": "Désolé, page introuvable !",
        "InvalidLoginUrl": "Le lien sur lequel vous avez cliqué est peut-être rompu ou l'invitation a été supprimée.",
        "InvitationInvalidatedReason": "Le lien sur lequel vous avez cliqué est peut-être rompu ou l'invitation a été supprimée.",
        "You": "vous",
        "DownloadPageTitle": "Afficher et télécharger les documents signés",
        "DownloadLinkInfo" : "Ce lien restera actif pendant un an à compter de la fin du processus de signature.",
        "Download": "Télécharger",
        "OpenAuditTrail": "Afficher la piste d'audit",
        "ProcessNotFound": "Le processus de signature est introuvable. Veuillez vérifier l'ID du processus de signature.",
        "SignatureEvents": "Signatures",
        "ProcessStatus!COMPLETED": "Terminé",
        "ProcessStatus!CANCELED": "Refusé",
        "ProcessStatus!PENDING": "En attente",
        "ProcessStatus!UNKNOWN": "Inconnu",
        "SignatureProcessId": "ID",
        "SignatureProcess": "Processus de signature",
        "SignatureFlowCreated": "Créé",
        "SignatureFlowStatus": "État",
        "NumberOfDocuments": "Documents",
        "SignedBy": "Signé par",
        "SignTime": "Heure de la signature",
        "AuthType": "Type d'authentification",
        "AuthMethod!verification_code": "Vérification de l'adresse e-mail",
        "AuthMethod!access_token": "Compte SignHero",
        "AuthMethod!strong_auth": "Forte",
        "AuthMethod!otp_token": "Tiers",
        "AuthMethod!otac": "E-mail",
        "AuthMethod!signer_token": "E-mail",
        "AuthMethod!on_site": "En personne",
        "PrivacyPolicy": "Politique de confidentialité",
        "EnterYourDetailsBelow": "Saisissez vos informations ci-dessous.",
        "ThirdParty": "Tiers",
        "SignUpButtonLabel": "Continuer",
        "NameAndSurname": "Vos prénom et nom",
        "Email": "E-mail",
        "Company": "Organisation",
        "ConfirmAccount": "Continuer",
        "Confirm": "Confirmer",
        "ConfirmAccountTitle": "Définissez le mot de passe pour votre compte <span class='elem-pink'>SignHero</span>",
        "ResetPasswordTitle": "Réinitialisez le mot de passe pour votre compte <span class='elem-pink'>SignHero</span>",
        "ResetPassword": "Définir un nouveau mot de passe",
        "Reset": "Réinitialiser",
        "Organization": "Organisation",
        "WelcomeMessage": "Connectez-vous à votre compte <span class='elem-pink'>SignHero</span>",
        "WelcomeText": "Saisissez vos informations ci-dessous.",
        "DontHaveSignHeroAccount": "Vous n'avez pas de compte <span class='elem-pink'>SignHero</span> ?",
        "GetStarted": "Commencer",
        "AlreadyHaveSignHeroAccount": "Vous avez déjà un compte <span class='elem-pink'>SignHero</span> ?",
        "CreateYourSignHeroAccount": "Créer votre compte <span class='elem-pink'>SignHero</span>",
        "AcceptTermsOfUseAndPrivacyPolicy": "En cliquant sur Continuer, vous acceptez les <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>Conditions d'utilisation</a> et la <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>Politique de confidentialité</a> de SignHero.",
        "YouFoundApi": "Vous avez trouvé l'API SignHero !",
        "CheckApiDocumentation": "Pour commencer, consultez notre dernière version de la <a class=='elem-pink' href='${apiDocsLink}'>documentation relative à l'API</a>.",
        "AuditTrail" : "Piste d'audit",
        "EnterProcessId" : "Saisir l'ID de processus de signature qui est indiqué dans la case de signature dans chaque document :",
        "SubmitID" : "Soumettre",
        "NoProcessId" : "Veuillez saisir l'ID du processus.",
        "ConfirmError1" : "Veuillez demander une nouvelle invitation ou un lien de réinitialisation du mot de passe à partir de la page ",
        "ConfirmError2" : " .",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "Ce lien a peut-être expiré.<br>Allez dans <a href='/#/signin' class='elem-pink'>la page de connexion</a> pour réinitialiser votre mot de passe.",
        "Error!tax_id_invalid" : "Identifiant fiscal non valide.",
        "CreditCardSaved" : "La carte de crédit a été ajoutée.",
        "BillingInformationSaved" : "Informations de facturation enregistrées.",
        "dateFormat" : "MMM jj, aaaa",
        "timeFormat" : "jj.MM, HH:mm",
        "Error!card_declined" : "La carte de crédit a été refusée par la passerelle de paiement.",
        "Error!incorrect_cvc" : "La carte de crédit a été refusée par la passerelle de paiement.",
        "Error!expired_card" : "La carte de crédit a été refusée par la passerelle de paiement.",
        "Error!processing_error" : "La carte de crédit a été refusée par la passerelle de paiement.",
        "Contact" :  "Contact",
        "name" : "Nom",
        "email" : "E-mail",
        "LoginEmail" : "E-mail",
        "EnterYourEmail" : "Veuillez saisir votre adresse e-mail :",
        "Password" : "Mot de passe",
        "ForgotYourPassword" : "Vous avez oublié votre mot de passe ?",
        "RequestConfirmToken" : "Essayez de confirmer à nouveau",
        "HelloThere" : "Bonjour,",
        "ChangePassword" : "Modifier le mot de passe",
        "CurrentPassword" : "Mot de passe actuel",
        "NewPassword" : "Nouveau mot de passe",
        "RepeatPassword" : "Répéter le mot de passe",
        "PasswordSet" : "Mot de passe défini.",
        "MessageSent" : "Message envoyé. Nous vous contacterons si nécessaire.",
        "SignHeroSentPasswordResetRequest" : "Demande de réinitialisation de mot de passe envoyée si vous êtes un utilisateur inscrit. Veuillez vérifier vos e-mails.",
        "SignHeroSentConfirmTokenRequest" : "Confirmation de nouveau compte demandée. Veuillez vérifier vos e-mails.",
        "AuthCodeCreated" : "Nous vous avons envoyé un lien par e-mail. Cliquez dessus pour vous connecter.",
        "SentPasswordResetRequest" : "Demande de réinitialisation de mot de passe envoyée si vous êtes un utilisateur inscrit. Surveillez vos e-mails !",
        "SentConfirmTokenRequest" : "Nouvelle confirmation de compte demandée. Surveillez vos e-mails !",
        "DefaultDialogTitle" : "",
        "Action!SetPassword" : "Définir le mot de passe",
        "Action!ConfirmAccount" : "Confirmer le compte",
        "TooltipSetPassword" : "Adresse e-mail incorrecte ou les mots de passe ne correspondent pas.",
        "Action!EditProfile" : "Modifier",
        "Action!SignOut" : "Se déconnecter",
        "Action!SignIn" : "Se connecter",
        "Update" : "Mettre à jour",
        "Delete" : "Supprimer",
        "Cancel" : "Annuler",
        "Accept" : "Accepter",
        "Submit" : "Soumettre",
        "Error!PasswordChangeFailedInvalidPassword" : "Votre ancien mot de passe ne correspondait pas. Veuillez réessayer.",
        "Error!InvalidEmail" : "Saisissez votre adresse e-mail.",
        "Error!InvalidFullName" : "Saisir un prénom et un nom valides",
        "Error!InvalidPasswordReset" : "Votre jeton de réinitialisation de mot de passe n'était pas valide ou a expiré. Demandez-en un nouveau à partir du lien \"Réinitialiser le mot de passe\" puis réessayez.",
        "Error!InvalidCredentials" : "Adresse e-mail ou mot de passe non valide.",
        "Error!InvalidScopes" : "Vos droits d'accès ne permettent pas l'utilisation de cette application. Veuillez contacter l'administration.",
        "Error!TemporarilyLockedOut" : "Connexion depuis cet appareil temporairement bloquée en raison d'échecs répétés. Si vous avez oublié votre mot de passe, réinitialisez-le à l'aide du lien \"Réinitialiser le mot de passe\". La connexion sera débloquée dans ${minutes} minutes.",
        "Error!TokenCheckFailed" : "Ce lien a peut-être expiré.<br>Allez dans la page de connexion pour réinitialiser votre mot de passe.",
        "Error!too_many_incorrect_email": "Trop d'adresses e-mail incorrectes. Veuillez réessayer demain.",
        "PasswordRequirement" : "Votre mot de passe doit comprendre au moins 10 caractères et inclure un chiffre, une majuscule, une minuscule et un caractère spécial. Vous pouvez également utiliser un minimum de 15 caractères. N'utilisez pas plus de deux caractères identiques à la suite.",
        "EmailRequired" : "L'adresse e-mail est requise",
        "EmailMustValid" : "L'adresse e-mail doit être valide",
        "PasswordMustValid" : "Les mots de passe doivent respecter des exigences de complexité",
        "RepeatPasswordMustSame" : "Les mots de passe doivent être identiques",
        "TitleNews" : "Actualités",
        "ReadMoreLink" : "Lire la suite",
        "OtherNewsBtn" : "Autres actualités",
        "NewsListBg1" : "Voici ce à quoi nous nous consacrons",
        "NewsListBg2" : "Lisez les actualités concernant nos réalisations, nos lancements et nos apparitions dans les médias.",
        "Share" : "Partager",
        "Locales": "Langue",
    },
    "client": {
        "ClientSecretInfo": "Stockez le secret du client de manière sécurisée. Vous pouvez le modifier mais vous ne pourrez plus le voir une fois cette session terminée.",
        "Error!InvalidDomain": "Nom de domaine non valide.",
        "DomainValidationDownloadInfo1": "Pour vérifier votre domaine :",
        "DomainValidationDownloadInfo2": "Téléchargez le fichier suivant :",
        "DomainValidationDownloadInfo3": "Chargez le fichier dans le répertoire racine de votre domaine :",
        "DomainValidationDownloadInfo4": "Vérifiez que vous pouvez accéder au fichier à partir du lien :",
        "DomainValidationDownloadInfo5": "Revenez sur cette page et cliquez sur \"Vérifier le domaine\"",
        "LastClientSecretReset": "Dernière modification",
        "FailedDomainValidation": "Échec de la validation de votre domaine. Avez-vous suivi les instructions ?",
        "OAuthApplications": "Applications OAuth2",
        "Application": "Application OAuth2",
        "IntegrateWithOAuth": "Intégrer à OAuth2",
        "NewOAuthApplication": "Nouvelle application OAuth2",
        "ApplicationName": "Nom de l'application *",
        "AddOAuthApplication": "Ajouter une application",
        "AddUri": "Ajouter d'autres URI",
        "CreationDate": "Date de création",
        "CreatedBy": "Créé par",
        "AllowedGrantTypes": "Types d'octrois autorisés",
        "OAuthRedirectURIs": "URI de redirection *",
        "OAuthClientsDomainURI": "Domaine",
        "OAuthClientsDomainTitle": "Domaine d'application *",
        "AppDescription": "Description de l'application",
        "VerifyDomain": "Vérifier le domaine",
        "DomainVerified": "Le domaine a été vérifié.",
        "WhyValidateDomain": "Vous devez vérifier la propriété de votre domaine. L'application ne peut pas être utilisée tant que le domaine n'a pas été vérifié avec succès. Pour plus d'informations, reportez-vous à la <a class='paragraph-links' href='/docs/api' target='_blank'>documentation relative à l'API</a>.",
        "ClientSecret": "Secret client",
        "ClientId": "ID client",
        "ClientCredentials": "Informations d'identification du client",
        "VerifiedApp": "Actif",
        "UnverifiedApp": "Inactif",
        "ResetClientSecret": "Modifier le secret",
        "ConfirmDeleteApiClient": "Voulez-vous vraiment supprimer cette application ?\nPour continuer, veuillez saisir le code : ${rndCode}",
        "ConfirmResetClientSecret": "Voulez-vous vraiment réinitialiser le secret du client ?\nPour continuer, veuillez saisir le code : ${rndCode}",
        "AppHasBeenDeleted": "L'application ${appName} a été supprimée.",
        "AppHasBeenCreated": "L'application ${appName} a été créée.",
        "YourChangesHaveBeenSaved": "Vos modifications ont été enregistrées.",
        "ClientSecretChanged": "Le secret du client a été modifié.",
        "CheckApiDocs": "Vous devez vérifier la propriété de votre domaine pour pouvoir utiliser l'application. Pour plus d'informations, consultez la <a href='/docs/api' target='_blank'>documentation relative à l'API</a>."
    },
    "oauth2consent": {
        "AllowAccess": "Autoriser l'accès",
        "OAuthAccessDescriptionHeader": "<span class='elem-pink'>${appName}</span> a besoin de votre autorisation pour que vous puissiez l'utiliser pour :",
        "OAuthBasicPermissions": "  <li>Envoyez des invitations à signer et des rappels</li>" +
                                 "  <li>Accéder aux invitations de signature envoyées à l'aide de cette application et les annuler et les supprimer</li>" +
                                 "  <li>Accéder aux documents signés envoyés pour signature à l'aide de cette application</li>",
        "OAuthSigningUrlPermissions": " <li>Signer des documents chargés à l'aide de cette application</li>",
        "OAuthProfilePermissions": " <li>Accéder à vos nom, adresse-mail, nom d'entreprise, nom d'organisation, langue de communication sélectionnée " +
                                    "et voir si vous avez des droits administrateur</li>",
        "OAuthAllDataPermissions": " <li>Accéder à toutes vos invitations de signature et les annuler et les supprimer</li>" +
                                   " <li>Accéder à tous vos documents</li>",
        "OAuthAccessWarning": "Cela signifie également que vous acceptez les conditions de service et la politique de confidentialité de ${appName}.",
        "QuestionToAllowAccess": "Autoriser <span class='elem-pink'>${appName}</span> à accéder à votre compte SignHero " +
                                 "<span class='elem-bold'>${userEmail}</span> ?<br><br>Vous pouvez toujours changer " +
                                 "d'avis dans les paramètres de votre compte SignHero.",
        "Cancel": "Annuler",
        "Allow": "Autoriser",
        "NotFound": "Désolé, application introuvable",
        "OAuthResourceNotFound": "L'application qui vous a dirigé ici doit malheureusement résoudre le problème."
    }
}
