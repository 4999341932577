<template>
  <v-card flat class="place-signature-document">
    <v-toolbar>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="alternative-primary-btn"
                 v-bind="attrs"
                 v-on="on"
                 :disabled="!page_loaded || !doc_loaded">
            {{ $t( 'pdfsign.SelectSigner' ) }}
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="signer in my_signers" :key="signer.signerEmail" @click="pickupSigner( signer )"
                       :input-value="signer === cur_signer"
                       :disabled="!page_loaded || !doc_loaded">
            <v-list-item-action>
              <v-icon v-if="!!signer.placed">mdi-check</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ signer.signerName }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div style="font-size:1px;visibility:hidden;font-family: LiberationSans">please preload</div>
      <v-spacer/>
      <v-btn icon @click="changeScale( 1 )"
             :disabled="!doc_loaded || !page_loaded || !cur_signer || cur_scale >= max_scale">
        <v-icon>mdi-format-font-size-increase</v-icon>
      </v-btn>
      <v-btn icon @click="changeScale( -1 )"
             :disabled="!doc_loaded || !page_loaded || !cur_signer || cur_scale <= min_scale">
        <v-icon>mdi-format-font-size-decrease</v-icon>
      </v-btn>
      <v-btn icon @click="bringToFront()"
             :disabled="!doc_loaded || !page_loaded || !cur_signer || page === cur_signer.page">
        <v-icon>mdi-flip-to-front</v-icon>
      </v-btn>
      <v-btn icon @click="changePage( -1 )" :disabled="!doc_loaded || page === 1">
        <v-icon>mdi-arrow-left-thick</v-icon>
      </v-btn>
      <div class="pa-5 sh-page-count"> {{ page }} / {{ page_count || '?' }}</div>
      <v-btn icon @click="changePage( 1 )" :disabled="!doc_loaded || page === page_count">
        <v-icon>mdi-arrow-right-thick</v-icon>
      </v-btn>
<!--      <v-btn icon @click="changeZoom( 1 )" :disabled="!doc_loaded || !page_loaded || cur_zoom >= max_zoom">-->
<!--        <v-icon>mdi-magnify-plus</v-icon>-->
<!--      </v-btn>-->
<!--      <v-btn icon @click="changeZoom( -1 )" :disabled="!doc_loaded || !page_loaded || cur_zoom <= min_zoom">-->
<!--        <v-icon>mdi-magnify-minus</v-icon>-->
<!--      </v-btn>-->
    </v-toolbar>
    <v-container>
      <v-row justify="center">
        <v-card :style="'width:' + pdf_width + 'px'" class="pa-0 ma-0" flat>
          <vue-pdf-embed ref="pdfRef" v-if="document_src" :source="document_src" :style="`width:${pdf_width}px`" @loaded="doc_loaded = true;"
               @rendered="handleDocumentRender"
               :page="page"></vue-pdf-embed>
          <div ref="overlay" style="position:absolute;top:0;left:0;bottom:0;right:0;" @mousemove="onMouseMove">
            <v-card v-for="signer in my_signers"
                    ref="signer_token"
                    :class="signer === cur_signer ? 'pa-0 ma-0 sh-signer-token-active sh-signer-token' : 'pa-0 ma-0 sh-signer-token'"
                    :flat="!!signer.placed"
                    :key="signer.signerEmail"
                    :ripple="false"
                    :elevation="!!signer.placed ? 0 : 10"
                    :style="`cursor:pointer;position:absolute;top:${signer.top}%;left:${signer.left}%;width:${signer.width * token_scale}px;height:${signer.height * token_scale}px;visibility:${!!signer.selected && signer.page === page ? 'visible' : 'hidden' };`"
                    @click="placeSigner( signer )">
              <v-container fill-height fluid>
                <v-row align="center"
                       justify="center" no-gutters>
                  <v-col v-if="!hiddenEmail && ( signatureLevel === 'basic' || !signatureLevel )" class="pa-0">
                    <div class="sh-signer-name pa-0" :style="`font-size:${12 * token_scale}px;`">
                      <div>{{ signer.signerName }}</div>
                      <div>{{ signer.signerEmail }}</div>
                      <div>{{ $t( 'pdfsign.SignatureTimestampFormat' ) }}</div>
                    </div>
                  </v-col>
                  <v-col v-if="hiddenEmail && ( signatureLevel === 'basic' || !signatureLevel )" class="pa-0">
                    <div class="sh-signer-name pa-0" :style="`font-size:${12 * token_scale}px;`">
                      <div>{{ signer.signerName }}</div>
                      <div>&nbsp;</div>
                      <div>{{ $t( 'pdfsign.SignatureTimestampFormat' ) }}</div>
                    </div>
                  </v-col>
                  <v-col v-if="signatureLevel === 'advanced'" class="pa-0">
                    <div  class="sh-signer-name pa-0" :style="`font-size:${12 * token_scale}px; text-align:left`">
                      <div style="color: #ff507a">{{ signer.signerName }}</div>
                      <div style="font-size: 10px">{{ $t( 'pdfsign.SignatureTimestampFormat' ) }}</div>
                      <div style="font-size: 10px">{{ $t( 'pdfsign.SignatureTypeFormat' ) }}</div>
                      <div style="font-size: 10px">{{ $t( 'pdfsign.SignatureAdvancedLevelFormat' ) }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </div>
        </v-card>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
    import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
    import SignatureImageRenderer from "./SignatureImageRenderer";
    import AdvancedSignatureImageRenderer from "./AdvancedSignatureImageRenderer";

    export default {
        components : {
            VuePdfEmbed,
        },
        props : {
            // Document.
            document : Object,
            // Unscaled document width in pixels
            documentWidth : { type : Number, default : 794 },
            // Unscaled token width in pixels
            tokenWidth : { type : Number, default : 200 },
            // Unscaled token height in pixels
            tokenHeight : { type : Number, default : 100 },
            // Step when changing zoom
            zoomFactor : { type : Number, default : 0.15 },
            // Step when changing token scale
            scaleFactor : { type : Number, default : 0.1 },
            // Signers
            signers : Array,
            // Allowed signature methods
            signatureLevel : { type : String },
            // Email is hidden
            hiddenEmail: { type : Boolean }
        },
        data()
        {
            return {
                // Document source
                document_src : undefined,
                // Has the doc loaded?
                doc_loaded : false,
                // Has the page loaded?
                page_loaded : false,
                // Number of pages
                page_count :undefined,
                // Page dimensions
                page_dimensions : undefined,
                // Current zoom
                cur_zoom : 1,
                // Minimum zoom
                min_zoom : 0.75,
                // Maximum zoom
                max_zoom : 1.25,
                // Current token scale
                cur_scale : 1,
                // Minimum scale
                min_scale : 0.75,
                // Maximum scale
                max_scale : 1.5,
                // Current page
                page : 1,
                // Pages in document
                pageCount : 0,
                // Signers, preprocessed from props
                my_signers : [],
                // Current signer
                cur_signer : undefined,
                // Scale
                scale : 1,
                // Placeholder
                bigbox : undefined
            }
        },
        computed : {
            // Actual scale of displayed PDF.
            real_scale()
            {
                return 1.34;
            },
            // Actual scale of signing token.
            token_scale()
            {
                return this.cur_scale * this.cur_zoom;
            },
            // Pixel size of PDF accounting for zoom.
            pdf_width()
            {
                return this.documentWidth * this.cur_zoom;
            }
        },
        methods : {
            /**
             * Set page_loaded flag.
             */
            handleDocumentRender() {
                  this.isLoading = false;
                  this.page_count = this.$refs.pdfRef.pageCount;
                  const something = this.$refs.pdfRef;
                  this.bigbox = something.$el.getBoundingClientRect();

                  this.pageWidth  = this.bigbox.width;
                  this.pageHeight = this.bigbox.height;
                  this.page_loaded = true;
                  if ( this.pageWidth < this.pageHeight )
                  {
                      this.page_dimensions = {
                          width : this.pageWidth,
                          height : this.pageHeight,
                          scale : this.scale,
                          width_mm : this.pageWidth * 25.4 / 96,
                          height_mm : this.pageHeight * 25.4 / 96
                      }
                  } else
                  {
                      this.page_dimensions = {
                          width: this.pageWidth,
                          height: this.pageHeight,
                          scale: this.scale,
                          width_mm: this.pageWidth * 38.1 / 96,
                          height_mm: this.pageHeight * 38.1 / 96
                      }
                  }
                },
            onPageLoaded( page )
            {
                this.page_loaded = true;
            },
            /**
             * Set page_dimensions, also computed height and width in mm.
             *
             * @param pageWidth {number}
             * @param pageHeight {number}
             * @param scale {number}
             */
            onPageSize( pageWidth, pageHeight, scale )
            {
                this.page_dimensions = {
                    width : pageWidth,
                    height : pageHeight,
                    scale : scale,
                    width_mm : pageWidth / scale * ( 25.4 / 96 ),
                    height_mm : pageHeight / scale * ( 25.4 / 96 )
                }
            },
            /**
             * Load a PDF from file or URL and initialise its signatures from doc.
             *
             * @param doc {Object}
             */
            loadPdf( doc )
            {
                if( doc.file )
                {
                    const reader = new FileReader();
                    reader.onload = e =>
                    {
                        this.document_src = new Uint8Array( e.target.result );
                    };
                    reader.readAsArrayBuffer( doc.file );
                }
                else
                {
                    this.document_src = doc.url;
                }
                for( let i = 0; i < this.my_signers.length; i++ )
                {
                    if( doc.signatures && doc.signatures[ i ] )
                    {
                        this.cur_scale = doc.signatures[ i ].scale;
                        this.my_signers[ i ].selected = true;
                        this.my_signers[ i ].placed = true;
                        this.my_signers[ i ].top = doc.signatures[ i ]._top_px;
                        this.my_signers[ i ].left = doc.signatures[ i ]._left_px;
                        this.my_signers[ i ].page = doc.signatures[ i ].page;
                    }
                    else
                    {
                        this.cur_scale = 1;
                        this.my_signers[ i ].placed = false;
                        this.my_signers[ i ].selected = false;
                        this.my_signers[ i ].top = 0;
                        this.my_signers[ i ].left = 0;
                    }
                }
                this.page = 1;
            },
            /**
             * Bring the selected signature to the visible page.
             */
            bringToFront()
            {
                this.pickupSigner( this.cur_signer );
            },
            /**
             * Change scale factor by .scaleFactor * n.
             *
             * @param n {number}
             */
            changeScale( n )
            {
                this.cur_scale = this.cur_scale + this.scaleFactor * n;
                this.my_signers.forEach( signer => this._moveSigner( signer ) );
            },
            /**
             * Change page by n.
             *
             * @param n {number}
             */
            changePage( n )
            {
                this.page_loaded = false;
                this.page += n;
            },
            /**
             * Change zoom by .zoomFactor * n.
             *
             * @param n
             */
            changeZoom( n )
            {
                this.cur_zoom += n * this.zoomFactor;
            },
            /**
             * Return info about placed signatures.
             *
             * @return {Object}
             */
            getSignatures()
            {
                const signatures = [];
                let allPlaced = true;
                this.my_signers.forEach( signer =>
                {
                    if( signer.placed )
                    {
                        signatures.push( {
                            _top_px : signer.top,
                            _left_px : signer.left,
                            page : signer.page,
                            top : 0.01 * signer.top * this.page_dimensions.height_mm,
                            left : 0.01 * signer.left * this.page_dimensions.width_mm,
                            height : signer.height * this.token_scale / this.real_scale * ( 25.4 / 72 ),
                            width :  signer.width * this.token_scale / this.real_scale * ( 25.4 / 72 ),
                            scale : this.cur_scale,
                            textAlign : "center",
                            shouldFillBackground : false
                        } );
                    }
                    else
                    {
                        allPlaced = false;
                        signatures.push( null );
                    }
                } );
                return {
                    signatures_placed : allPlaced,
                    fileName : this.document.fileName,
                    token : this.document.token,
                    signatures : signatures
                };
            },
            /**
             * Draw signature on canvas and report the pixel dimensions of the image.
             *
             * @param signature {Object} - the signature object of the signer
             * @public void
             */
            getSignatureSize( signature )
            {
                const tmpCanvas = document.createElement( "canvas" );
                tmpCanvas.width = this.page_dimensions.width;
                tmpCanvas.height = this.page_dimensions.height;
                let signatureForRender = {};
                signatureForRender.name = signature.signerName;
                signatureForRender.timestamp = this.$t( 'pdfsign.SignatureTimestampFormat' );
                if( this.signatureLevel === "advanced" )
                {
                    const renderer = new AdvancedSignatureImageRenderer( {
                        x : 0,
                        y : 0,
                        scale : this.real_scale,
                        zoom : 1
                    } );
                    signatureForRender.signatureMethod = this.$t( 'pdfsign.SignatureMethodFormat' );
                    signatureForRender.signatureLevel = this.$t( 'pdfsign.SignatureLevelFormat' );
                    return renderer.renderSignatureImage( tmpCanvas, signatureForRender );
                }
                else
                {
                    const renderer = new SignatureImageRenderer( {
                        x : 0,
                        y : 0,
                        scale : this.real_scale,
                        zoom : 1
                    } );
                    signatureForRender.email = signature.signerEmail;
                    return renderer.renderSignatureImage( tmpCanvas, signatureForRender );
                }
            },
            /**
             * Make signer the current one, and display it on the visible page.
             *
             * @param signer
             */
            pickupSigner( signer )
            {
                this.cur_signer = signer;
                signer.selected = true;
                signer.page = this.page;
                Object.assign( signer, this.getSignatureSize( signer ) );
                this._getToken( signer ).$el.style.visibility = "visible";
            },
            /**
             * Drop the current signer at its current position.
             *
             * @param signer
             */
            placeSigner( signer )
            {
                this.cur_signer = signer;
                signer.placed = !signer.placed;
                this.$emit( "signature-placed", this.getSignatures() );
            },
            /**
             * If the current signer is not placed (=being moved), move it to the mouse location.
             *
             * @param evt {Event}
             */
            onMouseMove( evt )
            {
                if( this.cur_signer && !this.cur_signer.placed )
                {
                    this._moveSigner( this.cur_signer, evt );
                }
            },
            /**
             * Move signer to box.x, box.y if provided, else place it at the centre of the signer's token's bounding
             * box.
             *
             * @param signer {Object}
             * @param box {Object?}
             * @private
             */
            _moveSigner( signer, box )
            {
                if( !signer.selected )
                {
                    return;
                }
                const token = this._getToken( signer )
                if( !box )
                {
                    box = token.$el.getBoundingClientRect();
                    box.x += box.width / 2;
                    box.y += box.height / 2;
                }
                const bounds = this.$refs.overlay.getBoundingClientRect();
                const mid = {
                    x : signer.width * this.token_scale / -2,
                    y : signer.height * this.token_scale / -2
                };
                const max = {
                    x : bounds.width - signer.width * this.token_scale,
                    y : bounds.height - signer.height * this.token_scale
                };
                const cur = {
                    x : box.x - bounds.x + mid.x,
                    y : box.y - bounds.y + mid.y
                };
                signer.top = 100 * ( Math.max( 0, Math.min( cur.y, max.y ) ) ) / bounds.height;
                signer.left = 100 * ( Math.max( 0, Math.min( cur.x, max.x ) ) ) / bounds.width;
                token.$el.style.top = signer.top + '%';
                token.$el.style.left = signer.left + '%';
            },
            /**
             * Return token matching signer.
             *
             * @param signer {Object}
             * @returns {*}
             * @private
             */
            _getToken( signer )
            {
                return this.$refs[ "signer_token" ][ this.my_signers.indexOf( signer ) ];
            }
        },
        /**
         * Populate my_signers from .signers and load PDF specified in .document.
         */
        mounted()
        {
            this.signers.forEach( signer =>
            {
                const _signer = { ...signer };
                _signer.selected = false;
                _signer.placed = false;
                this.my_signers.push( _signer );
            } );
            this.loadPdf( this.document );
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../styles/style"

  .sh-signer-token
    background-color: rgba(222, 238, 255, 0.5)
    text-align: center

  .sh-signer-token-active
    background-color: rgba(222, 238, 255, 1)
    text-align: center

  .sh-signer-name
    font-size: 12px

  .sh-signer-email
    font-size: 11px
    color: gray

  .sh-signer-date
    font-size: 14px

  .sh-page-count
    text-align: center
    width: 75px
    white-space: nowrap

  .place-signature-document
    background-color: $mid-grey
    overflow: hidden
</style>
