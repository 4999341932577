<template>
  <v-container class="pt-4 maxWidth-1100">
    <h1 class="shio-section-title">{{ $t( "signedin.MyOrganization" ) }}</h1>
    <v-responsive class="pt-5">
      <h3 class="sh-si-subSectionTitle pl-4 font-weight-bold">{{ $t( "signedin.OrganizationName" ) }}</h3>
      <div v-if="isAdmin" class="pl-2">
        <div class="px-2">
          <v-row>
            <v-col cols="12" sm="12" md="5" lg="6" class="pb-0">
              <v-text-field v-model="orgName" :rules="[validateFullName]">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" class="ma-auto pa-1">
              <v-btn class="secondary-btn" :disabled="!orgNameChanged" @click="resetOrgName()">{{ $t( "signedin.Reset" ) }}
              </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" class="ma-auto pa-1">
              <v-btn class="primary-btn" :disabled="!orgNameChanged || ( orgName === '' )" @click="saveOrgName()">{{ $t( "signedin.Save" ) }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <p v-if="!isAdmin" class="text-left px-4 pt-4">
        {{ orgName }}
      </p>
      <div class="mt-4">
        <div class="px-2">
          <div class="pl-2">
            <div v-if="isAdmin && organization.reseller_enabled && !stripePaymentOn && resellers[ organization.reseller ]" class="text-left">
              <h3 class="sh-si-subSectionTitle font-weight-bold mb-4">{{ $t( "signedin.SalesChannel" ) }}</h3>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                  <p>{{ $t( "signedin.ResellerName" ) }}<span class="font-weight-bold">{{ resellers[ organization.reseller ].reseller_name }}</span></p>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                  <p>{{ $t( "signedin.ResellerContact" ) }}<span class="font-weight-bold">{{ resellers[ organization.reseller ].contact_email }}</span></p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" md="6" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                  <p>{{ $t( "signedin.ResellerMaxUsers" ) }}<span class="font-weight-bold">{{ organization.reseller_max_users }}</span></p>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                  <p>{{ $t( "signedin.ResellerCurrentInvitedUsers" ) }}<span class="font-weight-bold">{{ resellerUsersCount }}</span></p>
                </v-col>
              </v-row>
              <p v-if="organization.reseller_max_users && resellerUsersCount >= organization.reseller_max_users">
                <span class="font-weight-bold pink--text">
                  {{ max_reseller_users_warning }}
                </span>
              </p>
            </div>
            <v-divider class="mt-7 mb-3"/>
            <div v-if="stripePaymentOn && isSubscriptionAdmin && !organization.reseller_enabled" class="text-left">
              <h3 class="sh-si-subSectionTitle font-weight-bold mb-4">{{ $t( "signedin.Subscription" ) }}</h3>
              <p>{{ $t( "signedin.StartOfSubscription" ) }} {{ subscription.current_period_start }}. {{ estimated_billing }}</p>
              <p v-if="subscription.has_valid_subscription || subscription.has_payment_past_due">{{ $t( "signedin.SubscriptionPeriod" ) }} {{ subscription.current_period_start }} -  {{subscription.current_period_end}}</p>
              <p v-if="subscription.has_trial_period">{{ $t( "signedin.FreeTrialLeft" ) }} {{subscription.trial_days_left}} {{ $t( "signedin.Days") }}</p>
            </div>
            <h3 class="sh-si-subSectionTitle font-weight-bold mt-5">{{ $t( "signedin.ManageAccounts" ) }}</h3>
            <v-text-field v-model="search" :label="$t( 'signedin.SearchUsers' )" append-icon="mdi-account-search"
                          @keydown="onSearchChange">
              <template v-slot:prepend>
              </template>
            </v-text-field>
          </div>
        </div>
        <v-expansion-panels focusable class="px-4">
          <sh-new-user-card
              ref="newUserCard"
              v-if="isAdmin && ( !organization.reseller_max_users || resellerUsersCount < organization.reseller_max_users )"
              @user-created="retrieveUsers()"
              class="new-user-card"
          />
          <sh-user-card
              class="user-cards"
              v-for="user in filtered_users"
              :key="user.email"
              :user="user"
              :isAdmin="isAdmin"
              :disableResellerToggle="( organization.reseller_max_users && resellerUsersCount >= organization.reseller_max_users )"
              :isSubscriptionAdmin="isSubscriptionAdmin"
          >
            {{ user.email }}
          </sh-user-card>
        </v-expansion-panels>
        <div class="px-2" v-if="enabled_signature_types.length > 1 && isAdmin">
          <div class="pl-2">
            <v-divider class="mt-7 mb-3"/>
            <h3 class="sh-si-subSectionTitle font-weight-bold mt-5">{{ $t( "signedin.ManageSignatureTypes" ) }}</h3>
            <v-row class="pt-5">
              <v-col>
                <p class="text-left">
                <span class="font-weight-light text--black">
                  {{ $t( "signedin.ManageSignatureTypesHelp" ) }}
                </span>
                </p>
              </v-col>
            </v-row>
            <v-row class="mt-4 pl-3">
              <v-col cols="12" sm="12" md="7" lg="6" class="pb-0">
                <v-row v-for="signature_type in enabled_signature_types" style="align-items: center;">
                    <v-col cols="12" sm="9" md="9" lg="9" class="pa-0 ma-0">
                      <v-checkbox :ripple="false" class="ma-0 shio-checkbox" v-model="orgSignatureTypes" :value="signature_type" @change=""
                              :disabled="( signature_type === activeSignatureTypeId )">
                        <template v-slot:label>
                          {{ $t("signedin.SignatureType!" + signature_type ) }}
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3" class="pa-0 mb-1">
                      <v-switch
                          class="mt-0 shio-checkbox"
                          :label="$t( 'signedin.SignatureDefault' )"
                          :input-value="signature_type === activeSignatureTypeId"
                          @change="setActiveSignatureType( signature_type )"
                          :disabled="( signature_type === activeSignatureTypeId ) || !orgSignatureTypes.includes( signature_type )"
                      />
                    </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="5" lg="6" class="mt-3">
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="6" class="ma-auto pa-1">
                    <v-btn class="secondary-btn" :disabled="!signatureTypesChanged && !defaultSignatureChanged" @click="resetOrgSignatureTypes()">{{ $t( "signedin.Reset" ) }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="6" class="ma-auto pa-1">
                    <v-btn class="primary-btn" :disabled="!signatureTypesChanged && !defaultSignatureChanged" @click="saveOrgSignatureTypes()">{{ $t( "signedin.Save" ) }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="mt-11 mb-3"/>
          </div>
        </div>
      </div>
      <div class="text-left" v-if="isAdmin">
        <div class="text-left mx-4 mt-6">
          <v-icon class="mr-1">mdi-download</v-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <a @click="downloadJSON()" class="pink-alone-links">
                  {{ $t( "signedin.DownloadOrganizationDataLink" ) }}
                </a>
              </span>
            </template>
            <span>{{ $t( "signedin.DownloadOrganizationDataMessage" ) }}</span>
          </v-tooltip>
        </div>
        <v-divider class="mt-7 mb-3"/>
        <div class="mt-5 px-4" v-if="receivedRequests.length">
          <h3 class="sh-si-subSectionTitle font-weight-bold">{{ $t( "signedin.ReceivedMergeRequests" ) }}</h3>
          <v-simple-table class="merge-requests-table">
            <template v-slot:default>
              <thead>
                <tr class="text-left">
                  <th>{{ $t( "signedin.Organization" ) }}</th>
                  <th>{{ $t( "signedin.Users" ) }}</th>
                  <th>{{ $t( "signedin.Action" ) }}</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr class="merge-requests-table-tr" v-for="request in receivedRequests">
                  <td>{{ request.groupName }}</td>
                  <td>
                    <v-list dense class="overflow-y-auto" max-height="416">
                      <v-list-item v-for="user in request.users" :disabled="!user.active" :key="user.email">
                        {{ user.name }} ({{ user.email }})
                      </v-list-item>
                    </v-list>
                  </td>
                  <td style="min-width:272px" class="pa-0">
                    <v-btn @click="rejectMergeRequest( request.groupId )" min-width="120px !important" class="secondary-btn ma-2">
                      {{ $t( "signedin.Reject" ) }}
                    </v-btn>
                    <v-btn @click="mergeGroup( request.groupId, request.groupName )" min-width="120px !important" class="primary-btn ma-2">
                      {{ $t( "signedin.Merge" ) }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div class="mt-5 px-4" v-if="sentRequest">
          <h3 class="sh-si-subSectionTitle font-weight-bold">{{ $t( "signedin.SentMergeRequest" ) }}</h3>
          <p class="my-3">{{ $t( "signedin.Recipient" ) }}: {{ sentRequest.recipientEmail }}
            <v-btn @click="cancelOwnMergeRequest()" class="secondary-btn ma-4">{{ $t( "signflow.Cancel" ) }}</v-btn>
          </p>
        </div>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <div class="mt-5 px-4" v-if="!receivedRequests.length && !sentRequest">
              <p class="mb-3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="requestMerge()" class="alternative-primary-btn" v-bind="attrs" v-on="on">
                      {{ $t( "signedin.RequestMerge" ) }}
                    </v-btn>
                  </template>
                  <span>{{ $t( "signedin.MergeOrganizationMessage" ) }}</span>
                </v-tooltip>
              </p>
            </div>
          </v-col>
          <v-col class="hidden-sm-and-down" cols="4">
            <v-spacer/>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="col-delete">
            <div class="px-4 text-right">
              <p class="my-3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="deleteEverything()" :disabled="receivedRequests.length > 0 || sentRequest != null"
                           class="secondary-btn" v-bind="attrs" v-on="on">
                      {{ $t( "signedin.DeleteEverything" ) }}
                    </v-btn>
                  </template>
                  <span>{{ $t( "signedin.DeleteOrganizationMessage" ) }}</span>
                </v-tooltip>
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-responsive>
  </v-container>
</template>

<script>
    import util from "../../../util/util";
    import {globalEvents} from "@cloudlace/client";
    import UserCard from "./UserCard";
    import NewUserCard from "./NewUserCard";
    const paginationLimit = 100;
    export default {
        name : "start-flow",
        components : {
            "sh-new-user-card" : NewUserCard,
            "sh-user-card" : UserCard
        },
        data()
        {
            return {
                search : "",
                organization : window.$cc_user_info.organization,
                orgName : window.$cc_user_info.organization.display_name,
                isAdmin : util.getIsAdmin(),
                isSubscriptionAdmin : util.getIsSubscriptionAdmin(),
                filtered_users : [],
                users : [],
                sentRequest : null,
                receivedRequests : [],
                userEmail : "",
                activeUsersCount : 0,
                subscription : $cc_user_info.subscription,
                stripePaymentOn : $cc_user_info.stripePaymentOn,
                estimated_billing : "",
                resellerUsersCount : 0,
                max_reseller_users_warning : "",
                resellers : process.env.VUE_APP_RESELLERS ? JSON.parse( process.env.VUE_APP_RESELLERS ) : [],
                enabled_signature_types : [ "email-based-signature" ],
                activeSignatureTypeId : "",
                orgSignatureTypes : [],
                defaultSignatureChanged : false
            };
        },
        /**
         * Map this.items to state.notes through the Vuex mapState utility.
         */
        computed : {
            orgNameChanged()
            {
                if( this.validateFullName( this.orgName ) !== true )
                {
                    return false;
                }
                else
                {
                    return ( this.orgName !== window.$cc_user_info.organization.display_name );
                }
            },
            signatureTypesChanged()
            {
                return ( JSON.stringify( this.orgSignatureTypes ) !== JSON.stringify( window.$cc_user_info.organization.org_signature_types ) );
            }
        },
        methods : {
            /**
             * Email validator.
             */
            validateEmail : util.validateEmail,
            /**
             * Full name validator.
             */
            validateFullName : util.validateFullName,
            /**
             * Reset organisation name to original.
             */
            resetOrgName()
            {
                this.orgName = window.$cc_user_info.organization.display_name;
            },
            /**
             * Save new organisation name.
             */
            saveOrgName()
            {
                this.$store.shioCoreClient.updateContract( util.getOrganizationId(), this.orgName ).then( r =>
                {
                    window.$cc_user_info.organization.display_name = this.orgName;
                    this.orgName = "";
                    this.orgName = window.$cc_user_info.organization.display_name;
                    globalEvents.emit( "orgname-changed" );
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Save new organisation signature types.
             */
            saveOrgSignatureTypes()
            {
                this.$store.shioCoreClient.updateOrgSignatureTypes( util.getOrganizationId(), this.orgSignatureTypes ).then( r =>
                {
                    window.$cc_user_info.organization.org_signature_types = this.orgSignatureTypes.slice();
                    this.defaultSignatureChanged =  false;
                    this.$warn({ text: this.$t( "signedin.SignatureSaved" ) });
                    } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Reset org signature types.
             */
            resetOrgSignatureTypes()
            {
                this.orgSignatureTypes = window.$cc_user_info.organization.org_signature_types.slice();
                this.activeSignatureTypeId = this.orgSignatureTypes[ 0 ];
                this.defaultSignatureChanged =  false;
            },
            /**
             * Move timeout to update search.
             */
            onSearchChange()
            {
                if( this._timeout )
                {
                    clearTimeout( this._timeout );
                }
                this._timeout = setTimeout( this._updateSearch, 500 );
            },
            /**
             * Inform about success and refresh user list.
             */
            onDeleteUser()
            {
                this.$inform( this.$t( "signedin.UserDeleted" ) );
            },
            /**
             * Inform about success and refresh user list
             */
            onRestoreUser()
            {
                this.$inform( this.$t( "signedin.UserRestored" ) );
            },
            /**
             * Passcode confirm deletion, then make request to delete everything.
             */
            deleteEverything()
            {
                const groupTitle = this.orgName;
                const text = eval( '`' + this.$t( "signedin.ConfirmDeleteEverything" ) + '`' );
                this.$store.signFlowClient.retrieveSignFlowCount( util.getOrganizationId(), "group", { completed : false, canceled : false } ).then( resp =>
                {
                    if( resp.count && this.stripePaymentOn
                             && !this.subscription.has_trial_period && !this.subscription.has_valid_subscription
                             && !this.subscription.has_payment_past_due && !this.subscription.incomplete_subscription )
                    {
                        const processNum = resp.count;
                        const warnText = eval( '`' + this.$t( "signedin.ConfirmCancelOngoingProcesses" ) + '`' );
                        this.$confirm( {
                            text : warnText,
                            cancelText : this.$t( "signflow.Cancel" ),
                            acceptText : this.$t( "signflow.Confirm" ),
                        } ).then( r =>
                        {
                            this.$store.signFlowClient.abortActiveSignFlows( util.getOrganizationId() ).then( () =>
                            {
                                this.$emit( "change", "canceled" );
                                this.$prompt( {
                                    title : this.$t( "signedin.DeleteEverything" ),
                                    text : text + "\n\n" + this.$t( "signedin.EnterPassword" ),
                                    cancelText : this.$t( "signflow.Cancel" ),
                                    acceptText : this.$t( "signflow.Confirm" ),
                                    type : "password"
                                } ).then( password =>
                                {
                                    this.doDeleteEverything( password );
                                } ).catch( e => {} );
                            } ).catch( e =>
                            {
                                this.$error( this.$t( "signflow.Error!Unexpected" ) )
                            } );
                        } ).catch( e => {} );
                    }
                    else if( resp.count )
                    {
                        const errText = this.$t( "signedin.CannotDeleteActiveFlows" );
                        const ok = this.$t( "signflow.Ok" );
                        this.$alert( { text : errText, acceptText : ok } ).then( () => {} );
                    }
                    else
                    {
                        this.$prompt( {
                            title : this.$t( "signedin.DeleteEverything" ),
                            text : text + "\n\n" + this.$t( "signedin.EnterPassword" ),
                            cancelText : this.$t( "signflow.Cancel" ),
                            acceptText : this.$t( "signflow.Confirm" ),
                            type : "password"
                        } ).then( password =>
                        {
                            this.doDeleteEverything( password );
                        } ).catch( e => {} );
                    }
                } ).catch( () => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Delete group with password, then alert about success and sign out, or handle error.
             *
             * @param password
             */
            doDeleteEverything( password )
            {
                this.$store.shioCoreClient.deleteGroupByUser( util.getOrganizationId(), password ).then( r =>
                {
                } ).catch( e =>
                {
                    switch( e.code_key )
                    {
                        case 401 :
                            this.$warn( this.$t( "signedin.InvalidPassword" ) );
                            break;
                        case 409 :
                            this.$warn( this.$t( "signedin.OrganizationHasActiveProcesses" ) );
                            break;
                        default :
                            this.$error( this.$t( "signedin.Error!Unexpected" ) );
                    }
                } );
            },
            /**
             * Ask admin email and password confirm, then create organisation merge request.
             */
            requestMerge()
            {
                util.retrieveMeErrorTimes( this.$store.shioCoreClient ).then( r =>
                {
                    if( r >= 5 )
                    {
                        this.$error( this.$t( "message.Error!too_many_incorrect_email" ) );
                    }
                    else
                    {
                        this.$prompt( {
                            title : this.$t( "signedin.RequestMerge" ),
                            text : this.$t( "signedin.RequestMergeDetails" ) + "\n\n" + this.$t( "signedin.EnterAdminEmail" ),
                            cancelText : this.$t( "signflow.Cancel" ),
                            acceptText : this.$t( "signflow.Continue" )
                        } ).then( email =>
                        {
                            email = email.trim();
                            if( email && this.validateEmail( email ) === true )
                            {
                                this.$prompt( {
                                    title : this.$t( "signedin.RequestMerge" ),
                                    text : this.$t( "signedin.ConfirmMergeOrganisation" ) + "\n\n" + this.$t( "signedin.EnterPassword" ),
                                    cancelText : this.$t( "signflow.Cancel" ),
                                    acceptText : this.$t( "signflow.Confirm" ),
                                    type : "password"
                                } ).then( password =>
                                {
                                    this.doRequestMerge( email, password );
                                } ).catch( e => {} );
                            }
                            else
                            {
                                this.$warn( this.$t( 'message.MustBeEmail' ) );
                            }
                        } ).catch( e => {} );
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( "signedin.Error!Unexpected" ) );
                }  );
            },
            /**
             * Create organisation merge request.
             */
            doRequestMerge( email, password )
            {
                this.$store.shioCoreClient.createMergeRequest( util.getOrganizationId(), email, password ).then( r =>
                {
                    this.$inform( this.$t( "signedin.MergeRequestSent" ) );
                    this.retrieveMergeRequests();
                } ).catch( e =>
                {
                    switch( e.code_key )
                    {
                        case 401 :
                            if( e.user_msg === "Invalid password." )
                            {
                                this.$warn( this.$t( "signedin.InvalidPassword" ) );
                            }
                            else
                            {
                                this.$warn( this.$t( "signedin.UserNotDifferentOrganizationAdmin" ) );
                            }
                            break;
                        case 404 :
                            this.$warn( this.$t( "signedin.UserNotFound" ) );
                            break;
                        case 409 :
                            this.$warn( this.$t( "signedin.OrganizationHasActiveMergeRequest" ) );
                            break;
                        default :
                            this.$error( this.$t( "signedin.Error!Unexpected" ) );
                    }
                    this.retrieveMergeRequests();
                } );
            },
            /**
             * Retrieve organisation merge requests created by the group or assigned to the user.
             */
            retrieveMergeRequests()
            {
                this.$store.shioCoreClient.retrieveMergeRequests( util.getOrganizationId() ).then( requests =>
                {
                    this.sentRequest = requests.sent;
                    this.receivedRequests = requests.received;
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Cancel own merge request, then update merge requests.
             */
            cancelOwnMergeRequest()
            {
                this.$store.shioCoreClient.deleteOwnMergeRequest( util.getOrganizationId(), this.sentRequest.recipientEmail ).then( r =>
                {
                    this.$inform( this.$t( "signedin.MergeRequestCanceled" ) );
                    this.retrieveMergeRequests();
                } ).catch( e =>
                {
                    if( e.code_key === 409 )
                    {
                        this.$warn( this.$t( "signedin.InvalidMergeRequest" ) );
                    }
                    else
                    {
                        this.$error( this.$t( "signedin.Error!Unexpected" ) );
                    }
                    this.retrieveMergeRequests();
                } );
            },
            /**
             * Reject organisation merge request, then update merge requests.
             */
            rejectMergeRequest( groupId )
            {
                this.$store.shioCoreClient.deleteMergeRequest( util.getOrganizationId(), groupId ).then( r =>
                {
                    this.$inform( this.$t( "signedin.MergeRequestRejected" ) );
                    this.retrieveMergeRequests();
                } ).catch( e =>
                {
                    if( e.code_key === 409 )
                    {
                        this.$warn( this.$t( "signedin.InvalidMergeRequest" ) );
                    }
                    else
                    {
                        this.$error( this.$t( "signedin.Error!Unexpected" ) );
                    }
                    this.retrieveMergeRequests();
                } );
            },
            /**
             * Password confirm merging, then make request to merge organisation.
             */
            mergeGroup( groupId, groupName )
            {
                this.$prompt( {
                    title : this.$t( "signedin.Merge" ),
                    text : eval( '`' + this.$t( "signedin.MergeOrganizationDetails" ) + '`' ) + "\n\n" +
                        this.$t( "signedin.ConfirmMergeOrganisation" ) + "\n\n" + this.$t( "signedin.EnterPassword" ),
                    cancelText : this.$t( "signflow.Cancel" ),
                    acceptText : this.$t( "signflow.Confirm" ),
                    type : "password"
                } ).then( password =>
                {
                    this.$store.groupMergeClient.createGroupMergeTask( util.getOrganizationId(), groupId, password ).then( r =>
                    {
                        this.$inform( this.$t( "signedin.OrganisationMerged" ) );
                        this.retrieveUsers();
                        this.retrieveNextBilling();
                        this.retrieveMergeRequests();
                    } ).catch( e =>
                    {
                        if( e.code_key === 401 )
                        {
                            this.$warn( this.$t( "signedin.InvalidPassword" ) );
                        }
                        else if( e.code_key === 409 )
                        {
                            this.$warn( this.$t( "signedin.InvalidMergeRequest" ) );
                        }
                        else
                        {
                            this.$error( this.$t( "signedin.Error!Unexpected" ) );
                        }
                        this.retrieveMergeRequests();
                    } );
                } ).catch( e => {} );
            },
            /**
             * Download all of their organisation’s user accounts and process data in a JSON file
             */
            downloadJSON()
            {
                this.from = 0;
                this.sign_flows = [];
                const proms = [
                    this.$store.shioCoreClient.retrieveUsers( util.getOrganizationId() ),
                    this.retrieveSignFlows()
                ];
                Promise.all( proms ).then( r =>
                {
                    const blob = new Blob( [ JSON.stringify( r ) ], { type: 'application/json' } );
                    const link = document.createElement( 'a' );
                    link.href = URL.createObjectURL( blob );
                    link.download = "data";
                    link.click();
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Retrieve sign flows in batches.
             *
             * @returns {Promise<unknown>}
             */
            retrieveSignFlows()
            {
                return new Promise( ( resolve, reject ) =>
                {
                    const currentFrom = this.from;
                    const props = {
                        limit : paginationLimit,
                        from : this.from,
                    };
                    this.$store.signFlowClient.retrieveSignFlows( util.getOrganizationId(), "group", props ).then( r =>
                    {
                        if ( r.signFlows.length )
                        {
                            this.sign_flows.push( ...r.signFlows );
                            if ( r.signFlows.length < paginationLimit )
                            {
                                resolve( { "signFlows" : this.sign_flows } );
                            }
                            else
                            {
                                this.from = currentFrom + paginationLimit;
                                this.retrieveSignFlows().then( r => resolve( r ) ).catch( e => reject( e ) );
                            }
                        }
                        else
                        {
                            resolve( { "signFlows" : [] } );
                        }
                    } ).catch( e => this.$error( this.$t( "signflow.Error!Unexpected" ) ) );
                } );
            },
            /**
             * Retrieve users and admins and flag admin users as such.
             */
            retrieveUsers()
            {
                const proms = [
                    this.$store.shioCoreClient.retrieveUsers( util.getOrganizationId() ),
                    this.$store.shioCoreClient.retrieveAdmins( util.getOrganizationId() )
                ];
                if( this.stripePaymentOn )
                {
                    proms.push(  this.$store.shioCoreClient.retrieveSubscriptionAdmins( util.getOrganizationId() ) );
                }
                Promise.all( proms ).then( r =>
                {
                    this.users = r[ 0 ].entities.map( user =>
                    {
                        user.isAdmin = this._isAdmin( user, r[ 1 ].entities );
                        if( this.stripePaymentOn )
                        {
                            user.isSubscriptionAdmin = this._isAdmin( user, r[ 2 ].entities );
                        }
                        return user;
                    } );
                    this.users = this.sortUsers( this.users );
                    this.activeUsersCount = this.users.filter( ( obj ) => obj.active === true && obj.disabled !== true ).length;
                    this._updateSearch();
                    const resellerMaxUsers = this.organization.reseller_max_users;
                    const resellerUsersCount = this.resellerUsersCount = this.users.filter( ( obj ) => obj.disabled !== true ).length;
                    this.max_reseller_users_warning = eval( '`' + this.$t( "signedin.MaxResellerUsersWarning" ) + '`' );
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            /**
             * Retrieves upcoming invoice.
             */
            retrieveNextBilling()
            {
                if( this.isSubscriptionAdmin && this.stripePaymentOn && ( this.subscription.has_trial_period || this.subscription.has_valid_subscription
                                                  || this.subscription.has_payment_past_due || this.subscription.incomplete_subscription ) )
                {
                    this.$store.shioCoreClient.retrieveUpcomingInvoice( util.getOrganizationId() ).then( r =>
                    {
                        const nextBillingDate = util.getDateFromTimestamp( r.next_payment_attempt );
                        const nextBillingValue = r.amount_due / 100;
                        this.estimated_billing = eval( '`' + this.$t( "signedin.EstimatedBilling" ) + '`' );
                    } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
                }
            },
            /**
             * Filter users.
             */
            _updateSearch()
            {
                if( !this.search )
                {
                    this.filtered_users = this.users;
                }
                else
                {
                    this.filtered_users = [];
                    const users = [];
                    for( let i = 0; i < this.users.length; i++ )
                    {
                        if( this.users[ i ].email.toUpperCase().indexOf( this.search.toUpperCase() ) !== -1 || this.users[ i ].name.toUpperCase().indexOf( this.search.toUpperCase() ) !== -1 )
                        {
                            this.filtered_users.push( this.users[ i ] );
                        }
                    }
                }
            },
            /**
             *  Split users into enabled and disabled, then sort them
             *  @param users {Object[]}
             *  @returns {Object[]}
             */
            sortUsers( users )
            {
                const [ enabled, disabled ] = util.partition( users, user => !user.disabled );
                return [ ...this.arrangeUsers( enabled ), ...this.arrangeUsers( disabled ) ];
            },
            /**
             * Arrange users according to the rules
             *  @param users {Object[]}
             *  @returns {Object[]}
             */
            arrangeUsers( users )
            {
                const [ me, notMe ] = util.partition( users, user => user.email === this.userEmail );
                const [ active, nonActive ] = util.partition( notMe, user => user.active );
                const [ activeAdmins, activeNonAdmins ] = util.partition( active, user => user.isAdmin );
                const [ nonActiveAdmins, nonActiveNonAdmins ] = util.partition( nonActive, user => user.isAdmin );
                this.sortByName( [ activeAdmins, activeNonAdmins, nonActiveAdmins, nonActiveNonAdmins ] );
                return  [ ...me, ...activeAdmins, ...activeNonAdmins, ...nonActiveAdmins, ...nonActiveNonAdmins ];
            },
            /**
             * In place sort of arrays of users by name
             * @param userGroups {Object[][]}
             *
             */
            sortByName ( userGroups )
            {
                userGroups.forEach( userGroup =>
                {
                    userGroup.sort( ( a, b)  => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0 );
                } );
            },
            /**
             * React to relevant server messages: refresh list or contract name.
             *
             * @param evt {Event}
             */
            onShioCoreMessage( evt )
            {
                const msg = evt.detail;
                if( msg.data_type === "user_admin" && msg.response_map.email === $cc_user_info.email )
                {
                    window.location.reload();
                }
                else if( msg.data_type === "user" || msg.data_type === "user_admin" )
                {
                    this.retrieveUsers();
                    this.retrieveNextBilling();
                }
                else if( msg.data_type === "contract" )
                {
                    window.$cc_user_info.organization.display_name = msg.response_map.display_name;
                    this.orgName = msg.response_map.display_name;
                    globalEvents.emit( "orgname-changed" );
                }
                else if( msg.data_type === "org_signature_types" )
                {
                    window.$cc_user_info.organization.org_signature_types = msg.response_map.org_signature_types;
                    this.orgSignatureTypes = window.$cc_user_info.organization.org_signature_types;
                }
            },
            setActiveSignatureType( signatureType )
            {
                this.activeSignatureTypeId = signatureType;
                const index = this.orgSignatureTypes.indexOf( this.activeSignatureTypeId );
                this.orgSignatureTypes.splice( index, 1 );
                this.orgSignatureTypes.unshift( this.activeSignatureTypeId );
                this.defaultSignatureChanged =  true;
            },
            /**
             * Check if user is an admin, return true or false.
             */
            _isAdmin( user, admins )
            {
                for( let i = 0; i < admins.length; i++ )
                {
                    if( admins[ i ].email === user.email )
                    {
                        return true;
                    }
                }
                return false;
            }
        },
        /**
         * Dispatch "retrieveUsers."
         */
        mounted()
        {
            if( window.$cc_user_info.organization.enabled_signature_types )
            {
                this.enabled_signature_types = [ ...[ "email-based-signature" ], ...$cc_user_info.organization.enabled_signature_types ];
            }
            if( window.$cc_user_info.organization.org_signature_types )
            {
                this.orgSignatureTypes = window.$cc_user_info.organization.org_signature_types.slice();
            }
            else
            {
                this.orgSignatureTypes = [ "email-based-signature" ];
            }
            this.activeSignatureTypeId = this.orgSignatureTypes[ 0 ];
            this.retrieveUsers();
            this.retrieveNextBilling();
            if( this.isAdmin )
            {
                this.retrieveMergeRequests();
            }
            globalEvents.on( "shio-core-notification", this.onShioCoreMessage );
            this.userEmail = $cc_user_info.email;
        },
        /**
         * Remove global event listener.
         */
        beforeDestroy()
        {
            globalEvents.remove( "shio-core-notification", this.onShioCoreMessage );
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .sh-si-subSectionTitle
    font-size: 16px

  tbody tr:hover
    background-color: transparent !important

  .merge-requests-table th, td
    font-size: 16px !important
    color: $text-black
    font-weight: normal

  .v-data-table.merge-requests-table
    border: 0

  .new-user-card
    margin-bottom: 5px

  .user-cards
    margin-top: 5px
    margin-bottom: 5px

  @media (max-width: 599px)
    button
      width: 100%

    .col-delete
      padding-top: 0
</style>
