<template>
  <div id="app">
    <v-fade-transition>
      <sh-homepage v-if="status === 'signed-out'" ref="homepage"></sh-homepage>
    </v-fade-transition>
    <v-fade-transition>
      <sh-signhero v-if="status === 'signed-in'"></sh-signhero>
    </v-fade-transition>
  </div>
</template>

<script>
    import SignHero from "../../components/signedin/SignHero";
    import { JSONPureClient } from "@cloudlace/client";
    import { globalEvents } from "@cloudlace/client";
    import SignHeroHomepage from "../../components/signedout/SignHeroHomepage";
    import signedInServiceMap from "../../api/shio-core-service-map";
    import signedInRoutesRaw from "./signed-in-routes";
    import signedOutRoutes from "./signed-out-routes";
    const apiDocsRoutes = signedOutRoutes.filter( route => route.path === "/docs/api" );
    const signedInRoutes = [ ...apiDocsRoutes, ...signedInRoutesRaw ];
    import util from "../../util/util";

    const serviceClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_SERVICE_SIGNEDIN_URL,
        service_map : signedInServiceMap
    } );
    /**
     * Application bootstrap. Handles state change between signed-in and signed-out, and initialises routes ::onSignIn.
     * Modify ::onSignIn to add routes to your UI modules to it.
     */
    export default {
        name : 'App',
        data()
        {
            return {
                starting : true,
                status : "starting"
            }
        },
        components : {
            "sh-homepage" : SignHeroHomepage,
            "sh-signhero" : SignHero
        },
        methods : {
            /**
             * Retrieve handshake, then call ::signedIn().
             */
            onSignIn()
            {
                this.$store.userSignedInClient.updateProfile( { locale: util.getLocale() } ).then( () => {
                    serviceClient.retrieveHandshake().then( r =>
                    {
                        this.signedIn();
                    } ).catch( e => {} );
                } ).catch( () => {} );
            },
            /**
             * Set app status to "signed-in" and reset router with signed-in routes.
             */
            signedIn()
            {
                this.$store.shioCoreClient.retrieveContract( util.getOrganizationId() ).then( r =>
                {
                    window.$cc_user_info.organization = {
                        uuid : util.getOrganizationId(),
                        display_name : r.entity.display_name,
                        reseller_enabled : r.entity.reseller_enabled,
                        reseller_max_users : r.entity.reseller_max_users,
                        reseller : r.entity.reseller,
                        wallet_enabled : r.entity.wallet_enabled,
                        advanced_signature_enabled : r.entity.advanced_signature_enabled,
                        enabled_signature_types : r.entity.enabled_signature_types,
                        org_signature_types : r.entity.org_signature_types
                    };
                    window.$cc_user_info.stripePaymentOn =( process.env.VUE_APP_STRIPE_PAYMENT === 'true' )
                                                          && ( r.entity.payment_disabled !== true );
                    if( window.$cc_user_info.stripePaymentOn )
                    {
                        Promise.all( [
                        util.retrieveSubscription( this.$store.shioCoreClient, r.entity.display_name ),
                        util.retrieveWallet( this.$store.shioCoreClient ) ] ).then( r =>
                        {
                            this.status = 'signed-in';
                            this.$router.resetRoutes( signedInRoutes );
                            this.redirectedFrom = window.location.href;
                            //redirect to oauth2 app if client_id exists
                            if( this.redirectedFrom.indexOf( "client_id" ) > -1 && this.redirectedFrom.indexOf( "oauth2" ) === -1 )
                            {
                                window.location.replace( this.redirectedFrom.replace("#/start?", "oauth2/authorize?" ) );
                            }
                            if( this._route && this.$router.currentRoute.path !== this._route )
                            {
                                this.$router.replace( this._route );
                            }
                        } ).catch( e =>
                        {
                            this.$error( this.$t( 'message.Error!Unexpected' ) );
                        } );
                    }
                    else
                    {
                        this.status = 'signed-in';
                        this.$router.resetRoutes( signedInRoutes );
                        this.redirectedFrom = window.location.href;
                        if( this.redirectedFrom.indexOf( "client_id" ) > -1 && this.redirectedFrom.indexOf( "oauth2" ) === -1 )
                        {
                            window.location.replace( this.redirectedFrom.replace("#/start?", "oauth2/authorize?" ) );
                        }
                        if( this._route && this.$router.currentRoute.path !== this._route )
                        {
                            this.$router.replace( this._route );
                        }
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            /**
             * Set status to signed-out and display a message if the user was previously signed-in.
             */
            onSignOut()
            {
                this.status = 'signed-out';
                this.$router.resetRoutes( signedOutRoutes );
                if( !this._keepRoute )
                {
                    this._route = "";
                }
                this._keepRoute = false;
            }
        },
        mounted()
        {
            document.documentElement.setAttribute( 'lang', util.getLocale() );
            this._route = this.$router.currentRoute.redirectedFrom;
            if( this._route === "/place-signatures" )
            {
                this._route = "/start";
            }
            globalEvents.on( "signed-in", ( evt, pl ) => this.onSignIn( evt ) );
            globalEvents.on( "signed-out", ( evt, pl ) => this.onSignOut( evt ) );
            this._keepRoute = true;
            serviceClient.retrieveHandshake().then( result =>
            {
                this._keepRoute = false;
                globalEvents.emit( "signed-in" );
            } ).catch( err =>
            {
                this.status = 'signed-out';
                console.info( "[app] Handshake failed, user probably signed out.")
            } );
        }
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  html
    overflow-y: auto

  h1, h2, h3
    text-align: left

  .vdp-message
    white-space: pre-wrap
    text-transform: none
    font-weight: normal
    letter-spacing: 0
</style>
