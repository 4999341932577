import StartFlow from "../../components/signedin/signflow/start/StartFlow";
import PlaceSignatures from "../../components/signedin/signflow/start/PlaceSignatures";
import OngoingFlows from "../../components/signedin/signflow/ongoing/OngoingFlows";
import CompletedFlows from "../../components/signedin/signflow/archived/CompletedFlows";
import SigningPane from "../../components/signedin/sign/SigningPane";
import ProfilePane from "../../components/signedin/profile/ProfilePane";
import SessionsPane from "../../components/signedin/sessions/SessionsPane";
import IntegrationsPane from "../../components/signedin/integrations/IntegrationsPane";
import OrganizationPane from "../../components/signedin/organization/OrganizationPane";
import SubscriptionPane from "../../components/signedin/subscription/SubscriptionPane";
import WalletPane from "../../components/signedin/wallet/WalletPane";
import StatisticsPane from "../../components/signedin/statistics/StatisticsPane";
import LandingPane from "../../components/common/LandingPane";
import util from "../../util/util";

export default [
    {
        path : '/',
        name : "base",
        component: LandingPane
    },
    {
        path : '/start',
        name : 'start',
        component : StartFlow
    },
    {
        path : '/place-signatures',
        name : 'place-signatures',
        component : PlaceSignatures
    },
    {
        path : '/ongoing',
        name : 'ongoing',
        component : OngoingFlows
    },
    {
        path : '/complete',
        name : 'complete',
        component : CompletedFlows
    },
    {
        path : '/sign',
        name : 'sign',
        component : SigningPane
    },
    {
        path : '/profile',
        name : 'profile',
        component : ProfilePane
    },
    {
        path : '/sessions',
        name : 'sessions',
        component : SessionsPane
    },
    {
        path : '/organization',
        name : 'organization',
        component : OrganizationPane
    },
    {
        path : '/subscription',
        name : 'subscription',
        component : SubscriptionPane
    },
    {
        path : '/wallet',
        name : 'wallet',
        component : WalletPane
    },
    {
        path : '/statistics',
        name : 'statistics',
        component : StatisticsPane
    },
    {
        path : '/integrations',
        name : 'integrations',
        component : IntegrationsPane,
        beforeEnter( to, from, next )
        {
            if ( util.getIsAdmin() )
            {
                next();
            }
            else
            {
                next( false );
            }
        }
    },
    {
        path : "/*",
        redirect : "/start"
    }
];
