<template>
  <v-container fluid class="fill-height pa-0">
    <v-app>
      <v-app-bar class="api-docs-header" app>
        <v-toolbar-title class="pl-3">
          <a href="/">
            <img src="/signherologoW.svg" class="hidden-xs-only signhero-logo" height="50"/>
            <img src="/SHicon.svg" class="hidden-sm-and-up signhero-icon" height="50"/>
          </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="d-flex color-switch pr-3">
          <span class="hidden-sm-and-down">Dark</span>
          <v-switch v-model="colorSwitch" color="primary" class="mx-2"></v-switch>
          <span class="hidden-sm-and-down">Light</span>
        </div>
        <v-divider vertical class="header-divider d-flex d-sm-flex d-md-none"></v-divider>
        <v-app-bar-nav-icon class="white--text d-flex d-sm-flex d-md-none pb-2" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>
      <v-navigation-drawer class="text-left side-bar" color="secondary" v-model="drawer" height="100%" disable-resize-watcher mobile-breakpoint="960px">
        <v-list dense class="api-docs-menu">
          <v-list-item-group active-class="api-docs-menu-active">
            <v-list-item-content>
              <v-list-item to="/docs/api/roadmap">
                <v-list-item-title class="side-bar-title py-6">Roadmap</v-list-item-title>
              </v-list-item>
              <v-divider class="left-nav-divider"></v-divider>
              <v-list-item to="/docs/api/overview">
                <v-list-item-title class="side-bar-title py-6">Overview</v-list-item-title>
              </v-list-item>
              <v-divider class="left-nav-divider"></v-divider>
              <v-list-item to="/docs/api/authentication">
                <v-list-item-title class="side-bar-title py-6">Authentication</v-list-item-title>
              </v-list-item>
              <v-divider class="left-nav-divider"></v-divider>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item to="/docs/api/client-credentials">
                    <v-list-item-content>
                      <v-list-item-title class="side-bar-title py-4">Client credentials
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <v-list-item to="/docs/api/request-an-access-token">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Request an access token
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/client-credentials-revoke-tokens">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Revoke tokens</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-divider class="left-nav-divider"></v-divider>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item to="/docs/api/authorisation-code">
                    <v-list-item-title class="side-bar-title py-6">Authorisation code
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item to="/docs/api/create-an-application">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Create an application
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/request-an-authorisation-code">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Request an authorisation code
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/exchange-the-authorisation-code-to-an-access-token">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Exchange the authorisation code to
                      an access token
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/refresh-an-access-token">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Refresh an access token
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/authorisation-code-revoke-tokens">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Revoke tokens</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/error-responses">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Error responses</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-divider class="left-nav-divider"></v-divider>
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item class="pl-0">
                    <v-list-item-title class="side-bar-title grey--text py-6">SignHero API
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list-item to="/docs/api/upload-a-document">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Upload a document
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/search-unattached-documents">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Search unattached documents
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/delete-an-unattached-document">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Delete an unattached document
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/create-a-signing-process">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Create a signing process
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/create-a-signing-url">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Create a signing URL
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/create-sign-reminders">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Create sign reminders
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/retrieve-a-signing-process">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Retrieve a signing process
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/search-signing-process">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Search signing process
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/cancel-a-signing-process">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Cancel a signing process
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/delete-a-signing-process">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Delete a signing process
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/download-a-document">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Download a document
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/retrieve-profile-info">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Retrieve profile info
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/retrieve-wallet-balance">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Retrieve wallet balance
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/preflight-an-advanced-signing-process">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Preflight an advanced signing process
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item to="/docs/api/signhero-api-error-responses">
                  <v-list-item-content>
                    <v-list-item-title class="side-bar-sub-title py-2">Signhero API error responses
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-divider class="left-nav-divider"></v-divider>
            </v-list-item-content>
          </v-list-item-group>
        </v-list>
        <div>
          <div class="footerLinks">
            <a target="_blank" href="/SignHero_Postman_Collection.zip"
               style="display: block;" class="pb-2">Postman Collection</a>
            <a href="/terms/api" target="_blank" class="api-terms-link">API terms</a>
          </div>
          <v-divider class="api-footer-divider"></v-divider>
          <div class="copy py-4">
            {{ $t( 'message.CopyrightYear' ) }}<a href="https://www.avaintec.com" target="_blank" rel="noopener">{{ $t( 'message.CompanyOyName' ) }}</a>
          </div>
        </div>
      </v-navigation-drawer>
    </v-app>
    <v-main class="pt-0" app>
      <v-layout align-center justify-center>
        <v-container class="pa-0 api-docs-content">
          <v-flex>
            <div :class="apiColorTheme">
              <router-view></router-view>
            </div>
          </v-flex>
        </v-container>
      </v-layout>
    </v-main>
  </v-container>
</template>

<script>
    export default {
        name : "ApiDocument",
        data()
        {
            return {
                drawer : null,
                colorSwitch : false
            }
        },
        computed : {
            apiColorTheme()
            {
                return this.colorSwitch ? "api-light" : "api-dark";
            }
        }
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  /* Header */

  .color-switch span
    color: white

  .side-bar-title, .side-bar-sub-title
    color: white

  .side-bar-title
    font-size: 16px !important

  .side-bar-sub-title
    font-size: 14px !important

  .side-bar
    padding: 0
    position: fixed
    margin-top: 64px
    bottom: 0
    height: calc(100vh - 64px)
    z-index: 999
    background: $background-black
    color: white
    transition: all .3s
    align-items: normal
    overflow-y: auto
    overflow-x: hidden

  aside.side-bar.v-navigation-drawer.v-navigation-drawer--open
    width: 250px !important

  .api-docs-menu
    min-height: calc(100% - 180px)

  .api-docs-menu i.v-icon.mdi.mdi-chevron-down
    color: white

  .footerLinks, .copy
    font-size: 14px
    position: relative
    width: auto
    color: $dark-grey
    margin: 0 15px
    a
      color: $light-grey !important
      text-decoration: none
      &:hover
        color: $dark-grey !important

  .api-terms-link
    display: block
    padding-bottom: 15px

  .v-messages
    min-height: auto

  .header-divider
    border-color: $dark-grey !important
    padding-top: 20px
    padding-bottom: 20px

  .api-footer-divider
    border-color: $dark-grey !important

  hr.left-nav-divider
    border-color: white !important

  .api-docs-menu .v-list-item__title.side-bar-title:hover, .api-docs-menu .v-list-item__title.side-bar-sub-title:hover
    color: $vivid-pink

  a.api-docs-menu-active.v-list-item--active .v-list-item__title
    color: $vivid-pink

  a.api-docs-menu-active.v-list-item--active, a.api-docs-menu-active.v-list-item--active.v-list-item.v-list-item--link
    color: transparent

  a
    outline: 0

  /* Fonts */

  h1, h2, h3, h4, h5, h6
    font-weight: 400

  /* Dark and light color theme */

  .api-light
    h1, h2, h5
      color: $text-black

    .sectionContainer
      background: white !important

    h3, i, p, li
      color: $dark-grey

    .sectionContainer a:hover
      color: $dark-grey

    .line-break
      border-top: 1px solid $dark-grey !important

    .version-switch span
      color: $dark-grey

    a:hover.api-negative-link.v-btn.v-btn--flat.v-btn--router.v-btn--text.theme--light.v-size--default span.v-btn__content
      color: $dark-grey

    a.api-negative-link.v-btn.v-btn--flat.v-btn--router.v-btn--text.theme--light.v-size--default span.v-btn__content
      border-bottom: 1px solid $dark-grey
</style>
