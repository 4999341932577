<template>
  <v-app>
    <v-navigation-drawer class="left-drawer" mobile-breakpoint fixed left hide-overlay temporary v-model="showNav"
                         v-if="( $route.path !== '/' ) && ( !$route.path.includes( '/docs/api' ) )">
      <v-list>
        <v-list-item
            active-class="left-drawer-active-items"
            v-for="item in items"
            :key="item.path"
            :to="item.path">
          {{ item.label }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar dark class="signedin-header" app height="64px"
               v-if="( $route.path !== '/' ) && ( !$route.path.includes( '/docs/api' ) )">
      <v-app-bar-nav-icon @click="toggleNav" v-if="!showNav" class="hidden-sm-and-up ml-1 mr-3">
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon @click="toggleNav" v-if="showNav" class="hidden-sm-and-up ml-1">
        <v-icon>
          mdi-close
        </v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="px-3" @click="closeLanguageSetting">
        <router-link to="/start">
          <v-img src="/SHicon.svg" class="mr-16 hidden-xs-only" height="50px" width="25px" :contain="true"></v-img>
        </router-link>
      </v-toolbar-title>
      <v-btn class="ma-2 hidden-xs-only shio-process-button"
             v-for="item in items"
             active-class="status-btn"
             :key="item.path"
             :to="item.path"
             @click="closeLanguageSetting">
        {{ item.label }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text class="shio-language-button shio-user-button" :ripple="false" @click="showLanguageSetting">
            <v-icon>
              mdi-earth
            </v-icon>
            <span class="hidden-sm-and-down">&nbsp;{{ $t( 'message.ThisLocale' ) }}</span>
          </v-btn>
        </template>
      </v-menu>
      <v-divider class="menu-divider" vertical></v-divider>
      <v-btn text @click.stop="rightDrawer = !rightDrawer" class="shio-user-button"  :ripple="false">
        <v-icon class="mx-2 mx-sm-0 mx-md-0 mx-lg-0 mx-xl-0">
          mdi-account-circle
        </v-icon>
        <div class="hidden-xs-only px-1">
          &nbsp;
          {{ userName }}
          <br/>
          {{ companyName }}
        </div>
        <v-icon>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer class="right-drawer" v-model="rightDrawer" fixed right hide-overlay temporary>
      <v-list class="pa-0 signedin-right-list" dark>
        <v-list-item class="white">
          <v-list-item-action>
            <v-icon class="category-title">mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content class="category-title">
            {{ $t( "signedin.MyAccount" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/profile" class="my-2" :ripple="false" @click="closeLanguageSetting">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.Profile" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/sessions" class="my-2" :ripple="false" @click="closeLanguageSetting">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.Sessions" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="signOut()" class="my-2" :ripple="false">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.Logout" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="white">
          <v-list-item-action>
            <v-icon class="category-title">mdi-cogs</v-icon>
          </v-list-item-action>
          <v-list-item-content class="category-title">
            {{ $t( "signedin.TheService" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/organization" class="my-2" :ripple="false" @click="closeLanguageSetting">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.MyOrganization" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/subscription" class="my-2" :ripple="false" v-if="isSubscriptionAdmin && stripePaymentOn" @click="closeLanguageSetting">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.MySubscription" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/wallet" class="my-2" :ripple="false" v-if="isSubscriptionAdmin && stripePaymentOn && walletEnabled" @click="closeLanguageSetting">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.MyWallet" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/integrations" class="my-2" :ripple="false" v-if="isAdmin" @click="closeLanguageSetting">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.MyIntegrations" ) }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/statistics" class="my-2" :ripple="false" v-if="isAdmin" @click="closeLanguageSetting">
          <v-list-item-action>
            <v-icon></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{ $t( "signedin.Statistics" ) }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main app v-if="( $route.path !== '/' ) && ( !$route.path.includes( '/docs/api' ) )">
      <v-layout align-center justify-center>
        <v-container v-bind:class="[ $route.path.includes( '/docs/api' ) ? 'container-api-docs' : '' ]">
          <v-flex>
            <router-view v-if="!choose_language_setting"></router-view>
            <v-row v-if="choose_language_setting">
              <v-col>
                <div class="ma-auto mt-1 maxWidth-1100 pa-6">
                  <h1 class="shio-section-title">{{ $t( 'message.Locales' ) }}</h1>
                  <v-radio-group v-model="locale" class="mx-3 mt-6">
                    <v-radio v-for="locale in locales" :key="locale" :label="$t( 'message.locale!' + locale )" :value="locale"></v-radio>
                  </v-radio-group>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <div class="text-left mt-5 mx-3">
                        <v-btn class="primary-btn choose-language-btn" @click="setLocale( locale )">{{ $t( 'signflow.Ok' ) }}</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-flex>
        </v-container>
      </v-layout>
    </v-main>
    <v-main app v-if="( $route.path === '/' ) || ( $route.path.includes( '/docs/api' ) ) ">
      <v-container fluid class="pa-0 fill-height">
        <router-view></router-view>
      </v-container>
    </v-main>
    <sh-app-footer v-if="( $route.path !== '/' ) && ( !$route.path.includes( '/docs/api' ) )"/>
  </v-app>
</template>

<script>
    import { globalEvents, JSONPureClient } from "@cloudlace/client";
    import util from "../../util/util";
    import AppFooter from "../common/AppFooter";
    import signedInServiceSchema from "@cloudlace/vue-widgets/src/api/signedin-service-schema";
    const signedInClient = new JSONPureClient( {
        service_url : process.env.VUE_APP_USER_SIGNEDIN_URL,
        service_map : signedInServiceSchema
    } );
    /**
     * Lookup the available locales from i18n. The locales will be translated with the messages
     * "signedin.locale!en", "signedin.locale!fi", and "signedin.locale!pl" and "signedin.locale!de" and "signedin.locale!se"
     */
    const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];
    /**
     * Bootsrap app.
     *
     * @module
     */
    export default {
        name : 'sh-signhero',
        components : { "sh-app-footer" : AppFooter },
        data()
        {
            return {
                rightDrawer : null,
                locale : util.getLocale(),
                isAdmin : util.getIsAdmin(),
                isSubscriptionAdmin : util.getIsSubscriptionAdmin(),
                stripePaymentOn : $cc_user_info.stripePaymentOn,
                walletEnabled : $cc_user_info.organization.wallet_enabled,
                locales : locales,
                showNav : false,
                companyName : "",
                userName : "",
                items : [
                    { path : "/start", label : this.$t( "signedin.Start" ) },
                    { path : "/ongoing", label : this.$t( "signedin.Ongoing" ) },
                    { path : "/complete", label : this.$t( "signedin.Complete" ) }
                ],
                choose_language_setting : false
            }
        },
        computed : {
            curLocale : util.getLocale
        },
        methods : {
            /**
             * Deletes access token, emits 'signed-out' event, picked up by 'App.vue'
             */
            signOut()
            {
                util.logoutSessions( this.$store.shioCoreClient );
            },
            /**
             * Toggle navigation drawer.
             */
            toggleNav()
            {
                this.showNav = !this.showNav;
            },
            /**
             * Change locale to newLoc. This involves a page reload.
             *
             * @param newLoc
             */
            setLocale( newLoc )
            {
                this.$store.userSignedInClient.updateProfile( { locale : newLoc } ).then(
                () => util.setLocale( newLoc )
                ).catch( () => util.setLocale( newLoc ) );
            },
            /**
             * React to relevant server messages: sign out if your organisation has been deleted
             *
             * @param evt {Event}
             */
            onShioCoreMessage( evt )
            {
                const msg = evt.detail;
                if( msg.action_str === "DELETED" && msg.data_type === "group" )
                {
                    this.$alert( this.$t( "signedin.OrganizationHasBeenDeleted" ) ).then( r =>
                    {
                        util.logoutAllSessions( this.$store.shioCoreClient );
                    } );
                }
            },
            showLanguageSetting()
            {
              this.locale = util.getLocale();
              this.choose_language_setting = !this.choose_language_setting;
            },
            closeLanguageSetting() {
              this.choose_language_setting = false;
            }
        },
        /**
         * Set app properties and a listener for when they change.
         */
        mounted()
        {
            this.companyName = $cc_user_info.organization.display_name;
            this.userName = $cc_user_info.name;
            globalEvents.on( "orgname-changed", orgName =>
            {
                this.companyName = $cc_user_info.organization.display_name
            } );
            globalEvents.on( "profile-changed", msg =>
            {
                if ( msg.detail && msg.detail.name )
                {
                    this.userName = msg.detail.name;
                }
            } );
            globalEvents.on( "scope-changed", () =>
            {
                this.isSubscriptionAdmin = util.getIsSubscriptionAdmin();
            } );
            globalEvents.on( "shio-core-notification", this.onShioCoreMessage );
        },
        /**
         * Remove global event listener.
         */
        beforeDestroy()
        {
            globalEvents.remove( "shio-core-notification", this.onShioCoreMessage );
        }
    }
</script>

<style lang="sass">
  @import "../../styles/style"

  .status-btn
    background-color: $vivid-pink !important

  .category-title
    color: $vivid-pink !important

  .v-list-item--active.v-list-item.v-list-item--link.theme--dark
    color: $vivid-pink !important

  .v-list-item--active.v-list-item.v-list-item--link.theme--dark:before
    background-color: $background-black !important

  .v-list-item.v-list-item--link.theme--dark:hover
    color: $vivid-pink !important

  .v-list-item.v-list-item--link.theme--dark:hover:before
    background-color: $background-black !important

  .v-navigation-drawer.right-drawer, .v-navigation-drawer.left-drawer
    background: $background-black
    margin-top: 64px
    max-height: calc(100vh - 64px)

  .v-toolbar__title
    padding-left: 0 !important

  .v-toolbar__content,
  .v-toolbar__extension
    padding: 4px

  a.shio-process-button.v-btn.theme--dark
    background-color: transparent

  a.shio-process-button.v-btn.theme--dark:hover
    background-color: $vivid-pink

  .shio-process-button, .shio-user-button
    text-transform: none

  .shio-process-button span.v-btn__content
    font-size: 20px

  .shio-user-button span.v-btn__content
    font-size: 16px

  .shio-user-button:hover
    color: $vivid-pink

  .shio-user-button:before
    background-color: $background-black !important

  .container-api-docs
    max-width: 100%
    padding: 0

  .signedin-right-list
    font-size: 17px

  .v-list-item__content
    -webkit-backface-visibility: hidden
    -webkit-transform-style: preserve-3d

  /* Left drawer */

  aside.left-drawer.v-navigation-drawer
    width: 120px !important

  .left-drawer a.v-list-item.v-list-item--link, .left-drawer a.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    color: white !important

  a.left-drawer-active-items.v-list-item--active.v-list-item
    color: $vivid-pink !important
</style>
