export default {
    "pdfsign": {
        "Error!Unexpected": "Er is een onverwachte fout opgetreden. Probeer het later opnieuw.",
        "SelectSigner": "Kies ondertekenaar",
        "SignatureTimestampFormat": "Ondertekend op dd-mm-jjjj",
        "DeclinationPlaceHolder": "Redenen voor weigering (optioneel)",
        "SignerGreetings": "Hallo ",
        "WhoSigns": "Wie ondertekent:",
        "Sign": "Ondertekenen",
        "WhatSigned": "Wat er is ondertekend: <span class='sh-nowOrNeverText'>(klik op het document om het te controleren: dit is je kans!)</span>",
        "SignConfirmationMsg": "Ik heb het document/de documenten bekeken en heb het recht om te ondertekenen.",
        "UsagePolicyInfoMsg": "Door SignHero te gebruiken, accepteer je onze <a href='terms-of-use.html' target='_blank' style='color: #ff507a;'>gebruiksvoorwaarden</a> en ons <a href='privacy-policy.html' target='_blank' style='color: #ff507a;'>privacybeleid</a>.",
        "Decline": "Weigeren",
        "Cancel": "Annuleren",
        "ThankUsingService": "Toch bedankt!",
        "FindMoreInformation": "Kies voor gemak op <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "Het ondertekeningsproces is geannuleerd",
        "CompleteProcessInfo": "Je kunt het/de document(en) niet ondertekenen omdat dit ondertekeningsverzoek is afgesloten. Mogelijk heb je deze documenten al ondertekend of is deze door de afzender geannuleerd.",
        "DeclineToSignTitle": "Weigeren te ondertekenen",
        "SignedAndSent": "Document(en) zijn ondertekend.",
        "SorryUnexpectedError": "Sorry, er ging iets mis",
        "SessionNeedRefreshError": "Uw sessie moet worden vernieuwd. Probeer het opnieuw.",
        "ReasonExpiredSession": "De link die je hebt gebruikt werkt niet of de uitnodiging is verwijderd.",
        "InvalidLoginUrl": "Controleer of de ingevoerde URL overeenkomt met de link in de e-mail en probeer het opnieuw.",
        "You": "jij"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "locale!th" : "ภาษาไทย",
        "Applications": "Applicaties",
        "SearchUsers": "Zoeken naar gebruikers",
        "UserDeleted" : "De gebruiker is gedeactiveerd en wordt na 30 dagen permanent verwijderd",
        "UserRestored" : "De gebruiker is hersteld",
        "UserAlreadyActive" : "De gebruiker is al actief.",
        "OrganizationHasBeenDeleted": "Een beheerder heeft zojuist jouw organisatie verwijderd.\nJammer dat je bij ons weggaat, we hopen dat je weer terugkomt.",
        "SendReminder": "Activeringsherinnering sturen",
        "EmailReminderSuccess": "E-mailherinnering verzonden.",
        "AdminStatusChanged": "Gebruiker bijgewerkt.",
        "DateTimeFormat": "dd.MM.yyyy, HH:mm:ss",
        "SessionType!password": "Wachtwoord",
        "SessionType!auth_code": "Applicatie",
        "Start": "Begin nieuw",
        "TheService": "Administratie",
        "Save": "Opslaan",
        "MyOrganization": "Mijn organisatie",
        "OrganizationName": "Naam van de organisatie",
        "ManageAccounts": "Accounts beheren",
        "MyIntegrations": "Integraties",
        "Statistics" : "Statistieken",
        "isAdmin": "Beheer",
        "DeleteUser": "Gebruikersaccount verwijderen",
        "RestoreAccount": "Gebruikersaccount herstellen",
        "Error!Unexpected": "Er is een onverwachte fout opgetreden. Probeer het later opnieuw.",
        "Error!InvitedUserExists": "De gebruiker is al lid van deze organisatie of behoort tot een andere organisatie. Neem in het laatste geval contact op met ${email}",
        "InviteUser": "Uitnodigen",
        "UserInvited" : "Gebruiker uitgenodigd",
        "Create": "Aanmaken",
        "ConfirmDeleteEverything": "Weet je zeker dat je dit organisatieaccount (${groupTitle}) wilt verwijderen?\n",
        "ConfirmCancelOngoingProcesses" : "Je organisatie heeft ${processNum} lopende ondertekeningsprocessen. Ze moeten ook worden geannuleerd voordat je doorgaat met het verwijderen van het organisatieaccount.\n "
                                          + "\nWeet je zeker dat je alle lopende processen wilt annuleren en dit organisatieaccount wilt verwijderen (${groupTitle})?\n"
                                          + "\nVoer je de code in om door te gaan: ${rndCode}",
        "IncorrectConfirmationCode" : "Foute bevestigingscode.",
        "DownloadOrganizationDataMessage": "Downloaden gebruikers- en ondertekeningsprocesgegevens als JSON-bestand.",
        "DownloadOrganizationDataLink": "Organisatiegegevens downloaden",
        "Subscription": "Abonnement",
        "InviteUserHeader": "Nieuwe gebruiker uitnodigen voor je organisatie",
        "AccountStatus" : "Accountstatus:",
        "OwnAccount" : "Je eigen account",
        "StatusActive" : "Actief, de gebruiker heeft de uitnodiging geaccepteerd.",
        "StatusInactive" : "Inactief, de gebruiker heeft de uitnodiging nog niet geaccepteerd.",
        "StatusDeleted" : "Gemarkeerd voor verwijdering. Het abonnement is inactief en wordt niet verlengd voor deze gebruiker.",
        "AccountRights" : "Rechten:",
        "DownloadOrganizationData": "JSON downloaden",
        "DeleteOrganizationMessage": "Deze organisatie, je account en alle andere beheerders- en gebruikersaccounts verwijderen",
        "DeleteEverything": "Alles verwijderen",
        "OrganizationHasActiveProcesses" : "Kan de organisatie niet verwijderen omdat deze actieve ondertekeningsprocessen of samenvoegverzoeken heeft.",
        "MergeOrganizationMessage": "Organisatie kan verzoeken om samen te voegen met een andere.",
        "RequestMergeDetails": "De beheerder van de organisatie kan verzoeken om de organisatie samen te voegen met een andere. Het verzoek is 24 uur geldig.\n\n"
            + "Na acceptatie van het verzoek worden de ondertekeningsprocessen inclusief documenten en gebruikers overgezet naar de nieuwe organisatie, en deze organisatie zal verdwijnen. "
            + "Gebruikers van deze organisatie hebben na het samenvoegen geen beheerdersrechten meer. De rechten moeten worden toegevoegd door de beheerder van de nieuwe organisatie. Als er sprake was van "
            + "een actief abonnement, wordt de rest van de abonnementsperiode geannuleerd en worden de samengevoegde gebruikers meegeteld bij het abonnement van de nieuwe organisatie.",
        "MergeOrganizationDetails": "De organisatie ${groupName} heeft gevraagd om het organisatieaccount samen te voegen "
            + "met jouw organisatieaccount. Het verzoek is 24 uur geldig.\n\n"
            + "Na acceptatie van het verzoek worden de ondertekeningsprocessen inclusief documenten en gebruikers overgezet naar jouw organisatie, "
            + "en de gemigreerde organisatie zal verdwijnen. Gebruikers van de gemigreerde organisatie hebben na het samenvoegen geen beheerdersrechten meer.  "
            + "De rechten moeten worden toegevoegd door de beheerder van jouw organisatie. Als er sprake was van een actief abonnement "
            + "worden de gemigreerde gebruikers meegeteld bij het abonnement van jouw organisatie.",
        "RequestMerge": "Samenvoeging aanvragen",
        "ConfirmMergeOrganisation": "Weet je zeker dat je dit organisatieaccount wilt samenvoegen? Het opnieuw splitsen van accounts wordt niet ondersteund.",
        "EnterAdminEmail": "Voer het e-mailadres van een beheerder van de andere organisatie in:",
        "EnterPassword": "Voer je wachtwoord in om door te gaan:",
        "InvalidPassword" : "Je wachtwoord kwam niet overeen. Probeer het opnieuw.",
        "MergeRequestSent": "Samenvoegverzoek van organisatie verzonden",
        "MergeRequestCanceled": "Samenvoegverzoek van organisatie geannuleerd",
        "MergeRequestRejected": "Samenvoegverzoek van organisatie afgewezen",
        "ReceivedMergeRequests": "Samenvoegverzoeken ontvangen",
        "SentMergeRequest": "Verzonden samenvoegverzoek",
        "Recipient": "Ontvanger",
        "Reject": "Afwijzen",
        "Merge": "Samenvoegen",
        "OrganisationMerged": "Het samenvoegen is gestart.",
        "UserNotDifferentOrganizationAdmin": "Gebruiker is geen beheerder in een andere organisatie",
        "UserNotFound": "Sorry, gebruiker niet gevonden!",
        "OrganizationHasActiveMergeRequest": "Organisatie heeft al een actief samenvoegverzoek",
        "InvalidMergeRequest": "Samenvoegverzoek is niet gevonden of is verlopen",
        "Complete": "Archief",
        "Locales": "Taal",
        "Sessions": "Sessies",
        "LogOutAllDevicesMessage": "Als je wil, kan je op al je apparaten uitloggen uit alle sessies.",
        "NoApplicationsMessage": "Je hebt nog geen toegang verleend tot een applicatie.",
        "Domain" : "Domein",
        "AccessGiven" : "Toegang gegeven op",
        "LastAccess" : "Laatste toegang",
        "RemoveAccess": "Toegang verwijderen",
        "Ongoing": "Lopend",
        "MyAccount": "Account",
        "UserName": "Naam",
        "Email": "E-mail",
        "Logout": "Uitloggen",
        "SignOut": "Uitloggen bij alle sessies",
        "Confirm": "Bevestigen",
        "Cancel": "Annuleren",
        "Reset": "Resetten",
        "Profile": "Mijn profiel",
        "Organization": "Organisatie",
        "Device": "Apparaat",
        "Update" : "Opslaan",
        "Delete" : "Verwijderen",
        "AcceptApiTerms" : "Door de SignHero API te gebruiken, accepteer je onze <a href='/terms/api' target='_blank'>API-voorwaarden</a>.",
        "CannotDeleteActiveFlows" : "Je organisatie heeft lopende ondertekeningsprocessen. Je moet deze eerst annuleren voordat je door kunt met het verwijderen van het organisatieaccount.",
        "MySubscription" : "Mijn abonnement",
        "FreeTrialLeft" : "Resterende tijd voor gratis proefperiode: ",
        "Days" : "dagen.",
        "SubscriptionPeriod" : "Huidige abonnementsperiode:",
        "PleaseActivateSubscription" : "Activeer uw abonnement.",
        "Tab1" : "Betalingsinformatie",
        "Tab2" : "Factureringsgeschiedenis",
        "Tab3" : "Over",
        "NoPaymentNotification" : "Opmerking: Er is nu geen geldige betaalmethode vastgelegd.",
        "AddCreditCard" : "Voeg een creditcard toe voor de maandelijkse facturering van SignHero",
        "CreditCardEmail" : "E-mail",
        "BillingDate" : "Factuurdatum",
        "Amount" : "Bedrag",
        "UsersAmount" : "Gebruikers",
        "Duration" : "Looptijd",
        "ReferNumber" : "Referentienummer",
        "Invoice" : "Factuur",
        "Action" : "Actie",
        "LoadMore" : "Meer laden",
        "SalesChannel" : "Verkoopkanaal",
        "ResellerName" : "Reseller: ",
        "ResellerContact" : "Contact: ",
        "ResellerMaxUsers" : "Max. aantal gebruikers: ",
        "ResellerCurrentInvitedUsers" : "Aantal uitgenodigde gebruikers: ",
        "MaxResellerUsersWarning" : "Je hebt het maximaal aantal gebruikers voor resellers (${resellerMaxUsers}) bereikt.",
        "StartOfSubscription" : "Startdatum abonnement:",
        "EstimatedBilling" : "Volgende factuur op ${nextBillingDate} voor ${nextBillingValue}€",
        "SubInactive1" : "Abonnement is inactief",
        "SubInactive2" : ", deze wordt niet verlengd voor deze verwijderde gebruiker",
        "isSubscriptionAdmin" : "Abonnementbeheer",
        "CardInfo" : "Kaartinformatie",
        "NameOnCard" : "Naam op kaart",
        "SelectCountry" : "Land of regio",
        "SelectLocale" : "Selecteer een taal",
        "BusinessAccount" : "Zakelijk account",
        "OnlyBusinessAccountsSupported" : "Voor het geselecteerde land worden alleen zakelijke accounts ondersteund.",
        "TaxIdExplanation" : "Kies de belastingregio en voer het btw-nummer van het bedrijf in.",
        "SelectIdType" : "Selecteer type btw-nummer",
        "TaxId" : "Btw-nummer",
        "AddCardTooltip1" : "Voeg eerst geldige factuurgegevens toe.",
        "AddCardTooltip2" : "Voeg geldige creditcardgegevens toe. Alle velden zijn verplicht.",
        "AddCardTooltip3" : "Creditcardgegevens zijn geldig.",
        "BillingInformation" : "Facturatiegegevens",
        "Edit" : "Bewerken",
        "EditBillingInformation" : "Facturatiegegevens bewerken.",
        "AddBillingTooltip" : "Vul alle verplichte velden in en controleer of het btw-nummer overeenkomt met de landinformatie.",
        "Street" : "Adres",
        "Street2" : "Adres2",
        "City" : "Plaats",
        "State" : "Staat",
        "Zip" : "Postcode",
        "SaveBillingInfo" : "Facturatiegegevens opslaan",
        "SaveCard" : "Kaartgegevens opslaan",
        "Expires" : "Geldig t/m",
        "DeleteCardOnly" : "Creditcard verwijderen",
        "DeletePaymentInfo" : "De factuurgegevens en creditcardgegevens verwijderen.",
        "RemoveWholePaymentMethod" : "Betalingsgegevens verwijderen",
        "PayOnline" : "Betaling bevestigen",
        "PaymentStatusPaid" : "Betaald",
        "PaymentStatusPending" : "In afwachting",
        "PaymentInfo" : "Over",
        "PaymentExplanation1" : "SignHero kost € 5,- per maand per actieve gebruiker (prijs is exclusief btw). De op dat moment geldende btw wordt bij de prijs opgeteld. Inactieve gebruikers worden niet meegerekend op de rekening. Als je gebruikers toevoegt of verwijdert tijdens een factureringscyclus, berekenen we automatisch hoeveel minder of meer de er de volgende keer in rekening moet worden gebracht op basis van hoeveel tijd er nog in de factureringscyclus over was toen de wijziging werd doorgevoerd. Bij aanmelding krijg je een gratis proefperiode van 14 dagen. Daarna moet je een creditcard registreren voor maandelijkse betalingen als je gebruik wil blijven maken van de dienst. Het bedrag wordt elke maand automatisch van de creditcard afgeschreven op basis van het aantal gebruikers dat is geregistreerd voor de dienst. Je ontvangt een factuur van de maandelijkse betaling op het e-mailadres dat je hebt opgegeven bij het registreren van de creditcard.",
        "PaymentExplanation2" : "Je moet het btw-nummer van het bedrijf invoeren, omdat SignHero btw van klanten int volgens de Finse en EU-belastingwetgeving. Voor bedrijven binnen de EU moet een geldig btw-nummer worden verstrekt. Als je land niet is opgenomen in de land- of regioselectie, kun je contact met ons opnemen voor alternatieve betaalmethoden. Laat het ons in ieder geval weten als je jouw land niet hebt gevonden en om die reden hebt besloten de dienst niet te gebruiken. We voegen deze mogelijk toe in de toekomst en kunnen het laten weten als dit gebeurt!",
        "PaymentExplanation3" : "Als je geen creditcard kunt toevoegen voor automatische facturering, neem dan contact op met SignHero via <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "PaymentExplanation4" : "Probleem met de factuur? Vragen over hoe alles werkt? Neem voor vragen contact met ons op via <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "CountersPending" : "De statistieken worden berekend. Probeer het na enkele minuten opnieuw.",
        "WholeOrganisation" : "Hele organisatie",
        "OrganisationHas" : "Je organisatie heeft momenteel",
        "ActiveUsers" : "actieve gebruikers en",
        "InactiveUsers" : "inactieve gebruikers.",
        "ChartDescriptionOrg" : "Het onderstaande diagram toont de aangemaakte ondertekeningsprocessen en documenten van de hele organisatie.",
        "ChartDescriptionUser" : "Het onderstaande diagram toont de gemaakte ondertekeningsprocessen van de geselecteerde gebruikers.",
        "SelectUsers" : "Gebruikers selecteren",
        "From" : "Van",
        "To" : "Tot",
        "AmountOfDocuments" : "In de geselecteerde periode is het aantal nieuwe documenten",
        "AmountOfSignatureProcesses" : "en is het aantal nieuwe ondertekeningsprocessen",
        "Processes" : "Processen",
        "Documents" : "Documenten",
        "Users" : "Gebruikers",
        "UserActivity" : "Gebruikersactiviteit",
        "Signatures": "Handtekeningen",
        "User" : "Gebruiker",
        "Inactive" : "Inactief",
        "SignatureProcesses" : "Ondertekeningsprocessen in de periode",
        "AllSignatureProcesses" : "Alle ondertekeningsprocessen",
        "FreeTrialAlert1" : "Je organisatie heeft nog",
        "FreeTrialAlert2" : "dagen over in de gratis proefperiode!",
        "ContactAdmin" : "Geen actief abonnement, neem contact op met je beheerder!",
        "PaymentOverdueAlert" : "Achterstallige betaling, voeg een geldige kaart toe",
        "PaymentOverdueAlertIgnore" : "Als je al een geldige kaart hebt toegevoegd, kun je dit bericht negeren",
        "PleaseAddValidCPayment" : "Geen actief abonnement, voeg een geldige betaalmethode toe!",
        "PleaseConfirmPayment" : "Kaartauthenticatie vereist. Controleer \"Factureringsgeschiedenis\"!",
        "PleaseConfirmPaymentMainPage" : "Geen geldige betaalmethode of kaartauthenticatie vereist. Controleer \"Mijn abonnement\"!",
        "ConfirmDeletePaymentMethod": "Weet je zeker dat je de factuur- en creditcardgegevens wilt verwijderen?",
        "ConsentDialogTitle": "Aanmelden bij SignHero met terugkerende betalingen?",
        "ConsentText1": "SignHero maakt snelle en soepele eSigning mogelijk. Verzend en onderteken pdf's, volg processen, herinner ondertekenaars, krijg toegang tot audittrails, werk in teams en archiveer ondertekende documenten.",
        "ConsentText2": "SignHero kost € 5,- per maand per actieve gebruiker + btw. Inactieve gebruikers worden niet meegerekend op de factuur. Als je gebruikers toevoegt of verwijdert tijdens een factureringscyclus, berekenen we automatisch hoeveel minder of meer de er bij volgende factuur in rekening moet worden gebracht op basis van hoeveel tijd er nog in de factureringscyclus over was toen de wijziging werd doorgevoerd. Bij aanmelding krijg je een gratis proefperiode van 14 dagen. Daarna moet je een creditcard registreren voor maandelijkse betalingen als je gebruik wilt blijven maken van de dienst. Het bedrag wordt elke maand op dezelfde datum automatisch van de creditcard afgeschreven op basis van het aantal gebruikers dat is geregistreerd voor de dienst.  Na registratie van de creditcard is het abonnement geldig na een succesvolle betaling en blijft deze geldig tenzij de betaling mislukt. Je zult de volgende e-mail ontvangen over je SignHero-abonnement:",
        "ConsentText3": "Je moet het btw-nummer van het bedrijf invoeren, omdat SignHero btw van klanten int volgens de Finse en EU-belastingwetgeving. Voor bedrijven binnen de EU moet een geldig btw-nummer worden verstrekt. Als je land niet is opgenomen in de land- of regioselectie, kun je contact met ons opnemen voor alternatieve betaalmethoden via hello@signhero.io. Vertel ons in je bericht in ieder geval als je jouw land niet hebt gevonden en om die reden hebt besloten de dienst niet te gebruiken. We voegen deze mogelijk toe in de toekomst en kunnen het laten weten als dit gebeurt!",
        "ConsentText4": "Om het abonnement op te zeggen, kunt je de creditcardgegevens verwijderen en wordt het huidige abonnement aan het einde van de abonnementsperiode opgezegd. Je kunt dit ook doen door op de knop \"Abonnement opzeggen\" te drukken.",
        "ConsentEmailBullet1": "een herinnering voor het einde van een proefperiode",
        "ConsentEmailBullet2": "een ontvangstbewijs van de maandelijkse betaling naar het e-mailadres dat je hebt opgegeven",
        "ConsentEmailBullet3": "een e-mailmelding 7 dagen voor elke transactie",
        "ConsentCheckBoxLabel1": "Ik heb bovenstaande informatie gelezen",
        "ConsentCheckBoxLabel2": "Ik ga ermee akkoord om me te abonneren op de SignHero-dienst voor terugkerende betalingen",
        "Agree": "Akkoord",
        "CurrentPlan" : "Huidig abonnement",
        "SignHeroSubscription" : "SignHero-abonnement",
        "CancelPlan" : "Abonnement annuleren",
        "CreatePlan" : "Abonnement aanmaken",
        "PriceInfo" : "€ 5,- per maand per actieve gebruiker",
        "TaxTooltip" : "Let op: De prijs is exclusief btw.",
        "PleaseAddValidCard" : "Voeg een geldige betaalmethode toe",
        "CancelPlanConfirm" : "Weet je zeker dat je het huidige abonnement wilt opzeggen?\nJe kunt dan geen gebruik meer maken van de resterende proefperiode of de resterende tijd van het betaalde abonnement. Je krijgt geen compensatie voor de resterende tijd.",
        "CreatePlanConfirm" : "Weet je zeker dat je een nieuw abonnement wilt aanmaken?",
        "numbersDidNotMatch" : "De codes komen niet overeen. Probeer het opnieuw.",
        "toProceedEnterCode" : "Voer de code in om verder te gaan: ",
        "subStatus!trialing" : "Proefperiode eindigt ",
        "subStatus!active" : "Actief",
        "subStatus!canceled" : "Geannuleerd",
        "subStatus!incomplete" : "Onvolledig",
        "subStatus!incomplete_expired" : "Verlopen",
        "subStatus!past_due" : "Achterstallig",
        "ConfirmCreateSubNow" : "Je kunt nu of later een nieuw abonnement aanmaken op de pagina mijn abonnement.",
        "CreateSubscriptionNow" : "Nu aanmaken",
        "CreateSubscriptionLater" : "Later",
        "Country!Australia" : "Australië",
        "Country!Austria" : "Oostenrijk",
        "Country!Belgium" : "België",
        "Country!Brazil" : "Brazilië",
        "Country!Bulgaria" : "Bulgarije",
        "Country!Canada" : "Canada",
        "Country!Chile" : "Chili",
        "Country!Croatia" : "Kroatië",
        "Country!Cyprus" : "Cyprus",
        "Country!CzechRepublic" : "Tsjechië",
        "Country!Denmark" : "Denemarken",
        "Country!Estonia" : "Estland",
        "Country!Finland" : "Finland",
        "Country!France" : "Frankrijk",
        "Country!Germany" : "Duitsland",
        "Country!Greece" : "Griekenland",
        "Country!HongKong" : "Hongkong",
        "Country!Hungary" : "Hongarije",
        "Country!India" : "Indië",
        "Country!Indonesia" : "Indonesië",
        "Country!Ireland" : "Ierland",
        "Country!Israel" : "Israël",
        "Country!Italy" : "Italië",
        "Country!Japan" : "Japan",
        "Country!Korea" : "Korea",
        "Country!Latvia" : "Letland",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Litouwen",
        "Country!Luxembourg" : "Luxemburg",
        "Country!Malaysia" : "Maleisië",
        "Country!Malta" : "Malta",
        "Country!Mexico" : "Mexico",
        "Country!Netherlands" : "Nederland",
        "Country!NewZealand" : "Nieuw-Zeeland",
        "Country!Norway" : "Noorwegen",
        "Country!Poland" : "Polen",
        "Country!Portugal" : "Portugal",
        "Country!Romania" : "Roemenië",
        "Country!RussianFederation" : "Russische Federatie",
        "Country!SaudiArabia" : "Saoedi-Arabië",
        "Country!Singapore" : "Singapore",
        "Country!Slovakia" : "Slowakije",
        "Country!Slovenia" : "Slovenië",
        "Country!SouthAfrica" : "Zuid-Afrika",
        "Country!Spain" : "Spanje",
        "Country!Sweden" : "Zweden",
        "Country!Switzerland" : "Zwitserland",
        "Country!Taiwan" : "Taiwan",
        "Country!Thailand" : "Thailand",
        "Country!UnitedArabEmirates" : "Verenigde Arabische Emiraten",
        "Country!UnitedKingdom" : "Verenigd Koninkrijk",
        "Country!UnitedStates" : "Verenigde Staten",
        "TaxType!au_abn" : "Australisch bedrijfsnummer (AU ABN)",
        "TaxType!au_arn" : "Referentienummer Australische belastingdienst",
        "TaxType!eu_vat" : "Europees btw-nummer",
        "TaxType!br_cnpj" : "Braziliaans CNPJ-nummer",
        "TaxType!br_cpf" : "Braziliaans CPF-nummer",
        "TaxType!ca_bn" : "Canadees bedrijfsnummer (BN)",
        "TaxType!ca_gst_hst" : "Canadees GST/HST-nummer",
        "TaxType!ca_pst_bc" : "Canadees PST-nummer (British Columbia)",
        "TaxType!ca_pst_mb" : "Canadees PST-nummer (Manitoba)",
        "TaxType!ca_pst_sk" : "Canadees PST-nummer (Saskatchewan)",
        "TaxType!ca_qst" : "Canadees QST-nummer (Québec)",
        "TaxType!cl_tin" : "Chileense TIN",
        "TaxType!hk_br" : "BR-nummer van Hongkong",
        "TaxType!in_gst" : "Indiaas GST-nummer",
        "TaxType!id_npwp" : "Indonesisch NPWP-nummer",
        "TaxType!il_vat" : "Btw Israël",
        "TaxType!jp_cn" : "Japans bedrijfsnummer",
        "TaxType!jp_rn" : "Registratienummer van Japanse geregistreerde buitenlandse bedrijven",
        "TaxType!kr_brn" : "Koreaanse BRN",
        "TaxType!li_uid" : "Liechtensteins UID-nummer",
        "TaxType!my_frp" : "Maleisisch FRP-nummer",
        "TaxType!my_itn" : "Maleisische ITN",
        "TaxType!my_sst" : "Maleisisch SST-nummer",
        "TaxType!mx_rfc" : "Mexicaans RFC-nummer",
        "TaxType!nz_gst" : "Nieuw-Zeelands GST-nummer",
        "TaxType!no_vat" : "Noors btw-nummer",
        "TaxType!ru_inn" : "Russische INN",
        "TaxType!ru_kpp" : "Russische KPP",
        "TaxType!sa_vat" : "Btw Saoedi-Arabië",
        "TaxType!sg_gst" : "Singaporese GST",
        "TaxType!sg_uen" : "Singaporese UEN",
        "TaxType!za_vat" : "Zuid-Afrikaans btw-nummer",
        "TaxType!es_cif" : "Spaans CIF-nummer",
        "TaxType!ch_vat" : "Zwitsers btw-nummer",
        "TaxType!tw_vat" : "Taiwanese btw",
        "TaxType!th_vat" : "Thaise btw",
        "TaxType!ae_trn" : "TRN Verenigde Arabische Emiraten",
        "TaxType!gb_vat" : "Btw-nummer Verenigd Koninkrijk",
        "TaxType!us_ein" : "EIN Verenigde Staten"
    },
    "signflow": {
        "SendReminder": "Activeringsherinnering sturen",
        "SessionType!password": "Wachtwoord",
        "SessionType!auth_code": "Applicatie",
        "Start": "Begin nieuw",
        "Error!Unexpected": "Er is een onverwachte fout opgetreden. Probeer het later opnieuw.",
        "ButtonBack": "Terug",
        "ButtonGoBack": "Ga terug",
        "ButtonNext": "Volgende",
        "Create": "Aanmaken",
        "Complete": "Archief",
        "SearchSignFlows": "Zoek op ondertekenaar, titel of document",
        "AllFlows": "Alles",
        "PendingMeFlows": "In afwachting van mij",
        "PendingOthersFlows": "In afwachting van anderen",
        "CanceledFlows": "Geannuleerd",
        "CompletedFlows": "Voltooid",
        "ShowGroupProcesses": "Alle processen van de organisatie",
        "CancellationReasons": "Reden: ${cancellationReasons}",
        "SignedDocuments": "Document(en):",
        "CreatedByTitle": "Gemaakt door:",
        "CanceledByTitle": "Geannuleerd door:",
        "PendingSignaturesTitle": "In afwachting:",
        "PendingMeAlert": "<span class='elem-pink'>Je</span> hebt niet ondertekend.",
        "PendingOthersAlert": "In afwachting van <span class='elem-pink'>${count}</span> persoon(en) om te ondertekenen",
        "SignerStatusTitle": "Status:",
        "ReminderHistory": "Herinneringsgeschiedenis",
        "SignerStatus1": "In afwachting, uitnodiging voor het eerst geopend op ",
        "SignerStatus2": "In afwachting, uitnodiging nooit geopend.",
        "SignerStatus3": "Ondertekend op ",
        "SignerStatus4": "In afwachting van de maker.",
        "SendReminders": "Herinneren per e-mail",
        "NoProcessesFound": "Geen processen die overeenkomen met de filters",
        "LastEmailReminder": "Laatste herinnering: <b>${timestamp}</b>",
        "NoRemindersYet": "Geen herinneringen verzonden",
        "EmailReminderSuccess": "E-mailherinneringen verzonden.",
        "ReminderSentBy": "verzonden door",
        "CancelProcessDialogTitle": "Proces annuleren",
        "CancellationReasonsPlaceHolder": "Redenen voor annulering",
        "CancelProcess": "Proces annuleren",
        "DeleteProcess": "Proces verwijderen",
        "RestartProcess": "Opnieuw beginnen",
        "ConfirmDeleteProcess": "Weet je zeker dat je het proces wilt verwijderen?",
        "ResendDownloadInvitation": "Downloadlink opnieuw sturen",
        "DownloadInvitationEmailSuccess": "Email verzonden.",
        "DownloadInvitationEmailFail": "Verzenden is mislukt",
        "Me": "Ik",
        "SignFlowDocumentsHeading": "Wat is er ondertekend?",
        "FlowSettings": "Extra's",
        "FlowTitlePlaceholder": "Geef de zaak een naam voor eenvoudige archivering",
        "Signers": "Ondertekenaars *",
        "AddSigner": "Meer ondertekenaars toevoegen",
        "AddSignerGroup": "Ondertekenaars uit bestand toevoegen",
        "AddressBookChooseFile": "Bestand kiezen",
        "AddressBookInfoHeader": "Zo voeg je ondertekenaars toe uit een bestand",
        "AddressBookInfo": "Het moet een bestand zijn met de extensie .txt/.csv, met elke ondertekenaar op een eigen regel en een komma tussen de velden.",
        "AddressBookInfoExampleHeader": "Bijvoorbeeld een bestand met de naam BoardMembers.txt met de volgende inhoud:",
        "AddressBookInfoExampleContent": "James Smith, james.smith@example.com<br>Mary Jones, mary.jones@example.com<br>John Williams, john.williams@example.com<br>Linda Taylor, linda.taylor@example.com",
        "NotAnAddressBook": "Het bestand bevat geen namen en adressen van ontvangers.",
        "CheckFileEncoding": "Het bestand bevat niet-ondersteunde tekens. Zorg ervoor dat de bestandscodering UTF-8 is.",
        "SignersName": "Voor- en achternaam",
        "SignersEmail": "E-mail",
        "EmailSubject": "E-mailtitel:",
        "EmailMessage": "Bericht:",
        "EditInvitation": "Handtekeninguitnodiging bewerken",
        "ChooseInvitation": "Uitnodigingssjabloon kiezen",
        "UseDragAndDropSignature": "Handtekeningposities instellen",
        "NoPrintedEmail": "E-mailadressen verbergen",
        "StartNow": "Nu ondertekenen",
        "StartSigningNow": "Je kunt nu of later ondertekenen via het tabblad Lopend.",
        "RequestEmailSendSigners": "E-mail met verzoek om handtekening wordt verzonden naar ondertekenaars. ",
        "InformProgressNotifications": "Je wordt per e-mail op de hoogte gehouden van de voortgang.",
        "Later": "Later",
        "Ok" : "Ok",
        "DefaultEmailSubject": "${$cc_user_info.name} nodigt je uit om te ondertekenen",
        "DefaultEmailMessage": "${$cc_user_info.name} van ${$cc_user_info.organization.display_name} (${$cc_user_info.email}) heeft je uitgenodigd voor eSign met SignHero.",
        "Signer": "Ondertekenaar",
        "Created": "Aangemaakt",
        "Canceled": "Geannuleerd",
        "Sign": "Ondertekenen",
        "Send": "Versturen",
        "GoToSign": "Ga naar ondertekenen",
        "Download": "Downloaden",
        "SignedBy": "Ondertekend door:",
        "WhoIsSigning": "Wie ondertekent?",
        "OnlyMe": "Alleen ik",
        "OnlyOthers": "Alleen anderen",
        "MeAndOthers": "Ik en anderen",
        "Email": "E-mail",
        "Add": "Toevoegen",
        "numbersDidNotMatch": "De codes komen niet overeen. Probeer het opnieuw.",
        "toProceedEnterCode": "Voer de code in om verder te gaan: ",
        "Confirm": "Bevestigen",
        "Continue": "Doorgaan",
        "Cancel": "Annuleren",
        "Reset": "Resetten",
        "selectFromDisk": "Lokaal",
        "UploadFile": "Uploaden",
        "fileTooLarge": "Maximale bestandsgrootte is ${maxSize}.",
        "allFilesTooLarge": "De totale maximale grootte voor alle bestanden is ${maxSize}. Je probeert nu ${uploadedSize} te uploaden.",
        "fileNumberTooLarge": "Upload maximaal ${maxFileNumber} bestanden.",
        "uploadError": "Sorry, er is iets mis met een bestand. Misschien een verkeerd formaat of het is al ondertekend.",
        "select": "Selecteren",
        "OngoingProcess" : "Lopende processen",
        "GoogleDrive": "Google Drive",
        "DownloadFailed": "Bestand downloaden mislukt",
        "Error!FilesOutdated" : "Fout sessietime-out. Graag de bestanden uploaden en indien nodig de gegevens opnieuw invullen",
        "Error!DocumentError" : "Sorry, de sessie is verlopen of er is iets mis met een bestand. Misschien een verkeerd formaat. Graag de bestanden uploaden en indien nodig de gegevens opnieuw invullen.",
        "OnlyPdf" : "Geaccepteerde bestandstypen zijn pdf",
        "OnlyCancelledProcessAllowed": "Je kunt alleen het geannuleerde proces opnieuw starten.",
        "CreateNewTags" : "Nieuw label maken",
        "CreateNewTagsHint" : "Druk op enter om een nieuw label toe te voegen: maximaal 20 tekens",
        "ManageTags" : "Labels beheren",
        "Tags" : "Labels",
        "FilterByTag" : "Filteren op label",
        "ErrorTagExists" : "Label bestaat al!   ",
        "MyOrganization" : "Mijn organisatie",
        "OtherOrganizations" : "Andere organisaties",
        "OwnedBy" : "Eigendom van:",
        "SavedTemplates" : "Uitnodigingssjabloon kiezen",
        "CreateTemplateTitle" : "Sla de uitnodigingssjabloon op",
        "TemplateCreated" : "Uitnodigingssjabloon opgeslagen.",
        "DeleteTemplateTitle" : "Verwijder gedeelde uitnodigingssjabloon",
        "DeleteTemplateConfirm" : "Weet je het zeker? Dit raakt de hele organisatie.",
        "Update" : "Bijwerken",
        "Edit" : "Bewerken",
        "Save": "Opslaan",
        "ExplainTemplate1" : "De organisatie heeft geen opgeslagen uitnodigingssjablonen. Uitnodigingssjablonen kunnen worden opgeslagen nadat ze zijn bewerkt.",
        "ExplainTemplate2" : "U kunt opslaan, maar dat hoeft niet! ",
        "ExplainTemplate3" : "De huidige handtekeninguitnodiging kan worden gebruikt zonder op te slaan.",
        "ExplainTemplate4" : "U kunt veelgebruikte uitnodigingssjablonen opslaan. De gemaakte sjablonen zijn zichtbaar voor de hele organisatie en kunnen door iedereen in de organisatie worden bewerkt of verwijderd.\nDe huidige handtekeninguitnodiging kan worden gebruikt zonder op te slaan.",
        "UpdateTemplate" : "Het sjabloon is zichtbaar, bewerkbaar en verwijderbaar voor iedereen in de organisatie.\n\nSjabloonnaam: ",
        "DeleteTemplate" : "Sjabloon verwijderd.",
        "TemplateNoname" : "noname",
        "Error!TemplateNameExist": "De sjabloon bestaat al, moet deze worden overschreven?",
        "Error!TemplateNameEmpty": "De sjabloonnaam ontbreekt!",
        "ProcessesTabName1" : "Mijn processen",
        "ProcessesTabName2" : "Mijn ondertekeningsuitnodigingen",
        "ShowOnly12MonthInfo" : "Processen van de afgelopen 12 maanden worden weergeven",
        "MaxSigners" : "Het maximale aantal ondertekenaars voor een proces is ",
        "MaxSigners1" : "Verwijder ",
        "MaxSigners2" : " ondertekenaars.",
        "MaxSigners3" : "Meer ondertekenaars kunnen niet worden toegevoegd."
    },
    "message": {
        "ReportProblem" : "Een probleem melden",
        "IForgotMyPassword" : "Wachtwoord resetten",
        "NavigationMenuButton": "Menu",
        "NavigationAccountButton": "Account",
        "NavigationContactButton": "Contact",
        "NavItemIntroduction": "Inleiding",
        "NavItemChooseEasy": "Kies voor gemak",
        "NavItemAccount": "Account",
        "NavItemAPIsIntegrations": "API's & Integraties",
        "NavItemDoubtsWorries": "Twijfels en zorgen",
        "NavItemThisIsUs": "Dit zijn wij",
        "NavItemContactUs": "Neem contact met ons op",
        "MainSlogan": "SignHero: makkelijker kan het niet!",
        "SubSloganPart1": "We komen in opstand tegen complexiteit, overontwikkelde processen,",
        "SubSloganPart2": "en de oude manier van doen.",
        "GoToRegister": "Start een gratis proefperiode",
        "TitleLazy": "Kies voor gemak",
        "TextLazyLine1": "Bank-ID's, sjablonen en krabbels zijn niet relevant;",
        "TextLazyLine2": "ze vertegenwoordigen overontwikkelde processen die tijd en geld verspillen.",
        "TextLazyLine3": "Fans van SignHero willen zich gewoon concentreren op wat belangrijk is, dus dat is wat we bieden.",
        "MetricsTitle": "Gemiddelde wekelijkse statistieken:",
        "Metrics1" : "6000+ mensen gebruiken SignHero",
        "Metrics2" : "2000+ contracten ondertekend",
        "Metrics3" : "100+ nieuwe teamaccounts",
        "TitleAccount": "Account",
        "TextAccount": "Het SignHero-account bevat alles wat je echt nodig hebt om eSign te gebruiken:",
        "Feature1": "Pdf's verzenden en ondertekenen",
        "Feature2": "Processen volgen",
        "Feature3": "Ondertekenaars herinneren",
        "Feature4": "Audittrail bekijken",
        "Feature5": "In groepen werken",
        "Feature6": "Documenten archiveren",
        "UnlimitedSigners": "Onbeperkt aantal ondertekenaars en uitnodigingen.",
        "EasyRegister": "Schrijf je in met je naam, e-mailadres en organisatie.",
        "Register": "Een account aanmaken",
        "TitlePricing": "Prijzen",
        "MonthlyPrice": "€ 5,-/gebruiker/maand",
        "MonthlyBill": "(Maandelijks gefactureerd. Prijs is exclusief btw. De op dat moment geldende btw wordt bij de prijs opgeteld.)",
        "Benefits1": "Onbeperkt aantal elektronische handtekeningen",
        "Benefits2": "Archief voor je documenten",
        "Benefits3": "Teamaccount voor meerdere gebruikers",
        "Benefits4": "Ondertekenaars hebben geen account nodig",
        "ButtonFreeTrial": "Start een proefperiode van 14 dagen",
        "CreditCardInfo": "Geen creditcard nodig",
        "TitleAPI": "API's & Integraties",
        "IntegrateSignHero": "Integreer onze ondertekenknop waar je maar wil.",
        "ToIntroBTN": "Inleiding",
        "ToDocsBTN": "Documenten",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "QuoteOlli": "\"Het integreren van de elektronische contractgeneratie en ondertekening in een WordPress-omgeving bespaart onze klanten tientallen uren tijd.\"",
        "AuthorOlli": "Olli Nyqvist",
        "CompanyOlli": "CEO, WebAula",
        "TitleDoubts": "Twijfels en zorgen",
        "DoubtsLegal": "Is eSigning wel legaal?",
        "TitleShortAnswer": "Kort antwoord:",
        "AnswerLegalParagraph1": "natuurlijk. Het wordt al tien jaar gebruikt.",
        "AnswerLegalParagraph2": "Een stuk papier ondertekenen met een pen is geen bijzonder veilige manier van ondertekenen. De wet staat aan onze kant; kijk maar naar de",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "en",
        "Acts": "wetten.",
        "TitleLongAnswer": "Lang antwoord:",
        "LegalLongAnswerParagraph1": "Weet je, het ondertekenen van een stuk papier is geen gegarandeerde manier om een overeenkomst te sluiten.\nHandgeschreven handtekeningen kunnen worden vervalst, betwist en zelfs kwijtraken. Sterker nog, als je nog steeds\naan het krabbelen bent, ben je eigenlijk nergens zeker van.",
        "LegalLongAnswerParagraph2": "Bovendien geeft de EU de vrijheid om contracten te personaliseren, zodat je kunt kiezen hoe je overeenkomsten worden bevestigd. Controleer of jouw bedrijf geen speciale vereisten heeft (bijv.\nwitwaswet) en ga dan gerust aan de slag met eSign.",
        "LegalLongAnswerParagraph3": "Authenticatie is als een verzekering. Heb je bijvoorbeeld je legitimatiebewijs laten zien toen je jouw\nhuidige arbeidsovereenkomst tekende? Sommige overeenkomsten hebben een laag risico en vereisen daarom slechts een lichte verzekering; in\ndit geval is e-mailauthenticatie voldoende. ",
        "LegalLongAnswerParagraph4": "SignHero wordt momenteel gebruikt voor: commerciële contracten/arbeidsovereenkomsten/ NDA's/vergadernotulen\n/huurovereenkomsten",
        "LegalLongAnswerParagraph5": "Handtekeningen die zijn gebaseerd op SignHero-standaarden gebaseerde voldoen aan UETA, de Esign Act en \n"
                                     + "het eIDAS-type elektronische handtekening.",
        "DoubtsPrice": "Hoe werkt het? Slechts € 5,-?",
        "PriceShortAnswerParagraph1": "Je kunt documenten met een paar klikken laten ondertekenen en archiveren. Uitnodigingen voor ondertekening worden per e-mail verzonden en voor ondertekening is geen account vereist. De prijs is € 5,- per gebruiker per maand. En het wordt maandelijks automatisch gefactureerd zodra je jouw creditcardgegevens hebt ingevuld. Je kunt op ieder moment opzeggen, dus je betaalt per maand.",
        "PriceLongAnswerParagraph1": "<span class='doubts-break1'>Met SignHero kun je pdf-documenten ondertekenen met computers en mobiele apparaten, en het kan worden geïntegreerd in elk CRM-, digitaal archief- of ander documentbeheersysteem met onze openbare API. Je kunt zelfs rechtstreeks ondertekenen in je Gmail-account met de SignHero-plug-in zonder je inbox te verlaten.</span>",
        "PriceLongAnswerParagraph2": "Je kunt vrijblijvend een account aanmaken en gebruikmaken van een gratis proefperiode van 14 dagen. Er zijn geen creditcardgegevens nodig bij het aanmelden. De gratis proefperiode bevat alle functies, zodat je het goed kunt uitproberen. En maak je geen zorgen, we spammen nooit en sturen geen nieuwsbrieven.",
        "PriceLongAnswerParagraph3": "De prijs is € 5,- per gebruiker per maand. Als betalende gebruiker kun je ondertekeningsverzoeken verzenden, ondertekeningsgebeurtenissen volgen, je documenten opslaan in het SignHero-archief en als een team werken aan een organisatieaccount. Je kunt bijna een oneindig aantal teamleden toevoegen aan het account en bij elke nieuwe gebruiker wordt er € 5,- toegevoegd aan het abonnement.",
        "PriceReasons1": "Op een gegeven moment moet SignHero op eigen benen kunnen staan en zich losmaken van zijn liefdevolle moederbedrijf (Avaintec).",
        "PriceReasons2": "We kunnen niet groeien en een betere ervaring creëren voor onze gebruikers die houden van gemak, tenzij we wat geld krijgen om onze rekeningen te betalen.",
        "PriceReasons3": "Elke week benadert iemand ons die vraagt hoe we de gegevens misbruiken en onze klanten oplichten met een briljant vermomde gratis service. Nou, dat is verleden tijd. We zijn er absoluut zeker van dat je, zodra je € 5,- per maand betaalt meer vertrouwen in ons zult hebben. Want waarom zou je een kapitalist niet vertrouwen?",
        "DoubtsSaveTime": "Wat kan ik doen met alle tijd die ik bespaar?",
        "SaveTimeAnswer": "Drink wat koffie, ga vissen, of toch niet, doe wat werk, bel je moeder, doe je\nbelastingaangifte, log je uren, recycle, red de wereld, speel met je hond, speel met de hond van\neen vriend, leer een instrument spelen, schrijf een gedicht, bak een taart, eet een taart, ga naar de kapper, doe\nradslagen in de tuin, reis de wereld rond, lees een boek, bepaal je eigen lot, creëer\neen religie, ontkracht een mythe, werk voor jezelf, ga naar een startup-evenement, zamel geld in, faal, doe een dutje,\nwees lui, en doe het dan allemaal nog een keer.",
        "TitleThisIsUs": "Dit zijn wij",
        "LinkedIn": "SignHero LinkedIn",
        "Youtube": "SignHero YouTube",
        "History": "Geschiedenis",
        "CompanyName": "Avaintec",
        "CompanyAddress": "Kansakoulukuja 3, 00100 Helsinki",
        "CompanyWeb": "www.avaintec.com",
        "TitleMoreQuestion": "Nog meer vragen?",
        "SignHeroEmail": "hello@signhero.io",
        "ApiDocsLink": "API-documenten",
        "TermOfUseLink": "Gebruiksvoorwaarden",
        "PrivacyPolicyLink": "Privacybeleid",
        "CopyrightYear": "© " + new Date().getFullYear() + " ",
        "CompanyOyName": "Avaintec Oy",
        "RegulationNotice": "Elektronische handtekeningen hebben dezelfde juridische status als handgeschreven handtekeningen in de VS en de EU dankzij de",
        "ESignAct": "ESIGN Act",
        "eIDASRegulations": "eIDAS-voorschriften",
        "Docs": "Documentatie",
        "SignIn": "Inloggen",
        "TermsOfUse": "Gebruiksvoorwaarden",
        "ThisLocale": "NL",
        "WelcomeToSignHero": "Welkom bij SignHero. Voer je e-mailadres in en klik op \"Doorgaan.\". Je ontvangt een e-mail waarmee je voor de eerste keer je wachtwoord kunt instellen en kunt aanmelden.",
        "ConfirmAccountInfo": "Bekijk de e-mail en volg de instructies om je wachtwoord in te stellen en aan te melden.",
        "MustBeEmail": "Voer een e-mailadres in.",
        "MustBeValidFullName": "Voer een geldige naam en achternaam in.",
        "MustBeValidSignatureInvitation": "Hyperlinks zijn niet toegestaan.",
        "MustBeUniqueEmail": "Deze e-mail is al toegevoegd.",
        "EnterValidCharacters": "Voer alleen ondersteunde tekens in.",
        "UserSignedUp": "Welkom bij SignHero! We hebben je een e-mail gestuurd met instructies voor het activeren van je account. Als je al een account hebt, kun je het wachtwoord resetten via het scherm \"Inloggen\".",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "SessionType!password": "Wachtwoord",
        "SessionType!auth_code": "Applicatie",
        "YouHaveBeenSignedOut": "Je bent afgemeld.",
        "Error!UserExists": "Er bestaat al een gebruiker met het e-mailadres of de gebruikersnaam die je hebt opgegeven. Als je het wachtwoord bent vergeten, klik je op \"Aanmelden\" en wijzig je deze via de link \"Wachtwoord resetten\".",
        "Save": "Opslaan",
        "Error!Unexpected": "Er is een onverwachte fout opgetreden. Probeer het later opnieuw.",
        "Create": "Aanmaken",
        "Complete": "Archief",
        "Sign": "Ondertekenen",
        "Welcome": "Welkom",
        "CompleteProcessInfo": "Je kunt het/de document(en) niet ondertekenen omdat dit ondertekeningsverzoek is afgesloten. Mogelijk heb je deze documenten al ondertekend of is deze door de afzender geannuleerd.",
        "SignInHere": "Inloggen",
        "ReasonTitle": "WAARSCHIJNLIJKE REDENEN",
        "Reason1": "Je hebt dit document al ondertekend",
        "Reason2": "Een van de ondertekenaars heeft geweigerd te ondertekenen",
        "Reason3": "De uitnodiging is geannuleerd door de afzender",
        "Reason4": "De uitnodiging is na een jaar verlopen",
        "Reason5": "Je hebt een verkeerd adres ingevoerd",
        "Reason6": "Je hebt een herinnering ontvangen en de oude link is automatisch verlopen",
        "SeeYouSoon" : "Tot ziens",
        "HowToOpenExpiredSession" : "Klik op de link in je e-mail om de sessie opnieuw te openen.",
        "ClearCookieWarning": "Als je een openbaar of gedeeld apparaat gebruikt, vergeet dan niet de cookies, geschiedenis en cache te wissen.",
        "JustASec": "Even geduld",
        "SorryUnexpectedError": "Sorry, pagina niet gevonden!",
        "InvalidLoginUrl": "De link die je hebt gebruikt werkt niet of de uitnodiging is verwijderd.",
        "InvitationInvalidatedReason": "De link die je hebt gebruikt werkt niet of de uitnodiging is verwijderd.",
        "You": "jij",
        "DownloadPageTitle": "Ondertekende documenten bekijken en downloaden",
        "DownloadLinkInfo" : "Deze link blijft een jaar actief vanaf de voltooiing van het ondertekeningsproces.",
        "Download": "Downloaden",
        "OpenAuditTrail": "Audittrail bekijken",
        "ProcessNotFound": "Het ondertekeningsproces is niet gevonden. Controleer het ondertekeningsproces-ID.",
        "SignatureEvents": "Handtekeningen",
        "ProcessStatus!COMPLETED": "Voltooid",
        "ProcessStatus!CANCELED": "Geweigerd",
        "ProcessStatus!PENDING": "In afwachting",
        "ProcessStatus!UNKNOWN": "Onbekend",
        "SignatureProcessId": "ID",
        "SignatureProcess": "Ondertekeningsproces",
        "SignatureFlowCreated": "Aangemaakt",
        "SignatureFlowStatus": "Status",
        "NumberOfDocuments": "Documenten",
        "SignedBy": "Ondertekend door",
        "SignTime": "Tijd van handtekening",
        "AuthType": "Authenticatietype",
        "AuthMethod!verification_code": "Emailverificatie",
        "AuthMethod!access_token": "SignHero-account",
        "AuthMethod!strong_auth": "Sterk",
        "AuthMethod!otp_token": "Derde partij",
        "AuthMethod!otac": "E-mail",
        "AuthMethod!signer_token": "E-mail",
        "AuthMethod!on_site": "Persoonlijk",
        "PrivacyPolicy": "Privacybeleid",
        "EnterYourDetailsBelow": "Vul hieronder je gegevens in.",
        "ThirdParty": "Derde partij",
        "SignUpButtonLabel": "Doorgaan",
        "NameAndSurname": "Je naam en achternaam",
        "Email": "E-mail",
        "Company": "Organisatie",
        "ConfirmAccount": "Doorgaan",
        "Confirm": "Bevestigen",
        "ConfirmAccountTitle": "Wachtwoord instellen voor je <span class='elem-pink'>SignHero</span>-account",
        "ResetPasswordTitle": "Wachtwoord resetten voor je <span class='elem-pink'>SignHero</span>-account",
        "ResetPassword": "Nieuw wachtwoord instellen",
        "Reset": "Resetten",
        "Organization": "Organisatie",
        "WelcomeMessage": "Inloggen op je <span class='elem-pink'>SignHero</span>-account",
        "WelcomeText": "Vul hieronder je gegevens in.",
        "DontHaveSignHeroAccount": "Heb je geen <span class='elem-pink'>SignHero</span>-account?",
        "GetStarted": "Aan de slag gaan",
        "AlreadyHaveSignHeroAccount": "Heb je al een <span class='elem-pink'>SignHero</span>-account?",
        "CreateYourSignHeroAccount": "Maak je <span class='elem-pink'>SignHero</span>-account aan",
        "AcceptTermsOfUseAndPrivacyPolicy": "Door op Doorgaan te klikken, accepteer je de <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>gebruiksvoorwaarden</a> en het <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>privacybeleid</a> van SignHero.",
        "YouFoundApi": "Je hebt SignHero API gevonden!",
        "CheckApiDocumentation": "Bekijk onze nieuwste <a class=='elem-pink' href='${apiDocsLink}'>API-documentatie</a> om aan de slag te gaan.",
        "AuditTrail" : "Audittrail",
        "EnterProcessId" : "Voer het ondertekeningsproces-ID in, weergegeven in het handtekeningvak in elk document:",
        "SubmitID" : "Indienen",
        "NoProcessId" : "Voer het proces-ID in.",
        "ConfirmError1" : "Vraag een nieuwe uitnodiging of een wachtwoordherstellink aan op de ",
        "ConfirmError2" : " pagina.",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "Deze link is mogelijk verlopen.<br>Ga naar <a href='/#/signin' class='elem-pink'>de inlogpagina</a> om je wachtwoord te resetten.",
        "Error!tax_id_invalid" : "Ongeldig btw-nummer.",
        "CreditCardSaved" : "Creditcard is toegevoegd.",
        "BillingInformationSaved" : "Facturatiegegevens opgeslagen.",
        "dateFormat" : "dd mm, jjjj",
        "timeFormat" : "dd.mm, UU:mm",
        "Error!card_declined" : "Creditcard is geweigerd door betalingsgateway.",
        "Error!incorrect_cvc" : "Creditcard is geweigerd door betalingsgateway.",
        "Error!expired_card" : "Creditcard is geweigerd door betalingsgateway.",
        "Error!processing_error" : "Creditcard is geweigerd door betalingsgateway.",
        "Contact" :  "Contact",
        "name" : "Naam",
        "email" : "E-mail",
        "LoginEmail" : "E-mail",
        "EnterYourEmail" : "Vul je e-mailadres in:",
        "Password" : "Wachtwoord",
        "ForgotYourPassword" : "Wachtwoord vergeten?",
        "RequestConfirmToken" : "Probeer opnieuw te bevestigen",
        "HelloThere" : "Hallo,",
        "ChangePassword" : "Wachtwoord wijzigen",
        "CurrentPassword" : "Huidig wachtwoord",
        "NewPassword" : "Nieuw wachtwoord",
        "RepeatPassword" : "Herhaal wachtwoord",
        "PasswordSet" : "Wachtwoord ingesteld.",
        "MessageSent" : "Bericht verzonden. Indien nodig nemen wij contact met je op.",
        "SignHeroSentPasswordResetRequest" : "Verzoek om wachtwoord te resetten verzonden als je een geregistreerde gebruiker bent. Check je e-mail.",
        "SignHeroSentConfirmTokenRequest" : "Nieuwe accountbevestiging aangevraagd. Check je e-mail.",
        "AuthCodeCreated" : "We hebben een link naar je gemaild. Volg deze om aan te melden.",
        "SentPasswordResetRequest" : "Verzoek om wachtwoord te resetten verzonden als je een geregistreerde gebruiker bent. Let op je e-mail!",
        "SentConfirmTokenRequest" : "Nieuwe accountbevestiging aangevraagd. Let op je e-mail!",
        "DefaultDialogTitle" : "",
        "Action!SetPassword" : "Wachtwoord instellen",
        "Action!ConfirmAccount" : "Account bevestigen",
        "TooltipSetPassword" : "Onjuiste e-mail of wachtwoorden komen niet overeen.",
        "Action!EditProfile" : "Bewerken",
        "Action!SignOut" : "Afmelden",
        "Action!SignIn" : "Aanmelden",
        "Update" : "Bijwerken",
        "Delete" : "Verwijderen",
        "Cancel" : "Annuleren",
        "Accept" : "Accepteren",
        "Submit" : "Indienen",
        "Error!PasswordChangeFailedInvalidPassword" : "Je oude wachtwoord kwam niet overeen. Probeer het opnieuw.",
        "Error!InvalidEmail" : "Vul je e-mailadres in.",
        "Error!InvalidFullName" : "Vul een geldige naam en achternaam in",
        "Error!InvalidPasswordReset" : "Het token voor het resetten van je wachtwoord was ongeldig of verlopen. Vraag een nieuwe aan via de link \"Wachtwoord resetten\" en probeer het opnieuw.",
        "Error!InvalidCredentials" : "Ongeldig e-mailadres of wachtwoord.",
        "Error!InvalidScopes" : "Je toegangsrechten staan het gebruik van deze applicatie niet toe. Neem contact op met de administratie.",
        "Error!TemporarilyLockedOut" : "Aanmelden is tijdelijk geblokkeerd vanaf dit apparaat vanwege herhaalde fouten. Als je je wachtwoord bent vergeten, kun je deze resetten via de link \"Wachtwoord resetten\". Inloggen wordt over ${minutes} minuten gedeblokkeerd.",
        "Error!TokenCheckFailed" : "Deze link is mogelijk verlopen.<br>Ga naar de inlogpagina om je wachtwoord te resetten.",
        "Error!too_many_incorrect_email": "Te veel onjuiste e-mailadressen, probeer het morgen opnieuw.",
        "PasswordRequirement" : "Het wachtwoord moet minimaal 10 tekens lang zijn en een cijfer, een hoofdletter, een kleine letter en een speciaal teken bevatten. Je kunt ook een minimum van 15 tekens gebruiken. Gebruik niet meer dan twee opeenvolgende identieke tekens.",
        "EmailRequired" : "E-mailadres is vereist",
        "EmailMustValid" : "E-mail moet geldig zijn",
        "PasswordMustValid" : "Wachtwoord moet voldoen aan complexiteitseisen",
        "RepeatPasswordMustSame" : "De wachtwoorden moeten identiek zijn",
        "TitleNews" : "Nieuws",
        "ReadMoreLink" : "Meer lezen",
        "OtherNewsBtn" : "Ander nieuws",
        "NewsListBg1" : "Waar we ons mee bezig hebben gehouden",
        "NewsListBg2" : "Lees nieuws over onze prestaties, releases en vermeldingen in de media.",
        "Share" : "Delen",
        "Locales": "Taal",
    },
    "client": {
        "ClientSecretInfo": "Sla het clientgeheim veilig op. Je kunt deze wijzigen, maar je kunt hem niet meer zien nadat je deze sessie hebt beëindigd.",
        "Error!InvalidDomain": "Ongeldige domeinnaam.",
        "DomainValidationDownloadInfo1": "Om je domein te verifiëren:",
        "DomainValidationDownloadInfo2": "Download het volgende bestand:",
        "DomainValidationDownloadInfo3": "Upload het bestand naar de hoofdmap van je domein:",
        "DomainValidationDownloadInfo4": "Controleer of het bestand toegankelijk is via de link:",
        "DomainValidationDownloadInfo5": "Ga terug naar deze pagina en klik op \"Domein verifiëren\"",
        "LastClientSecretReset": "Laatst gewijzigd",
        "FailedDomainValidation": "Validatie van het domein is mislukt. Heb je de instructies gevolgd?",
        "OAuthApplications": "OAuth2-applicaties",
        "Application": "OAuth2-applicatie",
        "IntegrateWithOAuth": "Integreren met OAuth2",
        "NewOAuthApplication": "Nieuwe OAuth2-applicatie",
        "ApplicationName": "Naam van de applicatie*",
        "AddOAuthApplication": "Applicatie toevoegen",
        "AddUri": "Meer URI's toevoegen",
        "CreationDate": "Aanmaakdatum",
        "CreatedBy": "Aangemaakt door",
        "AllowedGrantTypes": "Toegestane subsidiesoorten",
        "OAuthRedirectURIs": "Omleidings-URI's *",
        "OAuthClientsDomainURI": "Domein",
        "OAuthClientsDomainTitle": "Applicatiedomein *",
        "AppDescription": "Applicatiebeschrijving",
        "VerifyDomain": "Domein verifiëren",
        "DomainVerified": "Het domein is geverifieerd.",
        "WhyValidateDomain": "Je moet het eigendom van je domein verifiëren. De applicatie kan niet worden gebruikt voordat de domeinverificatie is geslaagd. Raadpleeg de <a class='paragraph-links' href='/docs/api' target='_blank'>API-documentatie</a> voor details.",
        "ClientSecret": "Clientgeheim",
        "ClientId": "Client-ID",
        "ClientCredentials": "Clientreferenties",
        "VerifiedApp": "Actief",
        "UnverifiedApp": "Inactief",
        "ResetClientSecret": "Geheim wijzigen",
        "ConfirmDeleteApiClient": "Weet je zeker dat je deze app wilt verwijderen?\nVoer de code in om door te gaan: ${rndCode}",
        "ConfirmResetClientSecret": "Weet je zeker dat je het clientgeheim wilt resetten?\nVoer de code in om door te gaan: ${rndCode}",
        "AppHasBeenDeleted": "De toepassing ${appName} is verwijderd.",
        "AppHasBeenCreated": "De toepassing ${appName} is aangemaakt.",
        "YourChangesHaveBeenSaved": "De veranderingen zijn bewaard.",
        "ClientSecretChanged": "Clientgeheim gewijzigd.",
        "CheckApiDocs": "Je moet het eigendom van je domein verifiëren voordat de applicatie kan worden gebruikt. Raadpleeg de <a href='/docs/api' target='_blank'>API-documentatie</a> voor details."
    },
    "oauth2consent": {
        "AllowAccess": "Toegang verlenen",
        "OAuthAccessDescriptionHeader": "<span class='elem-pink'>${appName}</span> heeft jouw autorisatie nodig voordat je het kunt gebruiken om:",
        "OAuthBasicPermissions": "  <li>Nieuwe ondertekeningsuitnodigingen en ondertekeningsherinneringen te sturen</li>" +
                                 "  <li>Alle ondertekeningsuitnodigingen die ermee zijn verzonden bekijken, annuleren en verwijderen</li>" +
                                 "  <li>Bekijk ondertekende documenten die ermee voor ondertekening zijn verstuurd</li>",
        "OAuthSigningUrlPermissions": " <li>Documenten te ondertekenen die ermee zijn geüpload</li>",
        "OAuthProfilePermissions": " <li>Bekijk je naam, e-mail, bedrijfsnaam, organisatienaam, geselecteerde " +
                                    "communicatietaal en zie of je beheerdersrechten hebt</li>",
        "OAuthAllDataPermissions": " <li>Alle ondertekeningsuitnodigingen te bekijken, annuleren en verwijderen</li>" +
                                   " <li>Bekijk al je documenten</li>",
        "OAuthAccessWarning": "Dit betekent ook dat je akkoord gaat met de servicevoorwaarden en het privacybeleid van ${appName}.",
        "QuestionToAllowAccess": "Geef <span class='elem-pink'>${appName}</span> toegang tot jouw SignHero-account " +
                                 "<span class='elem-bold'>${userEmail}</span>?<br><br>Je kunt altijd je keuzes " +
                                 "veranderen in de instellingen van je SignHero-account.",
        "Cancel": "Annuleren",
        "Allow": "Toestaan",
        "NotFound": "Sorry, app niet gevonden",
        "OAuthResourceNotFound": "Helaas moet het probleem worden opgelost in de applicatie die je hierheen heeft geleid."
    }
}
