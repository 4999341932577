export default {
    "pdfsign": {
        "Error!Unexpected": "An unexpected error occurred. Try again later.",
        "SelectSigner": "Choose signer",
        "SignatureTimestampFormat": "Signed on dd.mm.yyyy",
        "SignatureTypeFormat": "Type: {Signature-type-xxxxxxxx}",
        "SignatureAdvancedLevelFormat": "Advanced Electronic Signature",
        "DeclinationPlaceHolder": "Reasons for declining (optional)",
        "SignerGreetings": "Hello ",
        "WhoSigns": "Who signs:",
        "Sign": "Sign",
        "WhatSigned": "What’s signed: <span class='sh-nowOrNeverText'>(click the doc to review it – it’s now or never!)</span>",
        "SignConfirmationMsg": "I have reviewed the document(s) and have a legal right to sign.",
        "UsagePolicyInfoMsg": "By using SignHero, you accept our <a href='terms-of-use.html' target='_blank' style='color: #ff507a;'>Terms of Use</a> and <a href='privacy-policy.html' target='_blank' style='color: #ff507a;'>Privacy Policy</a>.",
        "Decline": "Decline",
        "Cancel": "Cancel",
        "ThankUsingService": "Thanks anyway!",
        "FindMoreInformation": "Choose easy at <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "Signing process has been cancelled",
        "CompleteProcessInfo": "You cannot sign the document(s) because this signature request has been closed. You may have already signed these documents or it was canceled by the sender.",
        "DeclineToSignTitle": "Decline to sign",
        "SignedAndSent": "Document(s) are successfully signed.",
        "SorryUnexpectedError": "Sorry, something went wrong",
        "SessionNeedRefreshError": "Your session needs to be refreshed. Please try again once more.",
        "ReasonExpiredSession": "The link you followed might be broken, or the invitation has been removed.",
        "InvalidLoginUrl": "Please, verify the entered URL matches the link in your email and try again.",
        "You": "you",
        "AdvancedSign": "Sign",
        "chooseMethodDialogTitle": "Review all documents carefully before signing"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "locale!th" : "ภาษาไทย",
        "Applications": "Applications",
        "SearchUsers": "Search for users",
        "UserDeleted" : "The user has been disabled and will be permanently deleted after 30 days",
        "UserRestored" : "The user has been restored",
        "UserAlreadyActive" : "The user is already active.",
        "OrganizationHasBeenDeleted": "An administrator has just deleted your organisation.\nWe're sorry to see you go, and hope you'll be back.",
        "SendReminder": "Send activation reminder",
        "EmailReminderSuccess": "Email reminder sent.",
        "AdminStatusChanged" : "User updated.",
        "DateTimeFormat" : "dd.MM.yyyy, HH:mm:ss",
        "SessionType!password" : "Password",
        "SessionType!auth_code" : "Application",
        "Start" : "Start new",
        "TheService" : "Administration",
        "Save" : "Save",
        "MyOrganization" : "My organisation",
        "OrganizationName" : "Organisation name",
        "ManageAccounts" : "Manage accounts",
        "ManageSignatureTypes" : "Manage signature types",
        "ManageSignatureTypesHelp" : "Select the signature types your organisation can use. One must be default.",
        "MyIntegrations" : "Integrations",
        "Statistics" : "Statistics",
        "Subscription" : "Subscription",
        "isAdmin" : "Admin",
        "DeleteUser" : "Delete user account",
        "RestoreAccount" : "Restore user account",
        "Error!Unexpected" : "An unexpected error occurred. Try again later.",
        "Error!InvitedUserExists" : "The user has already joined this organisation or belongs to another organisation. In the latter case, please contact ${email}",
        "InviteUserHeader" : "Invite new user to your organisation",
        "InviteUser" : "Invite",
        "UserInvited" : "User invited",
        "AccountStatus" : "Account status:",
        "OwnAccount" : "Your own account",
        "StatusActive" : "Active, user has accepted the invitation.",
        "StatusInactive" : "Inactive, user hasn't accepted the invitation yet.",
        "StatusDeleted" : "Marked for deletion. Subscription is inactive, it won't renew for this user.",
        "AccountRights" : "Rights:",
        "Create": "Create",
        "ConfirmDeleteEverything": "Are you sure you want to delete this organisation account (${groupTitle})?\n",
        "ConfirmCancelOngoingProcesses" : "Your organisation has ${processNum} ongoing signing processes. They need to be cancelled also before proceeding with organisation account deletion.\n "
                                          + "\nAre you sure you want to cancel all ongoing processes and delete this organisation account (${groupTitle})?\n",
        "IncorrectConfirmationCode" : "Incorrect confirmation code.",
        "DownloadOrganizationDataMessage": "Download user and signing process data as a JSON-file.",
        "DownloadOrganizationDataLink": "Download organisation data",
        "DownloadOrganizationData": "Download JSON",
        "DeleteOrganizationMessage": "Delete this organisation, your account, and all other admin and user accounts",
        "DeleteEverything": "Delete everything",
        "OrganizationHasActiveProcesses" : "Could not delete the organisation because it has active signature processes or merge requests.",
        "MergeOrganizationMessage": "Organisation can request to merge with another one.",
        "RequestMergeDetails": "The organisation's admin can request to merge the organisation with another one. The request will be valid for 24 hours.\n\n"
            + "After request acceptance, signing processes including documents and users will be migrated to the new organisation, and this organisation will disappear. "
            + "Users of this organisation won’t have any admin rights after the merger. The rights have to be added by the new organisation’s admin. If there was "
            + "an active subscription, the remaining subscription period will be cancelled, and merged users will be counted to the new organisation's subscription.",
        "MergeOrganizationDetails": "The organisation ${groupName} has requested to merge the organisation account "
            + "to your organisation account. The request is valid for 24 hours.\n\n"
            + "After request acceptance, signing processes including documents and users will be migrated to your organisation, "
            + "and the migrated organisation will disappear. Users of the migrated organisation won’t have any admin rights after the merger. "
            + "The rights have to be added by your organisation’s admin. If there was an active subscription, "
            + "the migrated users will be counted to your organisation's subscription.",
        "RequestMerge": "Request merge",
        "ConfirmMergeOrganisation": "Are you sure you want to merge this organisation account? Splitting accounts again is not supported.",
        "EnterAdminEmail": "Enter an admin's email address of the other organisation:",
        "EnterPassword": "To continue, please enter your password:",
        "InvalidPassword" : "Your password did not match. Please try again.",
        "MergeRequestSent": "Organisation merge request sent",
        "MergeRequestCanceled": "Organisation merge request cancelled",
        "MergeRequestRejected": "Organisation merge request rejected",
        "ReceivedMergeRequests": "Received merge requests",
        "SentMergeRequest": "Sent merge request",
        "Recipient": "Recipient",
        "Reject": "Reject",
        "Merge": "Merge",
        "OrganisationMerged": "Merge started",
        "UserNotDifferentOrganizationAdmin": "User is not admin in different organisation",
        "UserNotFound": "Sorry, user not found!",
        "OrganizationHasActiveMergeRequest": "Organisation has already active merge request",
        "InvalidMergeRequest": "Merge request was not found or it has expired",
        "Complete": "Archive",
        "Locales": "Language",
        "Sessions": "Sessions",
        "LogOutAllDevicesMessage": "If you want to, you can log out from all your sessions on all devices.",
        "NoApplicationsMessage": "You haven't granted access to any application yet.",
        "Domain" : "Domain",
        "AccessGiven" : "Access given on",
        "LastAccess" : "Last access",
        "RemoveAccess": "Remove access",
        "Ongoing": "Ongoing",
        "MyAccount": "Account",
        "UserName": "Name",
        "Email": "Email",
        "Logout": "Log out",
        "SignOut": "Log out from all sessions",
        "Confirm": "Confirm",
        "Cancel": "Cancel",
        "Reset": "Reset",
        "Profile": "My profile",
        "Organization": "Organisation",
        "Device": "Device",
        "Update" : "Save",
        "Delete" : "Delete",
        "AcceptApiTerms" : "By using SignHero API, you accept our <a href='/terms/api' target='_blank'>API terms</a>.",
        "CannotDeleteActiveFlows" : "Your organisation has ongoing signing processes. You need to cancel them first before proceeding with organisation account deletion.",
        "MySubscription" : "My subscription",
        "FreeTrialLeft" : "Time left on free trial: ",
        "Days" : "Days.",
        "SubscriptionPeriod" : "Current subscription period:",
        "PleaseActivateSubscription" : "Please activate your subscription.",
        "Tab1" : "Payment Info",
        "Tab2" : "Billing History",
        "Tab3" : "About",
        "NoPaymentNotification" : "Note: You don't have a valid payment method now.",
        "AddCreditCard" : "Add a credit card for SignHero monthly billing",
        "CreditCardEmail" : "Email",
        "BillingDate" : "Billing date",
        "Amount" : "Amount",
        "UsersAmount" : "Users",
        "Duration" : "Duration",
        "ReferNumber" : "Reference number",
        "Invoice" : "Invoice",
        "Action" : "Action",
        "LoadMore" : "Load more",
        "SalesChannel" : "Sales channel",
        "ResellerName" : "Reseller: ",
        "ResellerContact" : "Contact: ",
        "ResellerMaxUsers" : "Max users: ",
        "ResellerCurrentInvitedUsers" : "Current invited users: ",
        "MaxResellerUsersWarning" : "Your number of users has reached the maximum limit set by  your reseller (${resellerMaxUsers}).",
        "StartOfSubscription" : "Start date of subscription:",
        "EstimatedBilling" : "Next invoice on ${nextBillingDate} for ${nextBillingValue}€",
        "SubInactive1" : "Subscription is inactive",
        "SubInactive2" : ", it won’t renew for this deleted user",
        "isSubscriptionAdmin" : "Subscription Admin",
        "CardInfo" : "Card information",
        "NameOnCard" : "Name on card",
        "SelectCountry" : "Country or region",
        "SelectLocale" : "Select a language",
        "BusinessAccount" : "Business account",
        "OnlyBusinessAccountsSupported" : "Only business accounts are supported for the selected country.",
        "TaxIdExplanation" : "Please choose your tax region and input your business tax ID for VAT.",
        "SelectIdType" : "Select tax ID type",
        "TaxId" : "Tax ID",
        "AddCardTooltip1" : "Please add valid billing information first.",
        "AddCardTooltip2" : "Please add valid credit card information. All fields are mandatory.",
        "AddCardTooltip3" : "Credit card information is valid.",
        "BillingInformation" : "Billing Information",
        "Edit" : "Edit",
        "EditBillingInformation" : "Edit billing information.",
        "AddBillingTooltip" : "Please fill in all the mandatory fields and check that Tax ID matches Country information.",
        "Street" : "Address",
        "Street2" : "Address2",
        "City" : "City",
        "State" : "State",
        "Zip" : "ZIP/Postcode",
        "SaveBillingInfo" : "Save billing info",
        "SaveCard" : "Save card info",
        "Expires" : "Valid thru",
        "DeleteCardOnly" : "Delete credit card",
        "DeletePaymentInfo" : "Delete the billing information and credit card information.",
        "RemoveWholePaymentMethod" : "Delete payment info",
        "PayOnline" : "Confirm payment",
        "PaymentStatusPaid" : "Paid",
        "PaymentStatusPending" : "Pending",
        "PaymentInfo" : "About",
        "PaymentExplanation1" : "SignHero costs 5€ per month per active user (Price does not include value-added tax/sales tax). The value-added tax/sales tax at the time will be added to the price. Inactive users do not count towards your bill. If you add or remove users during a billing cycle, we will automatically calculate how much less or more the next bill should be based on how much of the billing cycle is left when the change was made. When you sign up we give you a 14 day free trial, after which you are required to register a credit card for monthly recurring payments if you want to keep using the service. Your credit card will be automatically charged every month according to how many users you have registered in the service. You will receive a receipt of your monthly payment to the email address you specified when registering your credit card.",
        "PaymentExplanation2" : "If you use SignHero as a business and not a private person, you must input your business' VAT number as SignHero will collect VAT from customers according to Finnish and EU tax legislation. For companies within the EU a valid VAT Tax ID needs to be provided. If your country is not listed in the country or region selection, you can contact us for alternative payment methods.",
        "PaymentExplanation3" : "If you do not have the possibility to add a credit card for automatic billing, please contact SignHero at <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "PaymentExplanation4" : "Have a problem with your invoice? Questions about how everything works? Please contact us at <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a> for any questions.",
        "CountersPending" : "The statistics are being calculated. Please try again after few minutes.",
        "WholeOrganisation" : "Whole organisation",
        "OrganisationHas" : "Your organisation currently has",
        "ActiveUsers" : "active users and",
        "InactiveUsers" : "inactive users.",
        "ChartDescriptionOrg" : "The below chart shows created signature processes and documents of the whole organisation.",
        "ChartDescriptionUser" : "The below chart shows created signature processes of the selected users.",
        "SignaturesCreated":"signatures in total",
        "AllSignatureHas":"In the selected period, the amount of signatures is ",
        "AdvancedSignatureHas":"from which the number of advanced signatures is ",
        "AllSignature" : "All signatures",
        "ChartDescriptionSignature" : "The below chart shows signatures created in the whole organisation.",
        "SelectSignatureTypes" : " Select signature types",
        "AllSignatureInPeriod" : " All signatures in period",
        "SelectUsers" : "Select users",
        "From" : "From",
        "To" : "To",
        "AmountOfDocuments" : "In the selected period, the amount of new documents is",
        "AmountOfSignatureProcesses" : "and the amount of new signature processes is",
        "Processes" : "Processes",
        "Documents" : "Documents",
        "Users" : "Users",
        "UserActivity" : "User activity",
        "Signatures" : "Signatures",
        "User" : "User",
        "Inactive" : "Inactive",
        "SignatureProcesses" : "Signature processes in period",
        "AllSignatureProcesses" : "All signature processes",
        "DownloadSignatureStatsMessage": "Download signature statistics as a CSV-file.",
        "DownloadSignatureStatsLink": "Download signature statistics",
        "FreeTrialAlert1" : "Your organisation has",
        "FreeTrialAlert2" : "days free trial left!",
        "ContactAdmin" : "No active subscription, please contact your administrator!",
        "PaymentOverdueAlert" : "Payment overdue, please add a valid card",
        "PaymentOverdueAlertIgnore" : "If you have already added a valid card, please ignore this message",
        "PleaseAddValidCPayment" : "No active subscription, please add a valid payment method!",
        "PleaseConfirmPayment" : "Card authentication required. Please check \"Billing History\"!",
        "PleaseConfirmPaymentMainPage" : "No valid payment method, or card authentication required. Please check \"My subscription\"!",
        "ConfirmDeletePaymentMethod": "Are you sure you want to delete billing and credit card information?",
        "ConsentDialogTitle": "Subscribe to SignHero with recurring payments?",
        "ConsentText1": "SignHero costs 5€ per month per active user + VAT. Inactive users do not count towards your invoice. If you add or remove users during a billing cycle, we will automatically calculate how much less or more the next invoice should be based on how much of the billing cycle is left when the change was made. When you sign up, we give you a 14-day free trial after which you are required to register a credit card for monthly recurring payments if you want to keep using the service. Your credit card will be automatically charged on the same date of every month according to how many users you have registered in the service. After registering your credit card, your subscription will be valid after a successful payment and remain valid unless payment failed.",
        "ConsentText2": "Concerning your SignHero subscription, you will receive these emails:",
        "ConsentText3": "If you use SignHero as a business and not as a private person, you must input your business' VAT number as SignHero will collect VAT from customers according to Finnish and EU tax legislation. For companies within the EU a valid VAT Tax ID needs to be provided. If your country is not listed in the country or region selection, you can contact us for alternative payment methods.",
        "ConsentText4": "To cancel the subscription, you can remove your credit card information, and the current subscription will be cancelled at the end of the subscription period. You can also do this by pressing the \"Cancel subscription\" button after which you can't use the service anymore.",
        "ConsentEmailBullet1": "a reminder before the end of a trial period",
        "ConsentEmailBullet2": "a receipt of your monthly payment to the email address you specified",
        "ConsentEmailBullet3": "an email notification 7 days before each transaction",
        "ConsentCheckBoxLabel1": "I have read the above information",
        "ConsentCheckBoxLabel2": "I agree to subscribe to SignHero service for recurring payments",
        "Agree": "Agree",
        "CurrentPlan" : "Current subscription",
        "SignHeroSubscription" : "SignHero subscription",
        "CancelPlan" : "Cancel subscription",
        "CreatePlan" : "Create subscription",
        "PriceInfo" : "5€ per month per active user",
        "TaxTooltip" : "Please note the price does not include value-added tax/sales tax.",
        "PleaseAddValidCard" : "Please add a valid payment method",
        "CancelPlanConfirm" : "Are you sure you want to cancel the current subscription?\nYou will lose the remaining trial period or the remaining time on your paid subscription. Remaining time is not compensated.",
        "CreatePlanConfirm" : "Are you sure you want to create a new subscription?",
        "numbersDidNotMatch" : "The codes do not match. Please try again.",
        "toProceedEnterCode" : "To proceed, enter code: ",
        "subStatus!trialing" : "Trial ends ",
        "subStatus!active" : "Active",
        "subStatus!canceled" : "Canceled",
        "subStatus!incomplete" : "Incomplete",
        "subStatus!incomplete_expired" : "Expired",
        "subStatus!past_due" : "Overdue",
        "subStatus!non_existing" : "Invalid",
        "ConfirmCreateSubNow" : "You can create new subscription now or later from ‘My subscription’ page.",
        "CreateSubscriptionNow" : "Create now",
        "CreateSubscriptionLater" : "Later",
        "Country!Australia" : "Australia",
        "Country!Austria" : "Austria",
        "Country!Belgium" : "Belgium",
        "Country!Brazil" : "Brazil",
        "Country!Bulgaria" : "Bulgaria",
        "Country!Canada" : "Canada",
        "Country!Chile" : "Chile",
        "Country!Croatia" : "Croatia",
        "Country!Cyprus" : "Cyprus",
        "Country!CzechRepublic" : "Czech Republic",
        "Country!Denmark" : "Denmark",
        "Country!Estonia" : "Estonia",
        "Country!Finland" : "Finland",
        "Country!France" : "France",
        "Country!Germany" : "Germany",
        "Country!Greece" : "Greece",
        "Country!HongKong" : "Hong Kong",
        "Country!Hungary" : "Hungary",
        "Country!India" : "India",
        "Country!Indonesia" : "Indonesia",
        "Country!Ireland" : "Ireland",
        "Country!Israel" : "Israel",
        "Country!Italy" : "Italy",
        "Country!Japan" : "Japan",
        "Country!Korea" : "Korea",
        "Country!Latvia" : "Latvia",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Lithuania",
        "Country!Luxembourg" : "Luxembourg",
        "Country!Malaysia" : "Malaysia",
        "Country!Malta" : "Malta",
        "Country!Mexico" : "Mexico",
        "Country!Netherlands" : "Netherlands",
        "Country!NewZealand" : "New Zealand",
        "Country!Norway" : "Norway",
        "Country!Poland" : "Poland",
        "Country!Portugal" : "Portugal",
        "Country!Romania" : "Romania",
        "Country!RussianFederation" : "Russian Federation",
        "Country!SaudiArabia" : "Saudi Arabia",
        "Country!Singapore" : "Singapore",
        "Country!Slovakia" : "Slovakia",
        "Country!Slovenia" : "Slovenia",
        "Country!SouthAfrica" : "South Africa",
        "Country!Spain" : "Spain",
        "Country!Sweden" : "Sweden",
        "Country!Switzerland" : "Switzerland",
        "Country!Taiwan" : "Taiwan",
        "Country!Thailand" : "Thailand",
        "Country!UnitedArabEmirates" : "United Arab Emirates",
        "Country!UnitedKingdom" : "United Kingdom",
        "Country!UnitedStates" : "United States",
        "TaxType!au_abn" : "Australian Business Number (AU ABN)",
        "TaxType!au_arn" : "Australian Taxation Office Reference Number",
        "TaxType!eu_vat" : "European VAT number",
        "TaxType!br_cnpj" : "Brazilian CNPJ number",
        "TaxType!br_cpf" : "Brazilian CPF number",
        "TaxType!ca_bn" : "Canadian BN",
        "TaxType!ca_gst_hst" : "Canadian GST/HST number",
        "TaxType!ca_pst_bc" : "Canadian PST number (British Columbia)",
        "TaxType!ca_pst_mb" : "Canadian PST number (Manitoba)",
        "TaxType!ca_pst_sk" : "Canadian PST number (Saskatchewan)",
        "TaxType!ca_qst" : "Canadian QST number (Québec)",
        "TaxType!cl_tin" : "Chilean TIN",
        "TaxType!hk_br" : "Hong Kong BR number",
        "TaxType!in_gst" : "Indian GST number",
        "TaxType!id_npwp" : "Indonesian NPWP number",
        "TaxType!il_vat" : "Israel VAT",
        "TaxType!jp_cn" : "Japanese Corporate Number",
        "TaxType!jp_rn" : "Japanese Registered Foreign Businesses' Registration Number",
        "TaxType!kr_brn" : "Korean BRN",
        "TaxType!li_uid" : "Liechtensteinian UID number",
        "TaxType!my_frp" : "Malaysian FRP number",
        "TaxType!my_itn" : "Malaysian ITN",
        "TaxType!my_sst" : "Malaysian SST number",
        "TaxType!mx_rfc" : "Mexican RFC number",
        "TaxType!nz_gst" : "New Zealand GST number",
        "TaxType!no_vat" : "Norwegian VAT number",
        "TaxType!ru_inn" : "Russian INN",
        "TaxType!ru_kpp" : "Russian KPP",
        "TaxType!sa_vat" : "Saudi Arabia VAT",
        "TaxType!sg_gst" : "Singaporean GST",
        "TaxType!sg_uen" : "Singaporean UEN",
        "TaxType!za_vat" : "South African VAT number",
        "TaxType!es_cif" : "Spanish CIF number",
        "TaxType!ch_vat" : "Switzerland VAT number",
        "TaxType!tw_vat" : "Taiwanese VAT",
        "TaxType!th_vat" : "Thai VAT",
        "TaxType!ae_trn" : "United Arab Emirates TRN",
        "TaxType!gb_vat" : "United Kingdom VAT number",
        "TaxType!us_ein" : "United States EIN",
        "SignatureTypes" : "Signature types",
        "SignatureDefault" : "Default",
        "SignatureSaved": "Your changes have been saved.",
        "SignatureType!email-based-signature" : "SignHero email based signature",
        "SignatureType!smart-id-signature" : "Estonian Smart-ID",
        "SignatureType!fi-ftn-advanced-signature" : "Finnish online bank and mobile certificate",
        "SignatureType!fi-ftn-intesi-adv-signature" : "Finnish online bank - Intesi",
        "SignatureTypeCountInPeriod!email-based-signature" : "SignHero email based signature in period",
        "SignatureTypeCountInPeriod!smart-id-signature" : "Estonian Smart-ID signature in period",
        "SignatureTypeCountInPeriod!fi-ftn-advanced-signature" : "Finnish online bank and mobile certificate signature in period",
        "SignatureTypeCountInPeriod!fi-ftn-intesi-adv-signature" : "Finnish online bank - Intesi signature in period",
        "MyWallet" : "My Wallet"
    },
    "wallet" : {
        "EstimatedAdvancedSignatureCost" : "Estimated signature cost: €",
        "Error!InsufficientWalletBalance" : "Insufficient wallet balance",
        "AvailableBalance" : "Available balance (Euro)",
        "TopUp" : "Top up",
        "Transactions" : "Transactions",
        "DownloadTransactionDesc" : "Download transaction details as a CSV file",
        "SearchTransactions" : "Search by email or process id",
        "SelectTransactionTypes" : "Select transaction types",
        "TransactionDate" : "Date",
        "TransactionTitle" : "Title",
        "TransactionAmount" : "Amount",
        "TransactionBalance" : "Balance",
        "TransactionInvoice" : "Invoice",
        "InvoicePDF" : "Download",
        "ChooseRechargeMethod" : "Choose a payment method",
        "BankTransfer" : "Bank transfer",
        "OnlinePayment" : "Online payment",
        "TopUpInfo" : "About",
        "TopUpExplanation1" : "The SignHero Wallet is used for Advanced Signature transaction payments. While SignHero email based signatures are included in the subscription fee, Advanced Signatures come with a transaction price.",
        "TopUpExplanation2" : "You can add funds to your wallet through three methods: Bank Transfer, Credit Card payment or MobilePay.",
        "TopUpExplanationWarn" : "<center><b>Wallet can’t be used for subscriptions.</b></center>",
        "TopUpExplanation3" : "After topping up your wallet, the funds are automatically used for Advanced Signature transactions. When you initiate an signature process with Advanced Signatures the following happens:",
        "TopUpExplanation3Bullet1" : "The estimated cost is shown under the Send button.",
        "TopUpExplanation3Bullet2" : "SignHero verifies you have enough funds in your wallet to complete the whole signature process. If the funds in your Wallet are insufficent, you will need to top up the wallet before continuing.",
        "TopUpExplanation3Bullet3" : "The used fee for the signature process will be automatically deducted from your Wallet when the total amount can be calculated. The total amount is calculated when the process is completed, cancelled or gets declined.",
        "TopUpExplanation3Bullet4" : "Transactions are logged under My Wallet.",
        "TopUpExplanation4" : "Signature cost table:",
        "TopUpExplanation4Bullet1" : "Finnish Bank ID and Mobile certificate: 1€ + VAT",
        "TopUpExplanation5" : "The transaction fee is calculated for each completed signature.",
        "TopUpExplanation6" : "By keeping a balance in your Wallet, you ensure a seamless and quick experience whenever you need to initiate advanced signatures. If you have any questions or need assistance, feel free to contact our support team at <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>",
        "PleaseCompleteBankTransfer" : "You have an incomplete bank transfer payment",
        "PleaseCompleteOnlinePayment" : "You have an incomplete online payment",
        "PAYMENT" : "PAYMENT",
        "BankTransferDesc" : "To pay SignHero by a bank transfer, please use the detailed IBAN information below. We will update your balance once we receive your transaction. It may take up to one day for the funds to appear on your balance.",
        "BankTransferPartiallyPaidDesc" : "You have paid ${amountPaid} using bank transfer. To complete the payment, please transfer the remaining ${amountRemaining}. It may take up to one day for the funds to appear on your balance.",
        "AvailableCashBalanceNotice" : "Note: You have ${availableCashBalance} in unused funds from previous bank transfers.",
        "IBAN" : "IBAN",
        "AccountHolder" : "Account holder",
        "BICCode" : "BIC code",
        "Country" : "Country",
        "AvailableBalanceTitle" : "Available Balance (Euro): ",
        "BankTransferMinTopUpAmount" : "Top up amount (minimum ${bankTransferMinTopUpValue})",
        "OnlineMinTopUpAmount" : "Top up amount (minimum ${onlineMinTopUpValue})",
        "EnterANumber" : "Enter the amount ",
        "YouArePaying" : "You are going to pay: ",
        "CancelPayment" : "Cancel payment",
        "Proceed" : "Proceed",
        "PayNow" : "Pay now",
        "Done" : "Done",
        "TransactionDetails" : "Transaction details",
        "TransactionDescription" : "Transaction description",
        "Title" : "Title",
        "Datetime" : "Date and time",
        "Balance" : "Balance",
        "Invoice" : "Download invoice",
        "Amount" : "Amount",
        "ProcessUuid" : "Process id",
        "LastUpdatedBy" : "Last updated by",
        "Prepayment" : "Prepayment",
        "FinalCost" : "Final cost",
        "TransactionType!incoming" : "Incoming",
        "TransactionType!outgoing" : "Outgoing",
        "TransactionTitle!process-prepayment" : "Signature process prepayment",
        "TransactionTitle!finalise-cost-process-completed" : "Process completed",
        "TransactionTitle!finalise-cost-process-cancelled" : "Process cancelled",
        "TransactionTitle!finalise-cost-process-declined" : "Process declined",
        "TransactionTitle!initialization" : "Wallet creation",
        "TransactionTitle!top-up-bank-transfer" : "Customer transferred funds",
        "TransactionTitle!top-up-online-payment" : "Customer paid",
        "TransactionDes!process-prepayment" : "Prepayment for advanced signature process creation",
        "TransactionDes!finalise-cost-process-completed" : "Process completed. Refunded balance for advanced signature process",
        "TransactionDes!finalise-cost-process-cancelled" : "Process cancelled. Refunded balance for advanced signature process",
        "TransactionDes!finalise-cost-process-declined" : "Process declined. Refunded balance for advanced signature process",
        "TransactionDes!initialization" : "Wallet creation",
        "TransactionDes!top-up-bank-transfer" : "Customer transferred ",
        "TransactionDes!top-up-online-payment" : "Customer paid ",
        "paymentStatus!requires_action" : "Incomplete",
        "paymentStatus!requires_payment_method" : "Incomplete",
        "paymentStatus!partially_paid" : "Partially paid",
        "NoteInvalidAddress" : "Note: You don't have a valid billing address. Please add one before topping up your wallet.",
        "NoteValidAddress" : "Billing information",
    },
    "signflow": {
        "SendReminder": "Send activation reminder",
        "SessionType!password": "Password",
        "SessionType!auth_code": "Application",
        "Start": "Start new",
        "Error!Unexpected": "An unexpected error occurred. Try again later.",
        "ButtonBack": "Back",
        "ButtonGoBack": "Go back",
        "ButtonNext": "Next",
        "Create" : "Create",
        "Complete" : "Archive",
        "SearchSignFlows" : "Search by signer, title, or document",
        "AllFlows" : "All",
        "PendingMeFlows" : "Pending on me",
        "PendingOthersFlows" : "Pending on others",
        "CanceledFlows" : "Canceled",
        "CompletedFlows" : "Completed",
        "ShowGroupProcesses" : "All processes of the organisation",
        "CancellationReasons" : "Reason: ${cancellationReasons}",
        "Documents" : "Documents:",
        "SignedDocuments" : "Signed documents:",
        "UnsignedDocuments" : "Unsigned documents:",
        "GenerateDigests" : "Generate digests",
        "DigestTitle" : "Digest:",
        "FtnSignatureTitle" : "XML-DSig signature (Finnish online bank and mobile certificate):",
        "FtnSignatureXmlFile" : "Document-signature.xml",
        "SignatureValidationFilesDesc" : "If needed, use these files to prove you had an intention to sign.",
        "SignatureValidationFiles" : "Signature validation files",
        "CreatedByTitle" : "Created by:",
        "CanceledByTitle" : "Canceled by:",
        "PendingSignaturesTitle" : "Pending:",
        "PendingMeAlert" : "<span class='elem-pink'>You</span> have not signed.",
        "PendingOthersAlert" : "Pending on <span class='elem-pink'>${count}</span> person(s) to sign",
        "SignerStatusTitle" : "Status:",
        "ReminderHistory" : "Reminder history",
        "SignerStatus1" : "Pending, invitation first opened on ",
        "SignerStatus2" : "Pending, invitation never opened.",
        "SignerStatus3" : "Signed on ",
        "SignerStatus4": "Pending on the creator.",
        "SignerStatus5": "Pending.",
        "SendReminders": "Remind by email",
        "NoProcessesFound": "No processes that match your filters",
        "LastEmailReminder": "Latest reminder: <b>${timestamp}</b>",
        "NoRemindersYet": "No reminders sent",
        "EmailReminderSuccess": "Email reminders sent.",
        "ReminderSentBy": "sent by",
        "CancelProcessDialogTitle": "Cancel process",
        "CancellationReasonsPlaceHolder": "Reasons for cancellation",
        "CancelProcess": "Cancel process",
        "DeleteProcess": "Delete process",
        "RestartProcess": "Start again",
        "ConfirmDeleteProcess": "Are you sure you want to delete the process?",
        "ResendDownloadInvitation": "Resend download link",
        "DownloadInvitationEmailSuccess": "Email sent.",
        "DownloadInvitationEmailFail": "Sending failed",
        "Me": "Me",
        "SignFlowDocumentsHeading": "What's signed?",
        "FlowSettings": "Extras",
        "FlowTitlePlaceholder": "Name the case for easy archiving",
        "Signers": "Signers *",
        "AddSigner": "Add more signers",
        "AddSignerGroup": "Add signers from file",
        "AddressBookChooseFile": "Choose file",
        "AddressBookInfoHeader": "How to add signers from file",
        "AddressBookInfo": "It should be a file with a .txt/.csv extension, with each signer on its own line, and a comma between the fields.",
        "AddressBookInfoExampleHeader": "For example a file named BoardMembers.txt with the following content:",
        "AddressBookInfoExampleContent": "James Smith, james.smith@example.com<br>Mary Jones, mary.jones@example.com<br>John Williams, john.williams@example.com<br>Linda Taylor, linda.taylor@example.com",
        "NotAnAddressBook": "The file did not contain recipient names and addresses.",
        "CheckFileEncoding": "The file contains unsupported characters. Please ensure that the file encoding is UTF-8.",
        "SignersName": "First and last name",
        "SignersEmail": "Email",
        "EmailSubject": "Email title:",
        "EmailMessage": "Message:",
        "EditInvitation": "Edit signature invitation",
        "UseDragAndDropSignature": "Set signature positions",
        "NoPrintedEmail": "Hide email addresses",
        "StartNow": "Sign now",
        "StartSigningNow": "You can sign now or later from the Ongoing tab.",
        "RequestEmailSendSigners": "Signature request email is sent to signers. ",
        "InformProgressNotifications": "You will be notified about the progress via email.",
        "Later": "Later",
        "Ok" : "Ok",
        "DefaultEmailSubject": "${$cc_user_info.name} invites you to sign",
        "DefaultEmailMessage": "${$cc_user_info.name} from ${$cc_user_info.organization.display_name} (${$cc_user_info.email}) has invited you to eSign using SignHero.",
        "Signer": "Signer",
        "Created": "Created",
        "Canceled": "Canceled",
        "Sign": "Sign",
        "Send": "Send",
        "GoToSign": "Go to sign",
        "Download": "Download",
        "SignedBy": "Signed by:",
        "WhoIsSigning": "Who signs?",
        "OnlyMe": "Only me",
        "OnlyOthers": "Only others",
        "MeAndOthers": "Me and others",
        "Email": "Email",
        "Add": "Add",
        "numbersDidNotMatch": "The codes do not match. Please try again.",
        "toProceedEnterCode": "To proceed, enter code: ",
        "Confirm": "Confirm",
        "Save": " Save ",
        "Continue": "Continue",
        "Cancel": "Cancel",
        "Reset": "Reset",
        "selectFromDisk": "Local",
        "UploadFile": "Upload",
        "fileTooLarge": "Maximum file size is ${maxSize}.",
        "allFilesTooLarge": "The total maximum size for all files is ${maxSize}. You are now attempting to upload ${uploadedSize}.",
        "fileNumberTooLarge": "Upload at most ${maxFileNumber} files.",
        "uploadError": "Sorry, there's something wrong with a file. Maybe wrong format or it's already signed.",
        "select": "Select",
        "OngoingProcess" : "Ongoing processes",
        "GoogleDrive": "Google Drive",
        "DownloadFailed": "File Download Failed",
        "Error!FilesOutdated" : "Session timeout error. Please upload files and fill in data again if necessary",
        "Error!DocumentError" : "Sorry, session expired or there's something wrong with a file. Maybe wrong format. Please upload files and fill in data again if necessary.",
        "OnlyPdf" : "Accepted file types are pdf",
        "OnlyCancelledProcessAllowed": "You can only restart cancelled process.",
        "CreateNewTags" : "Create new tag",
        "CreateNewTagsHint" : "Press enter to add new tag: maximum 20 characters",
        "ManageTags" : "Manage tags",
        "Tags" : "Tags",
        "FilterByTag" : "Filter by tag",
        "ErrorTagExists" : "Tag already exists!   ",
        "MyOrganization" : "My organization",
        "OtherOrganizations" : "Other organizations",
        "OwnedBy" : "Owned by:",
        "SavedTemplates" : "Choose template",
        "CreateTemplateTitle" : "Save the invitation template",
        "TemplateCreated" : "Invitation template saved.",
        "DeleteTemplateTitle" : "Delete shared invitation template",
        "DeleteTemplateConfirm" : "Are you sure? This affects the whole organisation.",
        "ExplainTemplate1" : "The organisation doesn't have saved invitation templates. Invitation templates might be saved after editing them.",
        "ExplainTemplate2" : "You can save -- but you don't have to! ",
        "ExplainTemplate3" : "Current signature invitation can be used without saving.",
        "ExplainTemplate4" : "You may save frequently used invitation templates. The created templates are visible for the whole organisation and can be edited or deleted by anybody in the organisation.\nCurrent signature invitation can be used without saving.",
        "UpdateTemplate" : "The template will be visible, editable and deletable for everybody in the organisation.\n\nTemplate name: ",
        "DeleteTemplate" : "Template deleted",
        "Error!TemplateNameExist": "The template already exists, should it be overwritten?",
        "Error!TemplateNameEmpty": "Template name is missing!",
        "TemplateNoname" : "noname",
        "Update" : "Update",
        "Edit" : "Edit",
        "ProcessesTabName1" : "My processes",
        "ProcessesTabName2" : "My signing invitations",
        "ShowOnly12MonthInfo" : "Displaying processes from the last 12 months",
        "MaxSigners" : "Maximum signers for a process is ",
        "MaxSigners1" : "Please remove ",
        "MaxSigners2" : " signers.",
        "MaxSigners3" : "More signers cannot be added.",
        "SelectSignatureTypes" : "Select signature types",
        "AllowedSignatureTypes" : "Allowed signature types",
        "AllSigners" : "All signers",
        "SwitchAdvancedSignature" : "Switch to advanced signature",
        "SwitchBasicSignature" : "Switch to SignHero email based signature",
        "BasicLevel" : "SignHero email based signature",
        "AdvancedLevel" : "Advanced signature",
        "BasicLevelInfo" : "When SignHero email based signature is selected, all the signers will be changed to this type.",
        "AdvancedLevelInfo" : "When advanced signature is selected, all signers are updated with the selected signature types.",
        "Apply" : "Apply"
    },
    "message": {
        "ReportProblem" : "Report a problem",
        "IForgotMyPassword" : "Reset password",
        "NavigationMenuButton": "Menu",
        "NavigationAccountButton": "Account",
        "NavigationContactButton": "Contact",
        "NavItemIntroduction": "Introduction",
        "NavItemChooseEasy": "Choose Easy",
        "NavItemAccount": "Account",
        "NavItemAPIsIntegrations": "APIs & Integrations",
        "NavItemDoubtsWorries": "About SignHero",
        "NavItemThisIsUs": "This is us",
        "NavItemContactUs": "Contact us",
        "MainSlogan": "SignHero - It doesn't get easier than this!",
        "SubSloganPart1": "Leaving complexity, inefficiency,",
        "SubSloganPart2": "and outdated ways behind.",
        "GoToRegister": "Start free trial",
        "TitleLazy": "Choose easy",
        "TextLazyLine1": "Templates and scribbles are irrelevant;",
        "TextLazyLine2": "they represent over-engineered processes that waste time and money.",
        "TextLazyLine3": "SignHero fans want to just focus on what matters, so we give you the essentials.",
        // New localisation for en, fi, sv
        "NEWTitleLazy": "Choose easy",
        "NEWTextLazyLine1": "SignHero keeps it simple, cutting out unnecessary steps so you can focus on what truly matters.",
        "MetricsTitle": "Average weekly metrics:",
        "Metrics1" : "6000+ people using SignHero",
        "Metrics2" : "2000+ contracts signed",
        "Metrics3" : "100+ new team accounts",
        "TitleAccount": "Account",
        "TextAccount": "SignHero account includes everything you really need to eSign:",
        "Feature1": "Send & sign PDFs",
        "Feature2": "Track processes",
        "Feature3": "Remind signers",
        "Feature4": "Access audit trail",
        "Feature5": "Work in teams",
        "Feature6": "Archive documents",
        "UnlimitedSigners": "Unlimited signers & invitations.",
        "EasyRegister": "Register with your name, email and organisation.",
        "Register": "Create your account",
        "QuoteMarko": "\"We sign nearly all contracts with SignHero. Feedback has been really positive, and people keep asking how they can start using it too.\"",
        "AuthorMarko": "Marko Vihiniemi",
        "CompanyMarko": "CEO, Salon Vilpas Koripallo Oy",
        "QuoteHenri": "\"I was fascinated by the increased efficiency compared with old signing methods. Our whole team started using SignHero, and it has received lots of praise!\"",
        "AuthorHenri": "Henri Kämäräinen",
        "CompanyHenri": "Head of Partnerships, FallUp, Aalto Entreprenership Society",
        "QuoteJuho": "\"Usually I don’t believe things that seem too good to be true, but in this case, you get exactly what’s promised.\"",
        "AuthorJuho": "Juho Nenonen",
        "CompanyJuho": "Co-founder & CEO, Sportspot",
        "QuoteKarolina": "\"First, we tried this out of curiosity, but now, our legal advisers couldn’t imagine not using SignHero! We primarily eSign contracts for legal services and formal legal opinions.\"",
        "AuthorKarolina": "Karolina Bonarska-Lenarczyk",
        "CompanyKarolina": "Legal adviser",
        "QuoteMatias": "\"Less time spent on unproductive work, less paper, and a more secure way to sign. SignHero is a great solution!\"",
        "AuthorMatias": "Matias Vihko",
        "CompanyMatias": "Chairman of the Board, Koju Film Company",
        "QuoteKalle": "\"SignHero is very straightforward and eases property management work, which involves a lot of signing.\"",
        "AuthorKalle": "Kalle Grönqvist",
        "CompanyKalle": "CEO, Fluxio",
        "QuoteMonika": "\"We wanted a modern solution that makes life easier for our customers. SignHero improves the booking process and registration – leaving us more time for our furry guests.\"",
        "AuthorMonika": "Monika Jackowska-Ziemba",
        "CompanyMonika": "Co-owner, DOGStation",
        "QuoteDominika": "\"HR can finally sign paperlessly! SignHero supports our signing process and also conveniently archives all signed documents.\"",
        "AuthorDominika": "Dominika Pusz",
        "CompanyDominika": "President of the Board, MDJ Recruitment",
        "QuoteVisa": "\"I love SignHero!\"",
        "AuthorVisa": "Visa Tuovinen",
        "CompanyVisa": "Roast Master, Lehmus Roastery",
        "TitlePricing": "Pricing",
        "MonthlyPrice": "5€ / user / month",
        "MonthlyBill": "(Billed monthly. Price does not include value-added tax/sales tax. The value-added tax/sales tax valid at the time will be added to the price.)",
        "Benefits1": "Unlimited electronic signatures",
        "Benefits2": "Archive for your documents",
        "Benefits3": "Team account for multiple users",
        "Benefits4": "Signers don’t need an account",
        // New localisation for en, fi, sv
        "NEWTitlePricing": "Pricing",
        "NEWMonthlyPrice1": "Email based signatures",
        "NEWMonthlyPrice2": "5€ / user / month",
        "NEWMonthlyBill": "(Billed monthly. Price does not include value-added tax/sales tax. The value-added tax/sales tax valid at the time will be added to the price.)",
        "NEWBenefits1": "Unlimited electronic signatures",
        "NEWBenefits2": "Archive for your documents",
        "NEWBenefits3": "Team account for multiple users",
        "NEWBenefits4": "Signers don’t need an account",
        "NEWAdvancedPrice1": "Finnish advanced signatures",
        "NEWAdvancedPrice2": "1€ / signature",
        "NEWAdvancedBill": "(Price does not include value-added tax/sales tax. The value-added tax/sales tax valid at the time will be added to the price.)",
        "NEWBenefits5": "Currently available for users with Finnish banking credentials or mobile certificates",
        "NEWBenefits6": "Add funds to your SignHero Wallet and pay as you go",
        "ButtonFreeTrial": "Start a 14-day trial",
        "CreditCardInfo": "No credit card required",
        "TitleAPI": "APIs & Integrations",
        "IntegrateSignHero": "Integrate our signing solution wherever it fits your workflow.",
        "ToIntroBTN": "Go to intro",
        "ToDocsBTN": "Go to docs",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "QuoteOlli": "\"Integrating electronic contract generation and signing into a WordPress environment saves our clients tens of hours of time.\"",
        "AuthorOlli": "Olli Nyqvist",
        "CompanyOlli": "CEO, WebAula",
        "TitleDoubts": "About SignHero",
        "DoubtsLegal": "Is eSigning even legal?",
        "TitleShortAnswer": "Short answer:",
        "AnswerLegalParagraph1": "Of course. It’s been around already for a decade.",
        "AnswerLegalParagraph2": "Signing a piece of paper with a pen is not a particularly secure way to sign. The law is on our side; check the",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "and",
        "Acts": "acts.",
        "TitleLongAnswer": "Long answer:",
        "LegalLongAnswerParagraph1": "You know, signing a piece of paper is not a very guaranteed way of making an agreement.\nHandwritten signatures can be forged, disputed and even lost. In fact, if you’re still\nscribbling away, you’re basically not assured of anything.",
        "LegalLongAnswerParagraph2": "Besides, the EU has a freedom of making contracts act, according to which you can choose how\nyour agreements are confirmed. Check that your business has no special requirements (e.g.\nmoney laundering act) and then eSign away.",
        "LegalLongAnswerParagraph3": "Authentication is like insurance. For example, did you show your ID when you signed your\ncurrent work contract? Some agreements are low risk and so only require light insurance; in\nthis case, email authentication is enough. ",
        "LegalLongAnswerParagraph4": "SignHero is currently used for: Commercial contracts / Work contracts / NDAs / Meeting minutes\n/ Rental agreements",
        "LegalLongAnswerParagraph5": "SignHero standards-based signatures are compliant with UETA, Esign Act and\n"
                                     + "eIDAS type Electronic signature.",
        // New localisation for en, fi, sv
        "NEWDoubtsLegal": "eSigning Made Simple and Secure",
        "NEWLegalLongAnswerParagraph1": "For almost two decades, electronic signatures have been recognized by law, with regulations like the eIDAS and ESIGN Acts ensuring their validity.",
        "NEWLegalLongAnswerParagraph2": "In fact, signing with pen and paper is far less secure—it’s easy to forge, dispute, or misplace. Electronic signatures offer layers of authentication, giving you confidence and assurance in every agreement.",
        "NEWLegalLongAnswerParagraph3": "Think of it like insurance: some deals need only basic coverage, like email authentication, while others may require stronger identity checks.",
        "NEWLegalLongAnswerParagraph4": "SignHero is the trusted solution for streamlining agreements of all kinds, making signing effortless, reliable, and secure.",
        "NEWLegalLongAnswerParagraph5": "With compliance built into our standards, SignHero meets UETA, <a href=\"https://www.gpo.gov/fdsys/pkg/PLAW-106publ229/pdf/PLAW-106publ229.pdf\"\n"
                                        + "                             target=\"_blank\" rel=\"noopener\">ESIGN</a> Act, and <a href=\"https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32014R0910\"\n"
                                        + "                             target=\"_blank\" rel=\"noopener\">eIDAS</a> requirements—so you can sign with confidence.",
        "DoubtsPrice": "How does it work? Only 5€?",
        "PriceShortAnswerParagraph1": "You can get documents signed and archived with a few clicks. Signing invitations are sent by email, and signing doesn’t require an account. The price is 5€ per user per month. And it will be billed automatically on a monthly basis once you have filled in your credit card information. You can cancel at any time, so you only pay for a month at the time.",
        "PriceLongAnswerParagraph1": "<span class='doubts-break1'>SignHero enables you to sign PDF documents with computers and mobile devices, and it can be integrated into any CRM, digital archive or other document management system with our public API. You can even sign directly in your Gmail account via SignHero plug-in</span> <span class='doubts-break2'>without leaving your inbox.</span>",
        "PriceLongAnswerParagraph2": "You can create an account without any strings attached and get a 14-day free trial. No credit card information is needed when signing up. The free trial period includes all the features, so you are able to give it a solid test-run. And don’t worry, we never spam or send any newsletters.",
        "PriceLongAnswerParagraph3": "The price is 5€ per user per month. As a paying user, you are able to send signature requests, monitor signing events, store your documents in the SignHero archive and work as a team on an organisation account. You can add almost an infinite number of team members to your account and every new user adds another 5€ to your monthly subscription plan.",
        // New localisation for en, fi, sv
        "NEWDoubtsPrice": "How does SignHero work?",
        "NEWPriceLongAnswerParagraph1": "With SignHero, getting documents signed is effortless. Send signing invitations via email, and recipients can sign without needing an account. Everything is streamlined - signatures, tracking, and secure document storage - all handled with just a few clicks. ",
        "NEWPriceLongAnswerParagraph2": "You can start with a free 14-day trial, no credit card required, explore the service and see how it fits your workflow. Once you’re ready, subscriptions are simple and flexible, billed monthly with no long-term commitment. You’re free to cancel anytime.",
        "NEWPriceLongAnswerParagraph3": "SignHero works seamlessly across devices and integrates with CRMs, archives, or document management systems via our public API. For Gmail users, you can even sign directly from your inbox using the SignHero plug-in.",
        "NEWPriceLongAnswerParagraph4": "Whether you’re sending signature requests, collaborating with your team, or managing archives, SignHero makes professional eSigning accessible, affordable, and easy to use.",
        "PriceReasons1": "At some point SignHero needs to be able to stand on its own, and not to be forever supported by a loving parent company (Avaintec).",
        "PriceReasons2": "We are not able to grow and create better experience for the lazy users unless we get some money to pay our bills.",
        "PriceReasons3": "Every week somebody approaches us, and asks how we are exploiting their data and scamming them with a brilliantly disguised free service. Well, not anymore. We are absolutely certain that after you start paying 5€ per month, you’ll trust us more. Who wouldn’t trust a capitalist?",
        "DoubtsSaveTime": "What can I do with all the time I save?",
        "SaveTimeAnswer": "Have some coffee, go fishing, actually don’t, get some work done, call your mum, do your\ntaxes, log in your hours, recycle, save the world, play with your dog, play with your friend’s\ndog, learn an instrument, write a poem, bake a cake, eat a cake, get a new haircut, do\ncartwheels across the lawn, travel the world, read a book, design your own destiny, create a\nreligion, bust a myth, self-employ, go to a startup event, raise money, fail fast, take a nap,\nbe lazy, repeat.",
        "TitleThisIsUs": "This is us",
        "LinkedIn": "SignHero LinkedIn",
        "Youtube": "SignHero Youtube",
        "History": "History",
        "CompanyName": "Avaintec",
        "CompanyAddress": "Kansakoulukuja 3, 00100 Helsinki",
        "CompanyWeb": "www.avaintec.com",
        "TitleMoreQuestion": "Any more questions?",
        "SignHeroEmail": "hello@signhero.io",
        "ApiDocsLink": "API docs",
        "TermOfUseLink": "Terms of use",
        "PrivacyPolicyLink": "Privacy policy",
        "CopyrightYear": "© " + new Date().getFullYear() + " ",
        "CompanyOyName": "Avaintec Oy",
        "RegulationNotice": "Electronic signatures have the same legal status as handwritten signatures in the US and EU thanks to",
        "ESignAct": "ESIGN Act",
        "eIDASRegulations": "eIDAS regulations",
        "Docs": "Documentation",
        "SignIn": "Log in",
        "TermsOfUse": "Terms of use",
        "ThisLocale": "EN",
        "WelcomeToSignHero": "Welcome to SignHero. Please enter your email and click \"Continue.\". You will receive an email which will let you set your password for the first time, and sign in.",
        "ConfirmAccountInfo": "Please check your email and follow the instructions in it to set your password and sign in.",
        "MustBeEmail": "Enter an email address.",
        "MustBeValidFullName": "Enter a valid name.",
        "HyperlinksNotAllowed": "Hyperlinks are not allowed.",
        "MustBeUniqueEmail": "This email has been already added.",
        "EnterValidCharacters": "Please enter only supported characters.",
        "UserSignedUp": "Welcome to SignHero! We sent you an email with instructions on how to activate your account. If you have an account already you can reset your password from the \"Log in\" screen.",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "SessionType!password": "Password",
        "SessionType!auth_code": "Application",
        "YouHaveBeenSignedOut": "You have been signed out.",
        "Error!UserExists": "A user with the email or username you provided already exists. If you've forgotten your password, click \"Sign in\" and change it from the \"Reset password\" link.",
        "Save": "Save",
        "Error!Unexpected": "An unexpected error occurred. Try again later.",
        "Create": "Create",
        "Complete": "Archive",
        "Sign": "Sign",
        "Welcome": "Welcome",
        "CompleteProcessInfo": "You cannot sign the document(s) because this signature request has been closed. You may have already signed these documents or it was canceled by the sender.",
        "SignInHere": "Log In",
        "ReasonTitle": "LIKELY REASONS",
        "Reason1": "You already signed this document",
        "Reason2": "One of the signers has declined to sign",
        "Reason3": "The invitation was cancelled by the sender",
        "Reason4": "The invitation expired after a year",
        "Reason5": "You entered a mistyped address",
        "Reason6" : "You received a reminder and the old link expired automatically",
        "SeeYouSoon" : "See you soon",
        "HowToOpenExpiredSession" : "To reopen the session, please click the link in your email.",
        "ClearCookieWarning" : "If you are using a public or shared device, please remember to clear your cookies, history and cache.",
        "JustASec" : "Just a sec",
        "SorryUnexpectedError" : "Sorry, page not found!",
        "InvalidLoginUrl" : "The link you followed might be broken, or the invitation has been removed.",
        "InvitationInvalidatedReason" : "The link you followed might be broken, or the invitation has been removed.",
        "You" : "you",
        "DownloadPageTitle" : "View and download signed documents",
        "DownloadPageSignatureValidationFilesDesc" : "If needed, use these files to prove you had an intention to sign.",
        "AuthInfo" : "Authentication info",
        "DownloadLinkInfo" : "This link will stay active one year from signature process completion.",
        "Download" : "Download",
        "OpenAuditTrail" : "View audit trail",
        "ProcessNotFound" : "The signing process was not found. Please verify the signing process ID.",
        "SignatureEvents" : "Signatures",
        "ProcessStatus!COMPLETED" : "Complete",
        "ProcessStatus!CANCELED" : "Declined",
        "ProcessStatus!PENDING" : "Pending",
        "ProcessStatus!UNKNOWN" : "Unknown",
        "SignatureProcessId" : "ID",
        "SignatureProcess" : "Signing process",
        "SignatureFlowCreated": "Created",
        "SignatureFlowStatus": "Status",
        "NumberOfDocuments": "Documents",
        "SignedBy": "Signed by",
        "SignTime": "Signature time",
        "AuthType": "Authentication Type",
        "AuthMethod!verification_code": "Email verification",
        "AuthMethod!access_token": "SignHero account",
        "AuthMethod!strong_auth": "Strong",
        "AuthMethod!otp_token": "Third party",
        "AuthMethod!otac": "Email",
        "AuthMethod!signer_token": "Email",
        "AuthMethod!on_site": "In-person",
        "AuthMethod!fi-ftn-advanced-signature": "Finnish online bank and mobile certificate",
        "AuthMethod!smart-id-signature" : "Estonian Smart-ID",
        "AuthMethod!fi-ftn-intesi-adv-signature" : "Finnish online bank - Intesi",
        "PrivacyPolicy": "Privacy Policy",
        "EnterYourDetailsBelow": "Enter your details below.",
        "ThirdParty": "Third party",
        "SignUpButtonLabel": "Continue",
        "NameAndSurname": "Your name and surname",
        "Email": "Email",
        "Company": "Organisation",
        "ConfirmAccount": "Continue",
        "Confirm": "Confirm",
        "ConfirmAccountTitle": "Set password for your <span class='elem-pink'>SignHero</span> account",
        "ResetPasswordTitle": "Reset password for your <span class='elem-pink'>SignHero</span> account",
        "ResetPassword": "Set new password",
        "Reset": "Reset",
        "Organization": "Organisation",
        "WelcomeMessage": "Log in to your <span class='elem-pink'>SignHero</span> account",
        "WelcomeText": "Enter your details below.",
        "DontHaveSignHeroAccount": "Don't have a <span class='elem-pink'>SignHero</span> account?",
        "GetStarted": "Get started",
        "AlreadyHaveSignHeroAccount": "Already have a <span class='elem-pink'>SignHero</span> account?",
        "CreateYourSignHeroAccount": "Create your <span class='elem-pink'>SignHero</span> account",
        "AcceptTermsOfUseAndPrivacyPolicy": "By clicking Continue, you accept SignHero's <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>Terms of Use</a> and <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>Privacy Policy</a>.",
        "YouFoundApi": "You found SignHero API!",
        "CheckApiDocumentation": "To get started, check out our latest <a class=='elem-pink' href='${apiDocsLink}'>API documentation</a>.",
        "AuditTrail" : "Audit trail",
        "EnterProcessId" : "Enter the signing process ID, displayed in the signature box in each document:",
        "SubmitID" : "Submit",
        "NoProcessId" : "Please enter the process ID.",
        "ConfirmError1" : "Please request a new invitation or a password reset link from the ",
        "ConfirmError2" : " page.",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "This link might have expired.<br>Go to reset your password on <a href='/#/signin' class='elem-pink'>the login page</a>.",
        "Error!tax_id_invalid" : "Invalid tax ID.",
        "CreditCardSaved" : "Credit card has been added.",
        "BillingInformationSaved" : "Billing information saved.",
        "dateFormat" : "MMM dd, yyyy",
        "timeFormat" : "dd.MM, HH:mm",
        "Error!card_declined" : "Credit card was declined by payment gateway.",
        "Error!incorrect_cvc" : "Credit card was declined by payment gateway.",
        "Error!expired_card" : "Credit card was declined by payment gateway.",
        "Error!processing_error" : "Credit card was declined by payment gateway.",
        "Contact" :  "Contact",
        "name" : "Name",
        "email" : "Email",
        "LoginEmail" : "Email",
        "EnterYourEmail" : "Please enter your email:",
        "Password" : "Password",
        "ForgotYourPassword" : "Forgot your password?",
        "RequestConfirmToken" : "Try confirming again",
        "HelloThere" : "Hello there,",
        "ChangePassword" : "Change password",
        "CurrentPassword" : "Current password",
        "NewPassword" : "New password",
        "RepeatPassword" : "Repeat password",
        "PasswordSet" : "Password set.",
        "MessageSent" : "Message sent. We will contact you if required.",
        "AuthCodeCreated" : "We have mailed you a link. Follow it to sign in.",
        "SignHeroSentPasswordResetRequest" : "Password reset request sent if you are a registered user. Please check your email.",
        "SignHeroSentConfirmTokenRequest" : "Requested new account confirmation. Please check your email.",
        "DefaultDialogTitle" : "",
        "Action!SetPassword" : "Set password",
        "Action!ConfirmAccount" : "Confirm account",
        "TooltipSetPassword" : "Incorrect email or passwords do not match.",
        "Action!EditProfile" : "Edit",
        "Action!SignOut" : "Sign out",
        "Action!SignIn" : "Sign in",
        "Update" : "Update",
        "Delete" : "Delete",
        "Cancel" : "Cancel",
        "Accept" : "Accept",
        "Submit" : "Submit",
        "Error!PasswordChangeFailedInvalidPassword" : "Your old password did not match. Please try again.",
        "Error!InvalidEmail" : "Enter your email address.",
        "Error!InvalidFullName" : "Enter a valid name and surname",
        "Error!InvalidPasswordReset" : "Your password reset token was invalid or expired. Request another one from the \"Reset password\" link and try again.",
        "Error!InvalidCredentials" : "Invalid email or password.",
        "Error!InvalidScopes" : "Your access rights do not allow use of this application. Please contact the administration.",
        "Error!TemporarilyLockedOut" : "Sign-in is temporarily blocked from this device due to repeated failures. If you've forgotten your password, reset it from the \"Reset password\" link. Sign-in will be unblocked in ${minutes} minutes.",
        "Error!TokenCheckFailed" : "This link might have expired.<br>Go to reset your password on the login page.",
        "Error!too_many_incorrect_email": "Too many incorrect email addresses, please try again tomorrow.",
        "PasswordRequirement" : "Your password should be at least 10 characters long and include a number, an uppercase letter, a lowercase letter and a special character. Alternatively you can use a minimum of 15 characters. Don't use more than two consecutive identical characters.",
        "EmailRequired" : "Email is required",
        "EmailMustValid" : "Email must be valid",
        "PasswordMustValid" : "Password must meet complexity requirements",
        "RepeatPasswordMustSame" : "The passwords must be identical",
        "TitleNews" : "News",
        "ReadMoreLink" : "Read more",
        "OtherNewsBtn" : "Other news",
        "NewsListBg1" : "This is what we've been up to",
        "NewsListBg2" : "Read news about our achievements, releases and mentions in the media.",
        "NewsTitlePartnerStory" : "SignHero partner story: Proactive cooperation offers a win-win-win situation",
        "NewsTitleSaveTime" : "Signhero: More secure way to sign gives you more time and money",
        "Share" : "Share",
        "PartnerStoryP1" : "SaaShop and SignHero have a long partnership that offers benefits to both companies, and above all, the customers. SignHero has been available in SaaShop´s marketplace since 2020 and through our marketplace, SignHero has gained over 1100 users. In this article, we talk about the successful partnership between the two Finnish companies that are now going international and offering SaaS solutions to small to medium sized businesses globally. ",
        "PartnerStoryP2" : "<a href=\"https://signhero.io/\" target=\"_blank\" rel=\"noopener\">SignHero</a>, developed and owned by a Finnish software company <a href=\"https://www.avaintec.com/\" target=\"_blank\" rel=\"noopener\">Avaintec Oy</a>, enables an easy signing of PDF documents with just a few clicks on computers and mobile devices. User-friendliness and efficiency are SignHero´s strengths: signing happens easily and doesn’t require a SignHero account and signed documents stored in SignHero. You can add team members to your organization account, as well as track processes and send reminders and signing invitations by email. ",
        "PartnerStoryP3" : "The benefits of using SignHero are many as it helps to get rid of pen-and-paper signing and physical archiving, which offers environmental benefits. SignHero helps to streamline processes and cut down costs. The smooth communication process improves the customer experience. SignHero also meets the industry standards and requirements for electronic signatures.",
        "SubtitleHelpCustomers" : "Helping customers is the main aim of partnering up",
        "PartnerStoryP4" : "In January 2022, SignHero launched a paid version with new functionalities which support smooth and easy use of the service. With this development, the intensity of the collaboration has increased as SignHero uses the marketing package SaaShop offers for SaaS partners.",
        "PartnerStoryP5" : "“The cooperation has been fruitful from the beginning and together with launching the paid version, we have worked together with SaaShop more intensively,” the CEO of Avaintec, Pekka Kuosmanen, describes. He continues:",
        "PartnerStoryP6" : "“Teaming up with businesses that have a similar customer base, but aren’t in direct competition has its own advantages. They say that win-win cooperation is the best cooperation. SaaShop attracts customers for us that we might not be able to reach, and SaaShop acts as a reseller of our digital signature service SignHero. In turn, we believe that we can add value to SaaShop as they can better serve their customers with our service, and our partnership helps further grow their business.”",
        "PartnerStoryP7" : "“They say that win-win cooperation is the best cooperation.”",
        "PartnerStoryP8" : "Both SaaShop and SignHero are also growing their international customer base. The two companies also share similar values as user experience and customer service are priorities which both strive for.",
        "PartnerStoryP9" : "“SignHero is unique because we prioritize usability over complex features. We have two advantages: a loyal user base, and easy integrations to other services,” the Operation manager of Avaintec Oy, Fatbardhe Hetemaj, says.",
        "PartnerStoryP10" : "“We have been very pleased with the cooperation all in all, especially with SaaShop’s proactive approach and a positive, can-do attitude in customer service. Customers expect quick responses and problem-solving and we’re always happy to see a satisfied customer. We are both committed to assisting customers in any way possible,” Hetemaj continues.",
        "PartnerStoryP11" : "“We’re always happy to see a satisfied customer. We are both committed to assisting customers in any way possible.”",
        "PartnerStoryP12" : "Avaintec is happy to recommend SaaShop´s service and partnership to others:",
        "PartnerStoryP13" : "“We see SaaShop as one of the most important reseller channels for SignHero in the future and look forward to expanding cooperation to international markets as well. The SaaShop has a great team of people working for them making working together very smooth,” Kuosmanen concludes.",
        "PartnerStoryP14" : "SaaShop offers all SaaS products from one place, on one bill. Customers can create a profile for SignHero directly from <a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SaaShop´s marketplace</a>. Read more about the benefits of becoming a SaaShop partner <a href=\"https://www.saashop.com/become-a-partner/\" target=\"_blank\" rel=\"noopener\">here</a>.",
        "NewsSaveTimeP1" : "Two-Factor Authentication is used on many occasions these days. There are also a lot of situations where contracts are signed on paper. But did you know that there are many agreements that can be confirmed with eSign where no further authentication is required? For example, did you show your ID when you signed your current work contract? Some agreements are low risk and therefore only require light insurance; in this case, email authentication is enough.",
        "NewsSaveTimeP2" : "Commercial contracts, work contracts, NDAs, meeting minutes and rental agreements are examples of situations where you can use <a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHero</a> instead of complicated and time wasting options. SignHero enables you to get documents signed with a few clicks, store signed documents in your SignHero archive, add team members to your organization account, as well as track processes and send reminders. Signing invitations are sent by email, and signing doesn’t require a SignHero account. No extra authentication is needed because SignHero only focuses on cases that fall under the ‘freedom of making contracts’ act.",
        "NewsSaveTimeP3" : "<a href=\"https://signhero.io\" target=\"_blank\" rel=\"noopener\">SignHero</a> is owned by a Finnish software company <a href=\"https://www.avaintec.com\" target=\"_blank\" rel=\"noopener\">Avaintec Oy</a> and it offers a modern solution that makes life easier for you and your customers. You will spend less time on unproductive work, you will have less paper, and you can offer a more secure way to sign agreements. When signing is simple, your booking process and number of customers will improve.",
        "SubtitleBenefitsForOrganisations" : "E-signing offers many benefits for organizations",
        "NewsSaveTimeP4" : "Electronic signatures have been helping organizations speed up their business for almost three decades now. Today, eSigning can be as simple as sending an email, but it also offers a variety of benefits.",
        "NewsSaveTimeP5" : "<a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHero</a> helps to get rid of pen-and-paper signing and physical archiving (environmental benefit), streamline processes, and cut down costs, as well as expand smoothness of online communication. By automating document workflows across organizations, sales, marketing, logistics, HR, and legal functions achieve substantial efficiencies in helping businesses of any size eliminate paperwork bottlenecks. Along with CRM, ERP, digital archiving, and other cloud-based SaaS applications, eSigning is finally digitizing global business.",
        "NewsSaveTimeP6" : "Johannes Setänen, Business Director from Eezy Office, has enjoyed the benefits and simplicity of SignHero:",
        "NewsSaveTimeP7" : "“Signhero was fast and easy to implement in action for Eezy in the field of staff leasing. No complicated integrations, no extra costs, just simply monthly fees. So Eezy!”",
        "SubtitleFeatures" : "What are the features of a good e-signing solution?",
        "NewsSaveTimeP8" : "<a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHero</a> already offered a modern solution for sealing the deals and signing the contracts. However, constant development is the key for success and SignHero has improved its features. With new updates organizations have even more tools to revolt against complexity, over-engineered processes, and the old way of doing things. Following features have been added to SignHero:",
        "NewsSaveTimeFeaturesBullet1" : "Improved archive search",
        "NewsSaveTimeFeaturesBullet2" : "Tag signing process and Tag search",
        "NewsSaveTimeFeaturesBullet3" : "Restart signature",
        "NewsSaveTimeFeaturesBullet4" : "Customized email templates",
        "NewsSaveTimeFeaturesBullet5" : "User statistics",
        "NewsSaveTimeFeaturesBullet6" : "Account merging",
        "NewsSaveTimeP9" : "SignHero wants to offer its help in multiple languages. At this moment, SignHero is available in English, Finnish, Swedish, Polish and German. French, Spanish and Dutch versions are also coming.",
        "NewsSaveTimeP10" : "SignHero can be integrated into any CRM, HRM, digital archive or other document\n"
                            + "management system, communication tool or application marketplace. This way the\n"
                            + "user will get a “send for signature” button right where she creates and stores\n"
                            + "documents. You can even sign directly in your Gmail account via SignHero plug-in without\n"
                            + "leaving your inbox.",
        "NewsSaveTimeP11" : "If you want great user experience for an e-signature service and increase the number of your contracts, we highly recommend you to try <a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHero</a>!",
        "NewsSaveTimeP12" : "Reference: SaaShop, https://www.saashop.com/more-secure-way-to-sign-gives-you-more-time-and-money/",
        "NewsTitleSahihiHaki" : "Sahihi Haki & SignHero signed a joint venture agreement",
        "NewsSahihiHakiP1" : "SignHero and Sahihi Haki entered into a partnership in February 2023. This joint venture enables SignHero to offer electronic signing services to countries south of the Sahara and East Africa. This is a large area that is digitizing rapidly and where there is a demand for this type of service.\n",
        "NewsSahihiHakiP2" : "SignHero is a fast and easy electronic signing service developed by the Finnish software company Avaintec. SignHero was launched in 2017 and has grown into an international service that is currently offered in eight languages in dozens of countries. SignHero's strengths are its ease of use, internationality and very competitive price.",
        "NewsSahihiHakiP3" : "SignHero's new partner Sahihi Haki is a Kenyan company that is a System Integrator to pave the way for Information Technology innovative and reliable solutions for governments, banks, and private sectors. They offer a wide range of unparalleled solutions from highly secure document issuing and personalization solutions to seamlessly integrated e Government solutions.",
        "NewsSahihiHakiP4" : "The joint venture concluded now enables SignHero to participate in the rapid digitalization of the East African region and introduces new users to the many advantages of electronic signing. Business-to-business spending in Africa's manufacturing sector is projected to reach $1 trillion by 2050. With this, digitalization has a huge opportunity to improve the industry's competitiveness and strengthen regional economies.",
        "NewsSahihiHakiP5" : "Organizations in the region should simplify decision-making and take steps towards digitization, even if only in small steps. SignHero e-signature service is just such a small step towards digitization that will significantly facilitate the business operations of organizations in the East African region.",
        "NewsSahihiHakiQuotePekka" : "Pekka Kuosmanen, Avaintec's CEO says:",
        "NewsSahihiHakiP6" : "\"I am really pleased that SignHero's electronic signature service can be expanded to Africa in cooperation with Sahihi Haki. The cooperation enables Avaintec the blue ocean market opportunity of electronic signature.\"",
        "NewsSahihiHakiQuoteBertrand" : "Bertrand Ngaywa from Sahihi Haki says:",
        "NewsSahihiHakiP7" : "“As Sahihi Haki we are pleased to work with Avaintec towards the introduction of electronic signatures in the African region as we are motivated towards pushing for Information Technology innovation as per the current global trends.”",
        "UnsubscribeQuestion" : "Are you sure you want to unsubscribe from SignHero marketing emails?",
        "Resubscribe" : "Resubscribe",
        "ResubscribedTitle" : "Glad to see you back!",
        "ResubscribedMessage" : "You have resubscribed to our marketing emails successfully, you can leave the page now.",
        "NewsTitlePrivyPartner" : "Privy Partner & Avaintec bring SignHero to the Southeast Asian market area",
        "NewsPrivyPartnerP1" : "Finnish Avaintec and Thai Privy Partner entered into collaboration in September 2023. The cooperation enables Avaintec’s SignHero to offer electronic signing services in the Southeast Asian region (Brunei, Burma (Myanmar), Cambodia, Timor-Leste, Indonesia, Laos, Malaysia, the Philippines, Singapore, Thailand and Vietnam). The rapid digitisation of Southeast Asia opens up a number of opportunities for its residents and businesses.",
        "NewsPrivyPartnerP2" : "SignHero is a fast and easy electronic signing service developed by the Finnish software company Avaintec. SignHero was launched in 2017 and has grown into an international service that is currently available in eight languages ​​in dozens of countries. SignHero's strengths are its ease of use, internationality and a very competitive price.",
        "NewsPrivyPartnerP3" : "SignHero's new collaborator Privy Partner is initially established as a business management consulting Thai company, founded since 2005, that provided C-Level   management consultancy services in the key areas of strategic business management (both tactical & operational level), internationalising business development and establishment, business networking management for localisation, and, now, the business consulting and digital service provider for Personal Data Privacy Management, Data Protection as-a Service, and Digital Transformation to help business and government units to cope with digital works sustainability without compromising their business growth.",
        "NewsPrivyPartnerP4" : "Through the collaboration, SignHero will be able to participate in the digitisation of the area. More and more companies around the world are modifying their operations to adapt to the digital international world and, with that, adopt new ways of working and utilising new technologies to enable that development.",
        "NewsPrivyPartnerP5" : "Southeast Asian operators have already benefited from digitisation through improved operational efficiency, improved sales processes and customer experience, as well as improved productivity. New technologies allow communication in an unprecedented way and give time for businesses to focus on their core competencies.",
        "NewsPrivyPartnerQuotePekka" : "Pekka Kuosmanen, CEO of Avaintec says:",
        "NewsPrivyPartnerP6" : '"Countries in Southeast Asia are digitising at a rapid pace. In Thailand, for example, a law came into force in June that obliges authorities to digitise their services. Electronic signatures are an integral part of digitised processes."',
        "NewsPrivyPartnerP7" : '"The agreement and cooperation with Privy Partner opens up a great potential for SignHero to become a key supplier of electronic signatures in the Southeast Asian market."',
        "NewsPrivyPartnerQuoteWanawit" : "Wanawit Ahkuputra, Founder and Principal Partner of Privy Partner says:",
        "NewsPrivyPartnerP8" : '"Finnish technology in digital signature is characterised by its security, innovation, and adherence to European standards. Avaintec had a long history working toward the integration of the localisation of Thai language that is the primary critical success factor of ensuring the integrity and authenticity of digital signature in the local context of this region."',
        "Locales": "Language",
    },
    "client": {
        "ClientSecretInfo": "Store the client secret securely. You can change it but you will not be able to see it again after you end this session.",
        "Error!InvalidDomain": "Invalid domain name.",
        "DomainValidationDownloadInfo1": "To verify your domain:",
        "DomainValidationDownloadInfo2": "Download the following file:",
        "DomainValidationDownloadInfo3": "Upload the file to the root directory of your domain:",
        "DomainValidationDownloadInfo4": "Verify that the file can be accessed from the link:",
        "DomainValidationDownloadInfo5": "Come back to his page and click on \"Verify domain\"",
        "LastClientSecretReset": "Last changed",
        "FailedDomainValidation": "Validation of your domain failed. Have you followed the instructions?",
        "OAuthApplications": "OAuth2 applications",
        "Application": "OAuth2 application",
        "IntegrateWithOAuth": "Integrate with OAuth2",
        "NewOAuthApplication": "New OAuth2 application",
        "ApplicationName": "Application name *",
        "AddOAuthApplication": "Add application",
        "AddUri": "Add more URIs",
        "CreationDate": "Creation date",
        "CreatedBy": "Created by",
        "AllowedGrantTypes": "Allowed grant types",
        "OAuthRedirectURIs": "Redirect URIs *",
        "OAuthClientsDomainURI": "Domain",
        "OAuthClientsDomainTitle": "Application domain *",
        "AppDescription": "Application description",
        "VerifyDomain": "Verify domain",
        "DomainVerified": "The domain has been verified.",
        "WhyValidateDomain": "You need to verify the ownership of your domain. The application can't be used before successful domain verification. Refer to the <a class='paragraph-links' href='/docs/api' target='_blank'>API documentation</a> for details.",
        "ClientSecret": "Client secret",
        "ClientId": "Client ID",
        "ClientCredentials": "Client credentials",
        "VerifiedApp": "Active",
        "UnverifiedApp": "Inactive",
        "ResetClientSecret": "Change secret",
        "ConfirmDeleteApiClient": "Are you sure you want to delete this app?\nTo continue, please enter the code: ${rndCode}",
        "ConfirmResetClientSecret": "Are you sure you want to reset client secret?\nTo continue, please enter the code: ${rndCode}",
        "AppHasBeenDeleted": "The application ${appName} has been deleted.",
        "AppHasBeenCreated": "The application ${appName} has been created.",
        "YourChangesHaveBeenSaved": "Your changes have been saved.",
        "ClientSecretChanged": "Client secret changed.",
        "CheckApiDocs": "You must verify the ownership of your domain before the application can be used. Check the <a href='/docs/api' target='_blank'>API documentation</a> for details."
    },
    "oauth2consent": {
        "AllowAccess": "Allow access",
        "OAuthAccessDescriptionHeader": "<span class='elem-pink'>${appName}</span> needs your authorisation before you can use it to:",
        "OAuthBasicPermissions": "  <li>Send signing invitations and reminders</li>" +
                                 "  <li>Access, cancel and delete signing invitations sent with it</li>" +
                                 "  <li>Access signed documents sent for signatures with it</li>",
        "OAuthSigningUrlPermissions": " <li>Take you to sign documents uploaded with it</li>",
        "OAuthProfilePermissions": " <li>Access your name, email, company name, organisation name, selected language " +
                                    "of communication and see if you have admin rights</li>",
        "OAuthAllDataPermissions": " <li>Access, cancel and delete all of your signing invitations</li>" +
                                   " <li>Access all of your documents</li>",
        "OAuthAccessWarning": "This also means you agree to ${appName}'s terms of service and privacy policy.",
        "QuestionToAllowAccess": "Allow <span class='elem-pink'>${appName}</span> to access your SignHero account " +
                                 "<span class='elem-bold'>${userEmail}</span>?<br><br>You can always change your " +
                                 "mind in your SignHero account settings.",
        "Cancel": "Cancel",
        "Allow": "Allow",
        "NotFound": "Sorry, app not found",
        "OAuthResourceNotFound": "Unfortunately, the application that directed you here needs to fix the issue."
    }
}
